import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { CRS, map, latLng, latLngBounds, imageOverlay,icon, Marker } from 'leaflet'
import { PanoService } from 'zontro-openapi-frontend';
@Component({
  selector: 'app-floor-map',
  templateUrl: './floor-map.component.html',
  styleUrls: ['./floor-map.component.css']
})
export class FloorMapComponent implements OnInit {

  private map: any
  markers: Marker[] = []
  prevIdx: number = -1
  rotMark: any
  count: number = 0
  @Input() currentScene: any 
  markerArr: any =[]
  @Input() scan: any
  @Input() selectedArea: any
  @Input() closestPano: any
  //@Output() selectedMarkerScan = new EventEmitter<any>()
  @Output() selectedMarkerFloor = new EventEmitter<any>()
  @Output() close = new EventEmitter<any>()
  iconNormal = icon({
    iconSize: [ 25, 25 ],
    iconAnchor: [ 13, 30 ],
    iconUrl: 'assets/images/squareBlue.png'
  })
  iconlarge = icon({
    iconSize: [ 32, 30 ],
    iconAnchor: [ 20, 30 ],
    iconUrl: 'assets/images/squareRed.png'
  })
  imageUrl: any;
  layer: any;
  bounds: any;
  subscription: any;
  date = '2021-12-25T06:10:20Z'
  projectId: any ='Aerospace_REV3'
  boundArr: any;
  closestDone: boolean = false;
  private initMap(){
    this.map = map('map', {
      crs:CRS.Simple,
      center: latLng(500, 300),
      minZoom: 0,
      maxZoom: 14
    })
  }

  constructor(private panoService: PanoService) { }

  ngOnInit() {
    if(this.closestPano!==undefined && this.closestPano) {
      this.initMap();
    
      var bnd = this.closestPano.bounds.split(', ')
      this.boundArr = bnd
      this.imageUrl = 'assets/images/'+this.closestPano.floorPlan;;
      this.bounds = latLngBounds([[0,0],[bnd[2],bnd[3]]])
      this.createMap()
      this.count = 1
      this.map.setView([this.boundArr[1]+100,this.boundArr[0]+100], 1);
    } else {
      this.initMap();
      var bnd = this.scan.bounds.split(', ')
      this.boundArr = bnd
      this.imageUrl = 'assets/images/'+this.scan.floorPlan;;
      this.bounds = latLngBounds([[0,0],[bnd[2],bnd[3]]])
      this.createMap()
      this.count = 1
      this.map.setView([this.boundArr[1]+100,this.boundArr[0]+100], 1);
    }
    //$('.leaflet-control-attribution').hide()
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['currentScene']!==undefined && this.currentScene){
      if(this.closestDone==true) {
        let scene = changes['currentScene'].currentValue;
        this.currentScene=scene;
        this.openPano(scene) 
      } 
    }

    if(changes['closestPano']!==undefined && this.closestPano){
      let newScan = changes['closestPano'].currentValue;
      this.loadFloorPlan(newScan)
      this.closestDone=true
    } else if(changes['scan']!==undefined && this.scan){
      if(!changes["scan"].isFirstChange()){
        if(this.closestDone==true) {
          let newScan = changes['scan'].currentValue;
          this.scan=newScan
          this.loadFloorPlan(newScan)
        }
      }
    }
  }

  onFloorChange(){
    for(var i=0; i<this.markers.length; i++) {
      this.map.removeLayer(this.markers[i])
    }
    this.markerArr=[]
    this.map.remove();
    this.initMap();
    this.layer.remove();
    this.createMap()
  }
  loadFloorPlan(scan: any){
    this.subscription = this.panoService.getPanobyArea(this.projectId,scan.scanName).subscribe(
      result => {
        for (var i=0; i<result.length; i++) { 
          var marker = {id: i+1, lat: result[i].transX, lng: result[i].transY, z:result[i].transY}
          this.markerArr.push(marker);
        }
        this.setMarkers();
      })

      if(this.count>=1){
        this.count=2
        this.imageUrl='assets/images/'+scan.floorPlan;
        var bnd = scan.bounds.split(', ')
        this.boundArr = bnd
        this.bounds = latLngBounds([[0,0],[this.boundArr[2],this.boundArr[3]]])
        this.onFloorChange() 
      } else {}
  }

  createMap(){
    this.layer = imageOverlay(this.imageUrl, this.bounds).addTo(this.map);
    var minmaxbounds = latLngBounds([[this.boundArr[0],this.boundArr[1]], [this.boundArr[2],this.boundArr[3]]]);
    this.map.setMaxBounds(minmaxbounds); 
    this.map.fitBounds(this.bounds);
    this.map.setZoom(1)
    this.map.setView([this.boundArr[1]+100,this.boundArr[0]+100], 1);
    $('.leaflet-control-attribution').hide()
  }
  
  setMarkers() {
    this.markers = this.markerArr.map((camera: any) => new Marker(
            { lat: camera.lng, lng: camera.lat },{icon: this.iconNormal})
            .on('click', function (e){ openPano(camera)})
            .addTo(this.map)
    );
    this.rotMark = this.markers[0].setIcon(this.iconlarge);
    this.prevIdx = 0
    const openPano = (id:any) =>{        
      if (id.id < 0) return;
      else {
        if(this.prevIdx>-1) {
          this.markers[this.prevIdx].setIcon(this.iconNormal);
        } else {
          this.rotMark.setIcon(this.iconNormal)}
          this.rotMark = this.markers[id.id-1].setIcon(this.iconlarge);
          this.prevIdx=id.id-1;
          if(this.closestDone==true) { 
            this.selectedMarkerFloor.emit(id.id)
          }

      }
      this.map.setView([id.lng, id.lat], 2,{
        "animate": true,
        "pan": {
          "duration": 2
        }
      });
    };
    //this.map.setView([this.markerArr[0].lng, this.markerArr[0].lat], 1);
  }

  openPano(id: number) {
    if (id < 2) return;
    else {
      if(this.prevIdx>-1) {
        this.markers[this.prevIdx].setIcon(this.iconNormal);
      } else {this.rotMark.setIcon(this.iconNormal)}
      this.markers[id-1].setIcon(this.iconlarge);
      this.prevIdx=id-1
      this.map.setView([this.markerArr[id-1].lng, this.markerArr[id-1].lat], 2,{
        "animate": true,
        "pan": {
          "duration": 2
        }
      });
    }
  }

  closeMap(e: any) {
    this.close.emit(false)
  }
}

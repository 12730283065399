

<div >
  <ul class="list-group list-group-flush text shadow" >
    <li  class="list-group-item area-list" *ngFor="let item of areaList" (click)=selectArea(item) >
      <p [ngClass]="{'class_name': item.areaId === select  }" >
        {{item.name}}
      </p>
    </li>
  </ul>
  
</div>



  <!-- <div *ngIf="showModal" >
    <div class="alert alert-warning alert-dismissible fade show msg" role="alert">
      You are already in this floor 
      <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" (click) = "close()"></button>
    </div>
</div>
<div class="container-fluid p-1 area" style="width: max-content;" >
    <ul class="list-group list-group-flush text " >
            <li  class="list-group-item area-list">
              <button class="btn btn-primary p-0 m-0" 
              (click)=selectFloor($event)  
              value="unit6floor1" 
              [ngStyle]="select ? {'color': '#4E91DA'} : {'color': 'black'}" >
                Unit 6: Floor 1</button>
            </li>
            <li  class="list-group-item area-list">
              <button class="btn btn-primary p-0 m-0" 
              (click)=selectFloor($event) 
              value="unit6floor2" 
              [ngStyle]="!select ? {'color': '#4E91DA'} : {'color': 'black'}" >
                Unit 6: Floor 2</button>
            </li>
    </ul>
</div> -->
<!-- <div class="container-fluid p-1 date" style="width: max-content;" >
  <select class="form-select" aria-label="select" style="width: 100%;" (change)="selectScan($event)">
    <option *ngFor="let title of scans" 
        [value]="title.scan_name" >
        {{title.scan_date}}
    </option>
  </select>
</div> -->

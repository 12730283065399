import { Component, EventEmitter, Input, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { AreaService } from 'zontro-openapi-frontend';
import { ScanService } from 'zontro-openapi-frontend';

@Component({
  selector: 'app-controls',
  templateUrl: './controls.component.html',
  styleUrls: ['./controls.component.css']
})
export class ControlsComponent implements OnInit {
  projectId: any = 'Aerospace_REV3'
  date = '2021-12-25T06:10:20Z'
  scans: any
  selectedDay: any;
  select:boolean = true
  @Output() selectedScan = new EventEmitter<any>()
  @Output() urn = new EventEmitter<any>()
  @Input() closestPano: any
  //@Input() taskFloor: any
  //@Input() selectedTask: any
  showModal: boolean = false;
  floor:any
  subscription: any;
  areaList: any;
  currentCompany: any;
  constructor(private areaService: AreaService, private scanService: ScanService) { }

  ngOnInit() {
    this.areaService.getAreaDetails(this.projectId).subscribe(
      result1 => {
        this.areaList=result1
      //  this.scans = result
      //  for(var i=0;i<result.length;i++){
      //   this.scans[i].scan_date = this.scans[i].scan_date.slice(0,10) 
      //   }
        
   
    //if(this.taskFloor){
      //this.floor = this.taskFloor
      //if(this.taskFloor=='unit6floor1'){this.select=true} else this.select=false
      // this.scanService.getScan(this.projectId,this.date,this.taskFloor).subscribe(
      this.scanService.getScan(this.projectId,this.date,this.areaList[0].areaId).subscribe(
      result => {
       this.scans = result
       for(var i=0;i<result.length;i++){
        this.scans[i].scan_date = this.scans[i].scan_date.slice(0,10) 
        }
        var areaDetails= {
          areaId: this.areaList[0].areaId,
          pano: this.areaList[0].closestPano,
          bounds: this.areaList[0].floorPlanBounds,
          floorPlan: this.areaList[0].floorPlanPath,
          scanName: this.scans[0].scan_name,
          scanPanoPath: this.scans[0].scan_pano_path,
          urnOffset: this.areaList[0].urnOffset,
          offsetYaw: this.scans[0].offsetYaw
        }
        this.selectedScan.emit(areaDetails)
      });
    });
  }
  close(){
    this.showModal = !this.showModal;
  }
  
  selectScan (event: any) {
    for(var i=0;i<this.scans.length;i++){
      if(this.scans[i].scan_name==event.target.value){
        this.selectedScan.emit(this.scans[i])
      }
    }
  }
  // selectFloor(floor:any){
  //   if(this.floor !== floor.target.value){
  //   if(floor.target.value=='unit6floor1'){this.select=true} else this.select=false
  //   this.subscription = this.scanService.getScan(this.projectId,this.date,floor.target.value).subscribe(
  //     result => {
  //      this.scans = result
  //      for(var i=0;i<result.length;i++){
  //        this.scans[i].scan_date = this.scans[i].scan_date.slice(0,10)
  //      }
  //      this.selectedScan.emit(this.scans[0])
  //      this.floor=floor.target.value
  //     });
  //   } else {this.showModal=true}
  // }
  ngOnChanges(changes: SimpleChanges) {
    if(changes['closestPano']!==undefined && this.closestPano){
      let id = changes['closestPano'].currentValue;
      this.closestPano=id
     // this.selectArea(this.closestPano.areaId)
      this.select = this.closestPano.areaId;
      for(var i=0;i<this.areaList;i++) {
        if(this.areaList[i].areaId==this.closestPano.areaId) {
          
        }
      }
    }
    
  }

  selectArea(area: any) {
    this.urn.emit(area)
    this.select = area.areaId;
    this.scanService.getScan(this.projectId,this.date,area.areaId).subscribe(
      result => {
       this.scans = result
       for(var i=0;i<result.length;i++){
         this.scans[i].scan_date = this.scans[i].scan_date.slice(0,10)
       }
       var areaDetails= {
         areaId: area.areaId,
         pano: area.closestPano,
         bounds: area.floorPlanBounds,
         floorPlan: area.floorPlanPath,
         scanName: this.scans[0].scan_name,
         scanPanoPath: this.scans[0].scan_pano_path,
         urnOffset: area.urnOffset,
         offsetYaw: this.scans[0].offsetYaw
       }
       this.selectedScan.emit(areaDetails)
       this.floor=area.areaId
      });
  }
  // ngOnDestroy() {
  //   this.subscription.unsubscribe()
  // }
}

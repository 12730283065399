import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { AreaService } from './api/area.service';
import { BimcompService } from './api/bimcomp.service';
import { LinkService } from './api/link.service';
import { NoteService } from './api/note.service';
import { PanoService } from './api/pano.service';
import { ProjectService } from './api/project.service';
import { ScanService } from './api/scan.service';
import { TaskService } from './api/task.service';
import { UploadService } from './api/upload.service';
import { UserService } from './api/user.service';
//import { ForgeService } from './api/forge.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AreaService,
    BimcompService,
    LinkService,
    NoteService,
    PanoService,
    ProjectService,
    ScanService,
    TaskService,
    UploadService,
    UserService,
    //ForgeService 
]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}

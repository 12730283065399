import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ChartService {

  constructor() { }

  plannedProgress(pEnd:any, pStart: any){
    var plannedEnd = new Date(pEnd)
    var plannedStart = new Date(pStart)
    var today = new Date(); 
    var plannedDur = Math.round(((plannedEnd.getTime()-plannedStart.getTime())/(1000*3600*24)))
    var durationLapsed = Math.round(((today.getTime()-plannedStart.getTime())/(1000*3600*24)));
    var plannedProgress = Math.round(((durationLapsed)/plannedDur)*100);
    if (plannedDur=0) {
      return 0
    } else if (plannedProgress>100) {
      return 100
    } else {
      return plannedProgress
    }
  }
  getDate(date: any) {
    var dateNew = new Date(date)
    return dateNew.toISOString().split('T')[0];
  }
  
}

import {  Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-delay-tooltip',
  templateUrl: './delay-tooltip.component.html',
  styleUrls: ['./delay-tooltip.component.css']
})
export class DelayTooltipComponent implements OnInit {
  @Input() tooltipData: any
  @Input() parentTree: any

  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges) {
    if(changes['parentTree']!==undefined){
      let data = changes['parentTree'].currentValue;
    }
  }

}

import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { User, UserService } from 'zontro-openapi-frontend';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})

export class SigninComponent implements OnInit {

  title = 'amplify-angular-auth';
  user: any
  email: string = ''
  password: string = ''
  errorMessage: string = ''
  currentUser: any;
  userd: any;
  token: any;
  clientCode: number = 0
  message: string = ''
  messageType: string = 'success'
  clientList: any;
  clientVerification: boolean = false;
  clientName: any;
  userInDB: boolean = false;
  accessLevel: string = '';

  constructor( private ref: ChangeDetectorRef, 
              private authService: AuthService, 
              private userService: UserService, 
              private router: Router ) {}

  async ngOnInit() {
    try {
      this.currentUser = await this.authService.getCurrentUser()
      const { attributes } = this.currentUser;
      if(this.currentUser!==undefined) {
        this.userService.getUserDetails(this.currentUser.username).subscribe(
          (data: any) => {
             if(!jQuery.isEmptyObject(data)) {
              this.userInDB = true
              this.accessLevel = data[0].clientId
             }
             if(data.length==0) {
              this.userService.getOrg().subscribe(
                (result: any) => {
                  this.clientList = result
                  this.clientVerification=true
                }
              )
             }
          }
        )
        
      }
      this.ref.detectChanges();
    } catch(error:any){
      if(error == "The user is not authenticated") {
        
      } else {//this.onProceed()
      }
    }
  }
  
  async onLogin(form: NgForm){
    try {
      this.user = await this.authService.signIn(form.value.email, form.value.password)
      if (this.user!==undefined) {
        return  
      } else {
        this.authService.setLoggedIn(true)
      }

    } catch(error:any){
      console.log(error)
      this.errorMessage = error
    }
  } 
  onProceed() {
    this.authService.setLoggedIn(true)
    const { attributes } = this.currentUser;
    let user: User = {
      userId: this.currentUser.username,
      name: attributes.name,
      email: attributes.email
    }
    this.router.navigate(['ui'])
  }
  onSignOut(){
    this.authService.signOut()
    this.authService.setLoggedIn(false)
  }
  displayMessage(message: string, messageType: string){
    this.message = message
    this.messageType = messageType
  }
  onOverview() {
    this.authService.setLoggedIn(true)
    this.router.navigate(['overview'])
  }
  checkClient(ev: any){
    this.clientName = ev.target.value

  }
  onCompanyAccess() {
    const { attributes } = this.currentUser;
    for(var i=0; i<this.clientList.length;i++){
      if(this.clientName==this.clientList[i].orgName && this.clientCode==this.clientList[i].clientCode){
          let user: User = {
          userId: this.currentUser.username,
          name: attributes.name,
          email: attributes.email,
          clientId: this.clientList[i].clientId,
	  orgName: this.clientList[i].orgName
        }
        this.userService.createUser(user).subscribe(
          result => {
             window.location.reload();    
          },error => {
            this.displayMessage('Error adding user', 'danger')
            console.log(error)
          }
        )
      }
    }
    if(!this.user) {
      this.errorMessage = "Company code incorrect. Please recheck."
    }
  }
}

/**
 * Zontro UI APIs
 * Zontro APIs for UI and backend
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ApiResponse } from '../model/apiResponse';
import { Project } from '../model/project';
import { ProjectCollab } from '../model/projectCollab';
import { ForgeToken } from 'zontro-openapi-frontend/model/forgeToken';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ProjectService {

    protected basePath = 'http://zontro-webui.mvizn.com:3200/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Add to collaborators&#x27; list for a Project
     * 
     * @param body Updated project object
     * @param projectId Project
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addCollab(body: ProjectCollab, projectId: string, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public addCollab(body: ProjectCollab, projectId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public addCollab(body: ProjectCollab, projectId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public addCollab(body: ProjectCollab, projectId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addCollab.');
        }

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling addCollab.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ApiResponse>('post',`${this.basePath}/project/collabadd/${encodeURIComponent(String(projectId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Create new Project
     * 
     * @param body Created project object
     * @param userId User Id of the Project to be added
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createProject(body: Project, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public createProject(body: Project, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public createProject(body: Project, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public createProject(body: Project, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling createProject.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ApiResponse>('post',`${this.basePath}/project/add`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

        /**
     * Delete Project
     * 
     * @param body deleted project object
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
         public deleteProject(body: Project, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
         public deleteProject(body: Project, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
         public deleteProject(body: Project, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
         public deleteProject(body: Project, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
     
             if (body === null || body === undefined) {
                 throw new Error('Required parameter body was null or undefined when calling deleteProject.');
             }
     
             let headers = this.defaultHeaders;
     
             // to determine the Accept header
             let httpHeaderAccepts: string[] = [
                 'application/json'
             ];
             const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
             if (httpHeaderAcceptSelected != undefined) {
                 headers = headers.set('Accept', httpHeaderAcceptSelected);
             }
     
             // to determine the Content-Type header
             const consumes: string[] = [
                 'application/json'
             ];
             const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
             if (httpContentTypeSelected != undefined) {
                 headers = headers.set('Content-Type', httpContentTypeSelected);
             }
     
             return this.httpClient.request<ApiResponse>('post',`${this.basePath}/project/delete`,
                 {
                     body: body,
                     withCredentials: this.configuration.withCredentials,
                     headers: headers,
                     observe: observe,
                     reportProgress: reportProgress
                 }
             );
         }
    
    /**
     * Get collaborators&#x27; list for a Project
     * 
     * @param projectId Project
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCollab(projectId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<ProjectCollab>>;
    public getCollab(projectId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ProjectCollab>>>;
    public getCollab(projectId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ProjectCollab>>>;
    public getCollab(projectId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getCollab.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ProjectCollab>>('get',`${this.basePath}/project/collabget/${encodeURIComponent(String(projectId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns accessible Projects for user
     * Operation to Fetch all Projects user has access to
     * @param userId user Id of the logged in user
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getProjects(userId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Project>>;
    public getProjects(userId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Project>>>;
    public getProjects(userId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Project>>>;
    public getProjects(userId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling getProjects.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Project>>('get',`${this.basePath}/project/get/${encodeURIComponent(String(userId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    /**
     * Returns accessible Projects for user
     * Operation to Fetch all Projects user has access to
     * @param userId user Id of the logged in user
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
     public getProjectDetails(projectId: string, clientId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Project>>;
     public getProjectDetails(projectId: string, clientId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Project>>>;
     public getProjectDetails(projectId: string, clientId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Project>>>;
     public getProjectDetails(projectId: string, clientId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         if (clientId === null || clientId === undefined) {
             throw new Error('Required parameter clientId was null or undefined when calling getProjectDetails.');
         }
         if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getProjectDetails.');
        }
 
         let headers = this.defaultHeaders;
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'application/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
         ];
 
         return this.httpClient.request<Array<Project>>('get',`${this.basePath}/project/getdetails/${encodeURIComponent(String(projectId))}/${encodeURIComponent(String(clientId))}`,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
    /**
     * update details of existing Project
     * 
     * @param body Updated project object
     * @param userId User Id of the Project to be added
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateProject(body: Project, userId: string, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public updateProject(body: Project, userId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public updateProject(body: Project, userId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public updateProject(body: Project, userId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateProject.');
        }

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling updateProject.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ApiResponse>('post',`${this.basePath}/project/update/${encodeURIComponent(String(userId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    /**
     * Get Autodesk forge Token
     * 
     * @param projectId
     * @param unit 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
     public getForgeToken(projectId: string,unit: string, observe?: 'body', reportProgress?: boolean): Observable<ForgeToken>;
     public getForgeToken(projectId: string,unit: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ForgeToken>>;
     public getForgeToken(projectId: string,unit: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ForgeToken>>;
     public getForgeToken(projectId: string,unit: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         if (projectId === null || projectId === undefined) {
             throw new Error('Required parameter projectId was null or undefined when calling getForgeToken.');
         }
        if (unit === null || unit === undefined) {
            throw new Error('Required parameter unit was null or undefined when calling getForgeToken.');
        }
 
         let headers = this.defaultHeaders;
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'application/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
         ];
 
         return this.httpClient.request<ForgeToken>('get',`${this.basePath}/forge/getToken/${encodeURIComponent(String(projectId))}/${encodeURIComponent(String(unit))}`,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
     

}

<div class="container-fluid">
<div class="row toolbar">
    <div class="col">
        <button class="btn btn-sm btn-outline-primary" (click)="expand_tasks()">
            <i class="fas fa-expand-alt"></i> Expand All</button>
    </div>
    <div class="col">
        <button class="btn btn-sm btn-outline-primary" (click)="collapse_tasks()">
            <i class="fas fa-compress-alt"></i> Collapse All
        </button>
    </div>
    <div class="col">
        <button class="btn btn-sm btn-outline-primary" onclick="gantt.ext.zoom.zoomIn();">
            <i class="fas fa-search-minus"></i> Zoom In
        </button>
    </div>
    <div class="col">
        <button class="btn btn-sm btn-outline-primary" onclick="gantt.ext.zoom.zoomOut();">
            <i class="fas fa-search-plus"></i> Zoom Out
        </button>
    </div>
    <div class="col">
        <button class="btn btn-sm btn-outline-primary" onclick="gantt.ext.fullscreen.toggle();">
            <i class="fas fa-expand"></i> Fullscreen
        </button>
    </div>
    <div class="col">
        <button *ngIf="cp" class="btn btn-sm btn-outline-primary" (click)="toggle_highlight()">
            <i class="fas fa-wave-square"></i> Hide Critical
        </button>
        <button *ngIf="!cp" class="btn btn-sm btn-outline-primary" (click)="toggle_highlight()">
            <i class="fas fa-wave-square"></i> Show Critical
        </button>
    </div>
    <div class="col">
        <button *ngIf="onlyCP" class="btn btn-sm btn-outline-primary" (click)="viewCP()">
            <i class="fas fa-wave-square"></i> View All
        </button>
        <button *ngIf="!onlyCP" class="btn btn-sm btn-outline-primary" (click)="viewCP()">
            <i class="fas fa-wave-square"></i> Critical Path
        </button>
    </div>
    <!-- <div class="col">
        <input class="btn btn-outline-primary btn-sm" id="date-picker" [owlDateTime]="dt1" [(ngModel)]="dateTime" [owlDateTimeTrigger]="dt1" (ngModelChange)="onChange()" placeholder="Versions">
        <owl-date-time #dt1></owl-date-time>
    </div>
    <div class="col"><input type="file" class="file-upload form-control form-control-sm" id="mspFile" name="file" (change)="onFileSelected($event)"
        accept=".mpp,.xml, text/xml, application/xml, application/vnd.ms-project, application/msproj, application/msproject, application/x-msproject, application/x-ms-project, application/x-dos_ms_project, application/mpp, zz-application/zz-winassoc-mpp"/>
        <button class="btn btn-outline-primary btn-sm" id="mspImportBtn" type="submit"  onclick='gantt.importFromMSProject()'> Load from MPP</button>  
    </div> -->
    
</div>
<div #gantt_here class='gantt-chart'></div>
</div>
<body class="wind text-left">
    <img class="mb-4" src="assets/images/cons_auth.jpg" alt="" width="28" height="28"> <a style="vertical-align: top; color: #64b5f6; font-size: larger;" >    Zontro</a>
    <div *ngIf="!codeSent"> 
        <p class="mt-5 mb-3 text-muted" style="font-size: 12px;"> </p>
        <main class="form-signin">
            <div class="input-group mb-3">
                <form  #loginForm="ngForm" novalidate>
                    <div *ngIf="errorMessage" type="danger"><p style=" color: red;">{{ errorMessage }}</p></div>
                    <h1 class="h3 mb-4 pt-10 fw-normal" style="color: #64b5f6;">Forgot Password </h1>
                    <div class="form-floating mb-2">
                        <input type="email" class="form-control " size="45" name= "email" id="floatingInput" [(ngModel)]="email" placeholder="name@example.com" required>
                        <label for="floatingInput">Email address</label>
                    </div>      
                    <button class="w-20 btn btn-primary" type="submit" (click)="onSendCode()" >Send Code</button>
                </form>
            </div>
        </main>
    </div> 
    <div *ngIf="codeSent && !msg"> 
        <p class="mt-5 mb-3 text-muted" style="font-size: 12px;"> </p>
        <main class="form-signin">
            <div class="input-group mb-3">
                <form  #loginForm="ngForm" novalidate>
                    <div *ngIf="errorMessage" type="danger"><p style=" color: red;">{{ errorMessage }}</p></div>
                    <h1 class="h3 mb-4 pt-10 fw-normal" style="color: #64b5f6;">Forgot Password </h1>
                    <div class="form-floating mb-2">
                        <input type="email" class="form-control " size="45" name= "email" value=email id="floatingInput" [(ngModel)]="email" placeholder="name@example.com" required>
                        <label for="floatingInput">Email address</label>
                    </div>
                    <div class="form-floating mt-2 mb-2">
                        <input type="password" class="form-control form-control-sm" name="password" id="floatingPassword" [(ngModel)]="password" placeholder="Password" required>
                        <label for="floatingPassword">New Password</label>
                    </div>
                    <div id="form-floating" class="mr-2">
                        <input class="inpt" type="text" maxLength="1" size="1" min="0" max="9" name="c1" [(ngModel)]="c1" pattern="[0-9]{1}" />
                        <input class="inpt"  type="text" maxLength="1" size="1" min="0" max="9" name="c2" [(ngModel)]="c2" pattern="[0-9]{1}" />
                        <input class="inpt" type="text" maxLength="1" size="1" min="0" max="9" name="c3" [(ngModel)]="c3" pattern="[0-9]{1}" />
                        <input class="inpt" type="text" maxLength="1" size="1" min="0" max="9" name="c4" [(ngModel)]="c4" pattern="[0-9]{1}" />
                        <input class="inpt" type="text" maxLength="1" size="1" min="0" max="9" name="c5" [(ngModel)]="c5" pattern="[0-9]{1}" />
                        <input class="inpt" type="text" maxLength="1" size="1" min="0" max="9" name="c6" [(ngModel)]="c6" pattern="[0-9]{1}" />
                    </div> 
                    <p class="mt-3 mb-3 text-muted" style="font-size: 12px;"> 
                        Please enter the verification code sent to  
                        <a style="color: #64b5f6; font-weight: bold;">{{toVerify}}</a>
                    </p>     
                    <button class="w-20 btn btn-primary" type="submit" (click)="onReset()" >Reset</button>
                </form>
            </div>
        </main>
    </div> 
    <div *ngIf="msg">
        <div  class="row">
            <p class="mt-3 mb-3 text-muted" style="font-size: 18px;"> Password reset successful! </p>
        </div>
        <div class="row m-10">
            <div class="form-floating mt-2 mb-2">
                <button class="w-30 btn btn-primary mr-3"  type="submit" (click)="onVerifySuccess()" >Next</button> 
            </div>
        </div>
    </div> 
    <p class="mt-5 mb-3 text-muted" style="font-size: 12px;">&copy; <a href="#"> Terms and Conditions</a> | <a href="#"> Privacy Policy</a></p>     
  </body>

import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { BimcompService, ProjectService } from 'zontro-openapi-frontend';
declare const Autodesk: any;
declare const THREE: any

@Component({
  selector: 'app-bim',
  templateUrl: './bim.component.html',
  styleUrls: ['./bim.component.css']
})
export class BimComponent implements OnInit {

//STATIC DATA
  projectId='Aerospace_REV3'

  @Input() scan: any
  @Input() panoPos: any
  @Input() panoYaw: any
  @Input() taskId: any
  @Input() setLock: any
  @Input() scanFromPano: any
  @Input() clearSelection : any
  
  @Output() modelLoaded = new EventEmitter<any>()
  @Output() devPopup = new EventEmitter<any>()

  devShown: boolean = false;
  options: any;
  token: any
  viewer: any
  currentUrn: string | undefined;
  allDbId: any = [];
  colorClear: boolean = false;
  rejectYaw: boolean = true;
  lastUrn: any = 'none';
  lastAreaId: any = 'none';
  bimLoaded: boolean =false;
  showMod: boolean = false;
  firstLoad: boolean = true;
  unlocked: boolean = false;
  toolbarControls: any = [];
  dtv: any;
  dvExt: any;
  style: any;
  viewableData: any;
  deviatedList: any =[];

  constructor(private projectService: ProjectService,
              private bimService: BimcompService) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['scan']!==undefined && this.scan){
      let newScan = changes['scan'].currentValue;
      if(!changes["scan"].isFirstChange()){ 
        this.firstLoad = false
      }
      
      this.scan=newScan
      this.loadNewScan()
    }
    
    if(changes['clearSelection']!==undefined && this.clearSelection){
      let newScan = changes['clearSelection'].currentValue;
      this.clearSelection=newScan
      this.viewer.clearSelection()
    }
    if(changes['scanFromPano']!==undefined && this.scanFromPano){
      let newScan = changes['scanFromPano'].currentValue;
      this.scan=newScan
      this.loadNewScan()
    }
    else if(changes['panoPos']!==undefined && this.panoPos) {
      if(!changes["panoPos"].isFirstChange()){
        let newValue = changes['panoPos'].currentValue;
        if(this.viewer!==undefined){
          //this.viewer.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, () => {
            this.bimLoaded=true
            if(this.setLock==1) {
              this.SetNavigation(newValue.x1,newValue.x2,newValue.y1,newValue.y2,newValue.z1,newValue.z2);
            }
            this.viewer.navigation.orientCameraUp(1)
          //})
          // if(this.bimLoaded=true) {
          //   if(this.setLock==1) {
          //     this.SetNavigation(newValue.x1,newValue.x2,newValue.y1,newValue.y2,newValue.z1,newValue.z2);
          //   }
          // }
        //  this.viewer.navigation.orientCameraUp(1)
        }
       
      }
    }

    if(changes['panoYaw']!==undefined && this.panoYaw){
      if(this.setLock==1) {
        let newYaw = changes['panoYaw'].currentValue;
        this.panoYaw =newYaw
        this.viewer.navigation.toPerspective();
        this.changeTarget()
      } else {}
    }

    if(changes['taskId']!==undefined && this.taskId){ 
      let task = changes['taskId'].currentValue;
      this.taskId=task
      if(this.firstLoad==false){
        this.highlight()
      }
    }

    if(changes['setLock']!==undefined && this.setLock){ 
      let lock = changes['setLock'].currentValue;
      this.setLock=lock
      if(this.setLock==2) {
        this.viewer.navigation.orientCameraUp(1)
        this.viewer.navigation.setIsLocked(false)
        this.unlocked=true
      } else if(this.unlocked==true && this.setLock==1) {
        this.viewer.navigation.setIsLocked(true)
        this.changeTarget()
      } 
    }

  }

  highlight() {
    this.bimService.getBimDetails(this.projectId,this.taskId, this.scan.scanDate).subscribe(
      async result => { 
        //this.dvExt.removeAllViewables();
        if(!jQuery.isEmptyObject(result)) {
          this.allDbId = []
          //this.viewer.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT,()=> {
            this.viewer.clearThemingColors() 
            //this.viewer.hideAll()
            //this.showMod = true
            
  //BIM ELEMENT LEVEL NODE COLORING
            result.forEach((id: any) => {
              if(id.progress!=null){
                this.viewer.show([id.dbId], this.viewer.impl.model)
                  if(id.progress>90 ){
                    this.viewer.setThemingColor([id.dbId], new THREE.Vector4(0.298,0.686,0.314))
                  } else if(id.progress<=90 && id.progress>80){
                    this.viewer.setThemingColor([id.dbId], new THREE.Vector4(0.38,0.718,0.31))
                  } else if(id.progress<=80 && id.progress>70){
                    this.viewer.setThemingColor([id.dbId], new THREE.Vector4(0.459,0.745,0.306))
                  } else if(id.progress<=70 && id.progress>60){
                    this.viewer.setThemingColor([id.dbId], new THREE.Vector4(0.537,0.776,0.302))
                  } else if(id.progress<=60 && id.progress>=50) {
                    this.viewer.setThemingColor([id.dbId], new THREE.Vector4(0.612,0.804,0.302))
                  } else if(id.progress<=50 && id.progress>=40 ){
                    this.viewer.setThemingColor([id.dbId], new THREE.Vector4(0.686,0.831,0.302))
                  } else if(id.progress<=40 && id.progress>30){
                    this.viewer.setThemingColor([id.dbId], new THREE.Vector4(0.765,0.859,0.31))
                  } else if(id.progress<=30 && id.progress>20){
                    this.viewer.setThemingColor([id.dbId], new THREE.Vector4(0.843,0.886,0.318))
                  } else if(id.progress<=20 && id.progress>10){
                    this.viewer.setThemingColor([id.dbId], new THREE.Vector4(0.922,0.91,0.329))
                  } else if(id.progress<=10 && id.progress>=0) {
                    this.viewer.setThemingColor([id.dbId], new THREE.Vector4(1.,0.933,0.345))
                  } 
              }
              this.allDbId.push(id.dbId)
            })
          //})
        }
        var bimReq = {
          projectId: this.projectId,
          taskId: parseFloat(this.taskId),
          scanDate: this.scan.scanDate,
          scanName: this.scan.scanName
        }
        this.bimService.getBimDeviation(bimReq).subscribe(
          async result => { 
           // console.log(result)
            this.deviatedList = result
            this.deviatedList.forEach((myData: any, index: any) => {
              this.viewer.setThemingColor([myData.dbId], new THREE.Vector4(0.392,0.71,0.965))
              // const dbId = 10 + index;
              // const position = myData.position;
              // const viewable = new this.dtv.SpriteViewable(position, this.style, dbId);
              // this.viewableData.addViewable(viewable);
            });
            // var a =  await this.viewableData.finish();
            //  this.dvExt.addViewables(this.viewableData); 
             this.viewer.addEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, this.onSelectionChangedDeviation.bind(this))
          })

        
        // var areaOffset = '287.01859167688576, 621.3871459520716, -15.010679910375941'
        // var offsetCoord = areaOffset.split(', ')
        // if(parseFloat(this.taskId)==1734) {
        //   const myDataList = [
        //     { dbId:486802, position: { x:527.925-parseFloat(offsetCoord[0]),y:458.346-parseFloat(offsetCoord[1]),z:36.9915-parseFloat(offsetCoord[2]) } },
        //     { dbId:486486, position: { x: 527.925-parseFloat(offsetCoord[0]) , y: 473.061-parseFloat(offsetCoord[1]), z: 36.9094-parseFloat(offsetCoord[2]) } },
        //     { dbId:8412, position: { x: 534.503-parseFloat(offsetCoord[0]) , y: 417.245-parseFloat(offsetCoord[1]), z: 36.9915-parseFloat(offsetCoord[2]) } }];
        //   myDataList.forEach((myData: any, index: any) => {
        //     const dbId = 10 + index;
        //     const position = myData.position;
        //     const viewable = new this.dtv.SpriteViewable(position, this.style, dbId);

        //     this.viewableData.addViewable(viewable);
        //   });
        //   await this.viewableData.finish();
        //   this.dvExt.addViewables(this.viewableData); 
        //} 
        // this.viewer.addEventListener(this.dtv.MOUSE_CLICK, this.onSelectionChanged.bind(this));
      }
    )
  }
  loadNewScan() {
    if(this.taskId==null || this.taskId==undefined) {
      this.taskId=0
    }
    this.projectService.getForgeToken(this.projectId,this.scan.areaId).subscribe(
      result => { 
        this.token = result.accessToken;
        this.currentUrn = result.urn
        this.options = {
      //    env: 'AutodeskProduction2',
      //    api: 'streamingV2',  
          env: 'AutodeskProduction',
          api: 'derivativeV2',  
          endpoint: 'https://cdn.derivative.autodesk.com',
          getAccessToken: function(onTokenReady: any) {
            var token = result.accessToken
            var timeInSeconds = result.expiresIn;
            onTokenReady(token, timeInSeconds);
          }
        }
        if(this.currentUrn!==this.lastUrn) {
          if(this.viewer!==undefined) {
            this.bimLoaded=false
            this.viewer.showAll()
            this.allDbId=[]
            this.viewer.finish();
            this.viewer = null;
            Autodesk.Viewing.shutdown();
          }
          this.modelLoaded.emit(false)
          this.firstLoad = true
          Autodesk.Viewing.Initializer(this.options, this.loadViewer.bind(this));
        }
         if(this.firstLoad==false) {
          this.highlight()
        }
      }
    )
    this.lastAreaId = this.scan.areaId
    this.lastUrn = this.currentUrn
  }

  changeTarget() {  
    var xtarget = this.panoPos.x1+(5*Math.cos(this.panoYaw.yaw* Math.PI / 180))
    var ytarget = this.panoPos.y1+(5*Math.sin(this.panoYaw.yaw* Math.PI / 180))
    var xMax = 90;
    var xMin = 0;
    var pitchDiff = 15
    var pitchOrigin = Math.round(this.panoPos.z1)
    var ztarget = pitchOrigin + (this.panoYaw.pitch/(xMax/pitchDiff))
    if(this.rejectYaw==false) {
      this.SetNavigation(this.panoPos.x1, xtarget, this.panoPos.y1, ytarget, this.panoPos.z1, ztarget );
    }
  }
  async loadViewer(){
    var config3d = {
      memory: {
          limit:  1000 // in MB
      }
    };
    var htmlDiv = document.getElementById('forgeViewer');
    this.viewer = new Autodesk.Viewing.GuiViewer3D(htmlDiv,config3d);
    var startedCode = await this.viewer.start();
    if (startedCode > 0) {
        console.error('Failed to create a Viewer: WebGL not supported.');
        return;
    }
    //console.log('Initialization complete, loading a model next...');
    var documentId = this.currentUrn
    Autodesk.Viewing.Document.load(documentId, this.onDocumentLoadSuccess.bind(this), this.onDocumentLoadFailure);  
    // const dataVizExtn = await this.viewer.loadExtension("Autodesk.DataVisualization");
    // this.dvExt = dataVizExtn
    // const DataVizCore = Autodesk.DataVisualization.Core;
    // this.dtv = DataVizCore
    // const viewableType = DataVizCore.ViewableType.SPRITE;
    // const spriteColor = new THREE.Color(0xffffff);
    // const spriteIcon = 'assets/images/warning.png'
    // const style2 = new DataVizCore.ViewableStyle(
    //                 viewableType,
    //                 spriteColor,
    //                 spriteIcon
    //               );
    // this.style = style2
    // const viewableData = new DataVizCore.ViewableData();
    // this.viewableData=viewableData
    // viewableData.spriteSize = 30; // Sprites as points of size 24 x 24 pixels
    // const showViewables = true;  

    this.viewer.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT,()=> {
      this.modelLoaded.emit(true)
      this.firstLoad = false
      this.bimLoaded=true
      // for (var i=0;i<this.viewer.toolbar.getNumberOfControls();i++) {
      //   this.toolbarControls.push(this.viewer.toolbar.getControlId(i));
      // }
      //console.log(this.toolbarControls)
      this.SetNavigation(this.panoPos.x1,this.panoPos.x2,this.panoPos.y1,this.panoPos.y2,this.panoPos.z1,this.panoPos.z2);
      this.rejectYaw=false
      this.viewer.navigation.orientCameraUp(1)
    })
    if(this.firstLoad==false){
      this.highlight()
      
      }
  }

  onDocumentLoadSuccess(viewerDocument: any) {
    var defaultModel = viewerDocument.getRoot().getDefaultGeometry();
    this.viewer.start()
	  this.viewer.loadDocumentNode(viewerDocument, defaultModel);
  }
  onDocumentLoadFailure() {
    console.error('Failed fetching Forge manifest');
  }
//POSITION CHANGE FUNCTION (TRIGGERED FROM OTHER COMPONENTS)
  SetNavigation(x1: any,x2: any,y1: any,y2: any,z1: any,z2: any) {
    if (x1 != undefined && x2 != undefined && y1 != undefined && y2 != undefined && z1 != undefined && z2 != undefined) {
      this.viewer.navigation.setView({ x: x1, y: y1, z: z1 }, { x: x2, y: y2, z: z2 });
      this.viewer.navigation.toPerspective();
      this.viewer.navigation.setVerticalFov(75, true);
      this.viewer.getCamera().perspectiveCamera.zoom=0.5;
      this.viewer.navigation.setIsLocked(true);
      //this.viewer.setNavigationLock(true)
      //console.log(this.viewer.getNavigationLockSettings())
      //(<HTMLInputElement>document.getElementById("toolbar-bimWalkTool")).click();
    }
  };
  
  showModel() {
    this.showMod = !this.showMod
    if(this.showMod==true) {
      this.viewer.showAll()
    } else {
    this.viewer.hideAll()
    this.allDbId.forEach(async (id: any) => {
      await this.viewer.show([id], this.viewer.impl.model)
    })
    } 
  }
  // onSelectionChanged(dbIds: any){
  //   this.devShown=!this.devShown
  //   if(dbIds.dbId == 10) {
  //    var deviation = {bimId:5674011, text: "Actual deviation of 20 cm in Y. Algorithm detected 17cm in Y.", img:"5674011.PNG"}
  //    this.devPopup.emit({show:this.devShown, deviation})
  //   } else if (dbIds.dbId==11) {
  //    var deviation = {bimId:5673820, text: "Actual deviation of 20 cm in Y. Algorithm detected 25cm in Y.", img:"5673820.PNG"}
  //    this.devPopup.emit({show:this.devShown, deviation})
  //   } else if (dbIds.dbId==12) {
  //    var deviation = {bimId:3202805, text: "Actual deviation of 18 cm in Y, Algorithm detected 17cm in Y.", img:"3202805.PNG"}
  //    this.devPopup.emit({show:this.devShown, deviation})
  //   } 
  // }
  onSelectionChangedDeviation (ev: any) {
    this.devShown=true
    if (ev.dbIdArray.length === 1) {
      this.viewer.getProperties(ev.dbIdArray[0], function(data: any) {
        //  console.log(data)
      })
      this.viewer.getProperties(ev.dbIdArray[0], this.deviationData.bind(this)) 
    }
  }
  deviationData(data: any){
      //console.log(data)
      for(var i=0;i<this.deviatedList.length;i++) {
        if(data.dbId==this.deviatedList[i].dbId) {
          //console.log(this.deviatedList[i])
          var deviation = {
            bimId: data.name,
            text: this.deviatedList[i].deviationText,
            img: this.deviatedList[i].deviationImage,
            footer: this.deviatedList[i].deviationCardFooter
          }
          //console.log(deviation)
          this.devPopup.emit({show:this.devShown, deviation})
        }
      }
  }
}

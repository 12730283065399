    <!-- MAIN -->
  <div class="row">
    <div class="col" style="background-color:#FAFAFB">
      <router-outlet></router-outlet>
    </div>
  </div>



<!-- <nav class="navbar navbar-expand-lg pb-1  navbar-light bg-light pt-0 mb-4 shadow fixed-top" style="height: 3% !important;">
  <div class="container-fluid">
    <a class="navbar-brand" href="#">
      <img src="../../assets/images/logo.png" alt="" width="30" height="30" class="d-inline-block align-text-top">
      <a style="color: #4E91DA; font-weight: 650;">    Zontro</a>    
      <a class="navbar-brand" href="#" style="color: rgb(71, 71, 71); font-size: smaller; padding-left: 20%;">
          Project: JTC Aerospace 
      </a>   
    </a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
      <ul class="navbar-nav justify-content-center" style="padding-left: 50%!important;" >
        <ul ngbNav #nav="ngbNav" [(activeId)]="active" [destroyOnHide]="false" class="nav-tabs">
          <li [ngbNavItem]="1" [destroyOnHide]="false">
            <a ngbNavLink>Dashboard</a>
            <ng-template ngbNavContent>
              <app-dashboard
              (selectedTask) = passIdToSV($event)>
            </app-dashboard>
            </ng-template>
          </li>
          <li [ngbNavItem]="2">
            <a ngbNavLink>Site View</a>
            <ng-template ngbNavContent>
              <app-site-view
              [dbTask] = "selectedTask"></app-site-view>
            </ng-template>
          </li>
        </ul>
      </ul>
    </div>
  </div>
</nav>
<div class="container-fluid">
  <div class="row">
    <div [ngbNavOutlet]="nav" class="mt-2"></div>
  </div>
</div> -->


import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { PanoService, ScanService } from 'zontro-openapi-frontend';
declare var pannellum: any;
import {NgbDateStruct, NgbDate, NgbCalendar} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-pano',
  templateUrl: './pano.component.html',
  styleUrls: ['./pano.component.css']
})
export class PanoComponent implements OnInit {
  
  @Input() scan: any
  @Input() setLock: any
  @Input() mapPos: any
  @Output() forgePos = new EventEmitter<any>()
  @Output() yawPitchScan = new EventEmitter<any>()
  @Output() calDate = new EventEmitter<any>()
  @Output() mapLocs = new EventEmitter<any>()
  @Output() mapScene = new EventEmitter<any>()

  projectId: any ='Aerospace_REV3'

  v: any;
  panoList: any;
  panoLength: number = 0;
  model!: NgbDateStruct 
  isEnabled : any;
  scanList: any
  date = '2022-04-18T06:10:20Z'
  closestScene: any;
  calDateArr: any = [];
  minDate!: NgbDateStruct 
  maxDate!: NgbDateStruct 
  unlocked: boolean = false;
  currentScene: any;
  currentYaw: any;
  constructor(private panoService: PanoService,
              private scanService: ScanService, 
              private changeDetectorRef: ChangeDetectorRef,
              ) { }

  ngOnInit(){
  }
  ngOnChanges(changes: SimpleChanges) {
    if(changes['scan']!==undefined && this.scan){
      //if(!changes["scan"].isFirstChange()){
        //if(this.closestDone==true){
          let newScan = changes['scan'].currentValue;
          this.scan=newScan
          this.scanService.getScan(this.projectId,this.date,newScan.areaId).subscribe(
            result => {
              this.calDateArr = []
              this.scanList = result
              for(var i=0;i<result.length;i++){
                this.scanList[i].scanDate = this.scanList[i].scanDate.slice(0,10) 
                var calDate = {
                  "year":parseInt(this.scanList[i].scanDate.substring(0,4)),
                  "month":parseInt(this.scanList[i].scanDate.substring(5,7)),
                  "day":parseInt(this.scanList[i].scanDate.substring(8,10))
                }
                this.calDateArr.push(calDate)
              }
              this.maxDate = {
                "year":parseInt(this.scanList[0].scanDate.substring(0,4)),
                "month":parseInt(this.scanList[0].scanDate.substring(5,7)),
                "day": 31
              };
              this.minDate = {
                "year":parseInt(this.scanList[this.scanList.length-1].scanDate.substring(0,4)),
                "month":parseInt(this.scanList[this.scanList.length-1].scanDate.substring(5,7)),
                "day": 1
              };
              this.isEnabled = (date: NgbDateStruct) => {
                return this.calDateArr.find((x: any) => (new NgbDate(x.year, x.month, x.day).equals(date)) )
                ? false
                : true;
              };
              //this.selectedScan.emit(this.scans[0])
            }
          )
          this.model = {
            "year": parseInt(newScan.scanDate.substring(0,4)),
            "month": parseInt(newScan.scanDate.substring(5,7)),
            "day": parseInt(newScan.scanDate.substring(8,10))
          }
          //console.log(this.model)
          this.panoService.getPanobyArea(this.projectId,newScan.scanName).subscribe(
            result => {
              this.panoList = result
              this.createViewer(result)
              this.forgePos.emit(result[0])
              this.mapLocs.emit(this.panoList)
              this.panoLength = result.length
              //this.panoCount.emit(result.length)
              
              
            }
          )
        //}
      //}
    }
    if(changes['setLock']!==undefined && this.setLock){ 
      let lock = changes['setLock'].currentValue;
      this.setLock=lock
      //console.log(this.unlocked,this.setLock)
      if(this.setLock==2) {
        this.currentScene = this.v.getScene()
        this.currentYaw = this.v.getYaw()
        this.createViewerZoomable(this.panoList)
        
        this.unlocked=true
      } else if(this.unlocked==true && this.setLock==1) { 
        this.currentScene = this.v.getScene()
        this.currentYaw = this.v.getYaw()
        this.createViewer(this.panoList)
      }
      
    }
    if(changes['mapPos']!==undefined && this.mapPos){
      let newValue = changes['mapPos'].currentValue;
      if(newValue==this.v.getScene()) {}
      else {
        //if(this.closestDone==true){ 
          this.v.loadScene(newValue,10,this.scan.offsetYaw,120)
          this.mapPos=newValue
        //}
      }
    } 
  }
  createViewer(data:any){
    var path1 = this.scan.scanPanoPath.substring(23)
    this.v = pannellum.viewer('panoViewer', { 
      "default": {
        "firstScene": this.scan.panoId || "1",
        "sceneFadeDuration": 1000,
        "autoLoad": true,
        "compass": false,
        //"showControls": false,
        "keyboardZoom": false,
        "mouseZoom": false,
        "showZoomCtrl": false,

      }, "scenes": {
        "1": {
          "title": "Scene 1",
          "hfov": 120,
          "pitch": 10,
          "yaw": parseFloat(this.scan.offsetYaw),
          "type": "equirectangular",
          "panorama": 'assets/'+path1+'/'+data[0].scanPanoName,
          ///"panorama": this.scan.scanPanoPath+'/'+data[0].scanPanoName,
        }
      }  
    });
    
    for (var i=1; i<data.length; i++) { 
      var path1 = this.scan.scanPanoPath.substring(23)
      var sceneId = i+1;
      this.v.addScene(sceneId,{
        "sceneFadeDuration": 1000,
        "title": "Scene "+sceneId,
        "hfov": 120,
        "pitch": -10,
        "autoload": true,
        "yaw": this.scan.offsetYaw,
        "type": "equirectangular",
        "panorama": 'assets/'+path1+'/'+data[i].scanPanoName,
        //"panorama": this.scan.scanPanoPath+'/'+data[i].scanPanoName,
      });
      try{
        if(this.currentScene&&this.currentYaw){
          setTimeout(() => {this.v.loadScene(this.currentScene,10,this.currentYaw,120)}, 1000)
        } else if(this.scan.panoId == data[i].scanPanoName) {
          this.closestScene = i+1
          //this.v.loadScene(this.closestScene,10,this.scan.offsetYaw,120)
          setTimeout(() => {this.v.loadScene(this.closestScene,10,this.scan.offsetYaw,120)}, 2000)
          var pos = this.panoList[i]
          setTimeout(() => {
            this.forgePos.emit(pos)}, 1000)
        } 
      } catch(err) {
        //console.log(err)
      }      // else {
      //   this.closestScene = 1
      //   console.log("else"+this.closestScene, this.scan.panoId)
      //   setTimeout(() => {this.v.loadScene(1,10,this.scan.offsetYaw,120)}, 1000)
      // }
    }
    (<HTMLInputElement>document.getElementById("next-img")).addEventListener('click', this.nextImage.bind(this));
    (<HTMLInputElement>document.getElementById("prev-img")).addEventListener('click', this.prevImage.bind(this));
    this.v.on('scenechange', this.sceneChangeListener.bind(this));
    this.v.on('mouseup', this.onMouseUp.bind(this));
    //this.v.on('zoomchange', this.onZoomChange.bind(this));
    //if(this.scan.panoId)
    this.v.resize() 
  }
  createViewerZoomable(data:any){
    var path1 = this.scan.scanPanoPath.substring(23)
    this.v = pannellum.viewer('panoViewer', { 
      "default": {
        "firstScene": "1",
        "sceneFadeDuration": 1000,
        "autoLoad": true,
        "compass": false,
        //"showControls": false,
        //"keyboardZoom": false,
        //"mouseZoom": false,
        //"showZoomCtrl": false,

      }, "scenes": {
        "1": {
          "title": "Scene 1",
          "hfov": 120,
          "pitch": 10,
          "yaw": parseFloat(this.scan.offsetYaw),
          "type": "equirectangular",
          "panorama": 'assets/'+path1+'/'+data[0].scanPanoName,
          ///"panorama": this.scan.scanPanoPath+'/'+data[0].scanPanoName,
        }
      }  
    });
    
    for (var i=1; i<data.length; i++) { 
      var path1 = this.scan.scanPanoPath.substring(23)
      var sceneId = i+1;
      this.v.addScene(sceneId,{
        "sceneFadeDuration": 1000,
        "title": "Scene "+sceneId,
        "hfov": 120,
        "pitch": -10,
        "autoload": true,
        "yaw": this.scan.offsetYaw,
        "type": "equirectangular",
        "panorama": 'assets/'+path1+'/'+data[i].scanPanoName,
        //"panorama": this.scan.scanPanoPath+'/'+data[i].scanPanoName,
      });

      if(this.currentScene&&this.currentYaw){
        setTimeout(() => {this.v.loadScene(this.currentScene,10,this.currentYaw,120)}, 1000)
      } else if(this.scan.panoId == data[i].scanPanoName) {
        this.closestScene = i+1
        //this.v.loadScene(this.closestScene,10,this.scan.offsetYaw,120)
        setTimeout(() => {this.v.loadScene(this.closestScene,10,this.scan.offsetYaw,120)}, 1000)
        var pos = this.panoList[i]
        setTimeout(() => {
          this.forgePos.emit(pos)}, 1000)
      } 
      // else {
      //   this.closestScene = 1
      //   console.log("else"+this.closestScene, this.scan.panoId)
      //   setTimeout(() => {this.v.loadScene(1,10,this.scan.offsetYaw,120)}, 1000)
      // }
    }
    (<HTMLInputElement>document.getElementById("next-img")).addEventListener('click', this.nextImage.bind(this));
    (<HTMLInputElement>document.getElementById("prev-img")).addEventListener('click', this.prevImage.bind(this));
    this.v.on('scenechange', this.sceneChangeListener.bind(this));
    this.v.on('mouseup', this.onMouseUp.bind(this));
    this.v.resize() 
  }

  

  onMouseUp(event: any){
    var direction = {yaw: this.panoList[this.v.getScene()-1].rotation-(this.v.getYaw()-this.scan.offsetYaw),pitch: this.v.getPitch()}
    //console.log(direction)
    this.yawPitchScan.emit(direction)
    this.changeDetectorRef.detectChanges();
  };
  nextImage(event: any){
    if(this.v.getScene()==1){
      this.v.loadScene(2,10,this.scan.offsetYaw,120)
    } else if(this.v.getScene()==this.panoLength) {
      this.v.loadScene(1,10,this.scan.offsetYaw,120)
    } else {
      var scene = this.v.getScene()+1
      this.v.loadScene(scene,10,this.scan.offsetYaw,120)
    }
  }
  prevImage(event: any){
    if(this.v.getScene()==1){
      this.v.loadScene(this.panoLength,10,this.scan.offsetYaw,120)
    } else if(this.v.getScene()==this.panoLength){
      this.v.loadScene(this.panoLength-1,10,this.scan.offsetYaw,120)
    } else {
    var scene = this.v.getScene()-1
    this.v.loadScene(scene,10,this.scan.offsetYaw,120)
    }
  }

  sceneChangeListener(event: any) {
    this.forgePos.emit(this.panoList[event-1])
    this.mapScene.emit(event)
  };

  // selectScan (event: any) {
  //   for(var i=0;i<this.scanList.length;i++){
  //     if(this.scanList[i].scanName==event.target.value){
  //       console.log(this.scanList[i])
  //       this.scan = this.scanList[i]
  //       this.panoService.getPanobyArea(this.projectId,this.scanList[i].scanName).subscribe(
  //         result => {
  //           this.createViewer(result)
  //           this.forgePos.emit(result[0])
  //           this.panoLength = result.length
  //           //this.panoCount.emit(result.length)
  //           this.panoList = result
  //         }
  //       )
  //     }
  //   }
  // }
  changeDate(e: any){
    //console.log(this.panoList(this.v.getScene()-1))
    for(var i=0;i<this.scanList.length;i++){

      var calDate = {
        "year":parseInt(this.scanList[i].scanDate.substring(0,4)),
        "month":parseInt(this.scanList[i].scanDate.substring(5,7)),
        "day":parseInt(this.scanList[i].scanDate.substring(8,10))
      }
      if(calDate.year==e.year && calDate.month==e.month && calDate.day==e.day){
        //this.calDate.emit(this.scanList[i])
        this.model = calDate
        this.scan = this.scanList[i]
        this.calDate.emit(this.scan)
        this.panoService.getPanobyArea(this.projectId,this.scanList[i].scanName).subscribe(
          result => {
            this.createViewer(result)
            this.forgePos.emit(result[0])
            this.panoLength = result.length
            //this.panoCount.emit(result.length)
            this.panoList = result
            this.mapLocs.emit(this.panoList)
          }
        )
      }
    }
    
  }
} 

import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'zontro-openapi-frontend';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
  email: string = ''
  password: string = ''
  password2: string = ''
  name: string = ''
  client: string = ''
  user: any
  errorMessage: string = '';
  signupUser!: string;
  code: string='';
  c1!: string; 
  c2!: string; 
  c3!: string; 
  c4!: string; 
  c5!: string; 
  c6!: string; 
  verify: boolean =false;
  toVerify: any;
  msg: boolean = false;
  currentUser: any;
  clientList: any
  constructor(private authService: AuthService, 
              private ref: ChangeDetectorRef,
              private userService: UserService) { }

  ngOnInit(){
    this.signupUser = this.email;
    this.code.concat(this.c1,this.c2,this.c3,this.c4,this.c5,this.c6)
    this.ref.detectChanges();
  }
  async onSignup(form: NgForm){
    if(this.password!=this.password2) {
      this.errorMessage = "Passwords don't match."
    } else {
    try {
      this.user = await this.authService.signUp(form.value.email, form.value.password, form.value.name)
      if(!this.user.userConfirmed) {
        this.verify = true; 
        this.errorMessage=''
        // this.userService.createUser(this.user).subscribe(
        //   result => {
        //     // console.log(result)  
        //   },error => {
        //     //this.displayMessage('Error adding user', 'danger')
        //     console.log(error)
        //   }
        // )
      }
      if (this.user!==undefined) {
        return  
      } else {
          this.authService.setLoggedIn(true)
          //this.router.navigate(['signup'])
      }
    } catch(error:any){
      console.log(error)
      var er = error
      if(error.code=="InvalidParameterException") {
        this.errorMessage = 'Require atleast 1 number, special character, uppercase letter & lowercase letter with minimum password length of 6'
      } else if(error=="InvalidParameterException: Username should be an email."){
      this.errorMessage = 'Please enter a valid Email'
      } else if(error.code == "UsernameExistsException") {
        this.verify=true
      } else {this.errorMessage=error}
      // if(error='UsernameExistsException: An account with the given email already exists.') {
      //   this.verify = true
      // }
    }
    }
  }
  async onVerify() {
    this.code = this.c5.concat(this.c6.toString())
    this.code = this.c4.concat(this.code.toString())
    this.code = this.c3.concat(this.code.toString())
    this.code = this.c2.concat(this.code.toString())
    this.code = this.c1.concat(this.code.toString())
    try {
      await this.authService.verify(this.email, this.code)
      this.msg=true
    } catch(error:any){
      console.log(error)
      this.errorMessage = error
    }
  }
  async onResendCode() {
    try {
      this.user = await this.authService.resendCode(this.email)
      this.toVerify=this.user.CodeDeliveryDetails.Destination
    } catch(error:any){
      console.log(error)
      this.errorMessage = error
    }
  }
  onVerifySuccess(){
    this.authService.verifySuccess()
  }
  moveToNext(event: any) {
    const key = event.key; // const {key} = event; ES6+
    let next = event.target.nextElementSibling;
    let previous = event.target.previousElementSibling;
    if (key === "Backspace" || key === "Delete") {
        previous.focus()
    } else 
    
    
    if (next) {
      next.focus();
    } else {
      event.target.blur();
    }
  }
}

import { ChangeDetectorRef, Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { PanoService } from 'zontro-openapi-frontend';
import {Location} from '@angular/common'; 
import { AuthService } from 'src/app/services/auth.service';
import * as bootstrap from "bootstrap"; 
//import $ from 'jquery';
declare var $ : any;
@Component({
  selector: 'app-site-viewer',
  templateUrl: './site-viewer.component.html',
  styleUrls: ['./site-viewer.component.css']
})
export class SiteViewerComponent implements OnInit {
  scan_name: any;
  showAreaList: boolean = true
  large: boolean = true;
  mapmin: boolean = false;
  bim: boolean = true;
  panoCount: number = 0;
  areamin: boolean = false;
  markers: any;
  currentScene: any = 1;
  newScene: any;
  isArea: boolean = false;
  viewS: any;
  hfovS: any
  setLock:number = 0
  floor: any = "not found";
  projectId='Aerospace_REV3'
  forgePos: any;
  urn: any
  closestPano: any
  showLoader: boolean = true
  //taskId: any
 // selectedTask: number;
  taskMapArr:any = [
                  {task: 392, floor: 'unit6floor1', pano: 'R0010861', scene: 1},
                  {task: 590, floor: 'unit6floor1', pano: 'R0010866', scene: 6},
                  {task: 1452,floor: 'unit6floor1', pano: 'R0010867', scene: 7},
                  {task: 1523,floor: 'unit6floor1', pano: 'R0010874', scene: 14},
                  {task: 1503,floor: 'unit6floor1', pano: 'R0010879', scene: 19},
                  {task: 1505,floor: 'unit6floor1', pano: 'R0010879', scene: 19},
                  {task: 1506,floor: 'unit6floor1', pano: 'R0010879', scene: 19},
                  {task: 1453,floor: 'unit6floor1', pano: 'R0010879', scene: 19},
                  {task: 2266,floor: 'unit6floor1', pano: 'R0010874', scene: 14},
                  {task: 1435,floor: 'unit6floor1', pano: 'R0010872', scene: 12},
                  {task: 1607,floor: 'unit6floor1', pano: 'R0010873', scene: 13},
                  {task: 1608,floor: 'unit6floor1', pano: 'R0010873', scene: 13},
                  {task: 1644,floor: 'unit6floor1', pano: 'R0010870', scene: 10},
                  {task: 1645,floor: 'unit6floor1', pano: 'R0010870', scene: 10},
                  {task: 1674,floor: 'unit6floor1', pano: 'R0010871' , scene: 11},
                  {task: 1512,floor: 'unit6floor2', pano: 'R0010895', scene: 4},
                  {task: 1514,floor: 'unit6floor2', pano: 'R0010899', scene: 8},
                  {task: 1498,floor: 'unit6floor2', pano: 'R0010898', scene: 7},
                  {task: 2207,floor: 'unit6floor2', pano: 'R0010898', scene: 7},
                  {task: 1594,floor: 'unit6floor2', pano: 'R0010893', scene: 2},
                  {task: 1595,floor: 'unit6floor2', pano: 'R0010893', scene: 2},
                  {task: 2267,floor: 'unit6floor2', pano: 'R0010899', scene: 8},
                  ]
  taskScene: any;
  bimScene: any;
  selectedTask!: number;
  selectedArea: any;
  closestUrn: any;
  currentUser: any;
  showDeviation: any;
  deviationDetails: any;
  @Input() dbTask: any
 


  constructor(private route: ActivatedRoute, 
              private changeDetectorRef: ChangeDetectorRef, 
              private panoService: PanoService, 
              private location: Location,
              private authService: AuthService) { }

  async ngOnInit() {
    try {
      this.currentUser = await this.authService.getCurrentUser()
      const { attributes } = this.currentUser;
    } catch(error:any){
      if(error == "The user is not authenticated") {
        
      } else {this.onProceed()}
    }
    const routeParams = this.route.snapshot.paramMap
    const taskIdFromRoute = Number(routeParams.get('taskId'));
    this.selectedTask = taskIdFromRoute;
    this.panoService.getClosestPano(this.projectId, this.selectedTask).subscribe(
      result => {
        if(!jQuery.isEmptyObject(result)) {
          this.closestPano = result
          this.closestUrn=this.closestPano.urn
        } else {
          this.closestPano = {
            "panoId": "R0011609.JPG",
            "scanName": "20220119-15_14_21",
            "areaId": "unit3floor1",
            "bounds": "322.82, 479.79, 516.90, 666.55",
            "floorPlan": "bim_u3f1.jpg",
            "offsetYaw": 100,
            "scanPanoPath": "Z:\\Zontro\\BackendFromD\\AS3_Algorithms\\m20220119\\munit3\\mfloor1\\mscan\\pano",
            "urnOffset": "287.01859167688576, 621.3871459520716, -15.010679910375941",
            "urn": "urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6bXZpem5fYnVja2V0X2FzM191bml0M3dob2xlX2xpbmsvQVMzX1VuaXQzX1dob2xlLnppcA"
            }
            this.closestUrn=this.closestPano.urn = "urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6bXZpem5fYnVja2V0X2FzM191bml0M3dob2xlX2xpbmsvQVMzX1VuaXQzX1dob2xlLnppcA"
            this.location.replaceState("/site-viewer/home");
        }
      })
    for(var i = 0; i<this.taskMapArr.length;i++){
      if(taskIdFromRoute===this.taskMapArr[i].task) {
        this.taskScene = this.taskMapArr[i].scene
      }
    }
    if(this.floor =="not found") {
      this.floor = this.taskMapArr[0].floor
      this.taskScene = this.taskMapArr[0].scene
    }
    // $( document ).ready(function() {
      
    //   window.$('#myModal').modal('hide');
    // });

  }
  onProceed() {
    this.authService.setLoggedIn(true)
    const { attributes } = this.currentUser;
  }

  passScan(data:any) {
    this.scan_name = data 
    this.isArea = true
    this.changeDetectorRef.detectChanges();
  }
  showArea(){
    this.showAreaList=!this.showAreaList
  }
  doResize(){
    if(this.large) {
      this.large = !this.large;
    }  else {
      this.large = !this.large;
    }
    if(this.large==false) {
      this.showLoader=true
    }
    this.changeDetectorRef.detectChanges();
  }
  // openMap(){
  //   if(this.mapmin){
  //     this.mapmin = !this.mapmin;
  //   } else {
  //     this.mapmin=!this.mapmin
  //   }
  // }
  openBim(){
    if(this.bim) {
      this.bim = !this.bim;
    }  else {
      this.bim = !this.bim;
    }
  }
  passPanoCount(data: any) {
    this.panoCount = data
  }
  openArea() {
    if(this.areamin){
      this.areamin = !this.areamin;
    } else {
      this.areamin=!this.areamin
    }
  }
  // passMarkers(data:any){
  //   this.markers = data
  //   this.changeDetectorRef.detectChanges();
  // }
  passSceneId(id: any) {
    this.currentScene = id
  }
  posScanBim(scene: any) {
    this.newScene = scene
  }
  posBim(scene: any){
    this.bimScene = scene
  }
  onViewChange(data: any) {
    this.viewS = data;
    //this.changeDetectorRef.detectChanges();
  }
  onZoomChange(hfov: number) {
    this.hfovS = hfov;
    this.changeDetectorRef.detectChanges();
  }
  viewLocked() {
    if(this.setLock==0){
      this.setLock=1
    } else this.setLock=0
    this.changeDetectorRef.detectChanges();
  } 

  passForgePos(ev: any){
    this.forgePos = {
      x1:ev.xPos,
      x2:ev.xTarget,
      y1:ev.yPos,
      y2:ev.yTarget,      
      z1:ev.zPos,
      z2:ev.zTarget,
    }
  }
  passUrn(urn: any) {
    this.urn=urn.urn
    this.selectedArea=urn
  }
  showDevPopup(ev: any) {
    this.showDeviation = ev.show
    this.deviationDetails = ev.deviation

  }
  closeDeviation() {
    this.showDeviation = false
    this.changeDetectorRef.detectChanges();
  }
  removeLoader(ev: any) {
    this.showLoader = false
  }
  ngAfterContentChecked(): void {
    this.changeDetectorRef.detectChanges();
  }
  ngOnChanges(changes: SimpleChanges) { 
    if(changes['dbTask']!==undefined && this.dbTask){
      let newValue = changes['dbTask'].currentValue;
      this.panoService.getClosestPano(this.projectId, newValue).subscribe(
        result => {
          if(!jQuery.isEmptyObject(result)) {
            this.closestPano = result
            this.closestUrn=this.closestPano.urn
          } else {
            this.closestPano = {
              "panoId": "R0011609.JPG",
              "scanName": "20220119-15_14_21",
              "areaId": "unit3floor1",
              "bounds": "322.82, 479.79, 516.90, 666.55",
              "floorPlan": "bim_u3f1.jpg",
              "offsetYaw": 100,
              "scanPanoPath": "Z:\\Zontro\\BackendFromD\\AS3_Algorithms\\m20220119\\munit3\\mfloor1\\mscan\\pano",
              "urnOffset": "287.01859167688576, 621.3871459520716, -15.010679910375941",
              "urn": "urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6bXZpem5fYnVja2V0X2FzM191bml0M3dob2xlX2xpbmsvQVMzX1VuaXQzX1dob2xlLnppcA"
              }
              this.closestUrn=this.closestPano.urn = "urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6bXZpem5fYnVja2V0X2FzM191bml0M3dob2xlX2xpbmsvQVMzX1VuaXQzX1dob2xlLnppcA"
              //this.location.replaceState("/site-viewer/home");
          }
        })
    } 
  }
}

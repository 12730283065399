import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Task, TaskService } from 'zontro-openapi-frontend';
import { ChartService } from 'src/app/services/chart.service';
import { ActivatedRoute } from '@angular/router';
//import { TooltipComponent } from './tooltip.component';
@Component({
  selector: 'app-overall-cards',
  templateUrl: './overall-cards.component.html',
  styleUrls: ['./overall-cards.component.css']
})
export class OverallCardsComponent implements OnInit {
  myOptions = {
    'placement': 'left',
    'showDelay': 500
}
  //component = TooltipComponent;
  projectId: any
  date = new Date().toISOString()
  planned: any;
  actual: any;
  plannedProgress: number = 0
  actualProgress: any = 0
  plannedEndDate: any
  expectedEndDate: any
  @Output() projectTaskId = new EventEmitter<any>()
  view: any = [90, 90];
  legend: boolean = true;
  legendPosition: string = 'below';
  @Input() overallDelay: any

  colorSchemePlanned = {
    domain: ['#4E91DA', '#ffffff']
  };
  colorSchemeActual = {
    domain: ['#ffcc80', '#ffffff']
  };
  end: any;

  constructor(private taskService: TaskService, 
              private chartService: ChartService,
              private route: ActivatedRoute) {
    //Object.assign(this, { this.single });
  }
  format(data: any) {
    return '';
  }
  units(){
    return "data"
  }

  ngOnInit() {
  }
  ngOnChanges(changes: SimpleChanges) {
    if(changes['overallDelay']!==undefined && this.overallDelay){
      let data = changes['overallDelay'].currentValue;
      this.overallDelay=data
      const routeParams = this.route.snapshot.paramMap
      const projectId = routeParams.get('projectId');
      this.projectId = projectId
      this.taskService.getChild(this.projectId,this.date,0).subscribe(
      result => {
        this.cleanData(result)
        var res = new Date(this.end);
      var f = res.setDate(res.getDate() + this.overallDelay);
      var date = new Date(f)
      this.expectedEndDate = date.toISOString().split('T')[0];
      });
      
      
    }
  }
  cleanData(result: any) {
    this.actualProgress = Math.round(result[0].progress*100)
      this.end = result[0].planned_end
      this.plannedEndDate = this.chartService.getDate(result[0].planned_end)
      this.plannedProgress = this.chartService.plannedProgress(result[0].planned_end, result[0].planned_start)
      this.projectTaskId.emit(result[0].id)
      this.actual = [
        {
        "name": "actual",
        "value": result[0].progress*100
        },
        {
        "name": "total",
        "value": 100
        }
      ];
      this.planned = [
        {
          "name": "planned",
          "value": this.plannedProgress
        },
        {
          "name": "total",
          "value": 100
        }
      ];
  }

}

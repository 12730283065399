import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { SigninComponent } from './auth/signin/signin.component';
import { SignupComponent } from './auth/signup/signup.component';
//import { DashboardComponent } from './dashboard/dashboard.component';
//import { SiteViewerComponent } from './site-viewer/site-viewer.component';
import { MainUiComponent } from './main-ui/main-ui.component';
import { OverviewComponent } from './overview/overview.component';
import { SetupComponent } from './overview/setup/setup.component';
import { PreviewComponent } from './preview/preview.component';
const routes: Routes = [
    { path: '',   redirectTo: '/signin', pathMatch: 'full' },
    {path: 'signup', component: SignupComponent},
    {path: 'signin', component: SigninComponent},
    {path: 'forgotpwd', component: ForgotPasswordComponent},
    {path: 'overview', component: OverviewComponent},
    {path: 'ui/:orgName/:projectId', component: MainUiComponent},
    {path: 'newproject', component: SetupComponent},
    {path: 'preview/:orgName/:projectId', component: PreviewComponent}  
    
    //{path: 'dashboard', component: DashboardComponent},
    //{path: 'site-viewer', component: SiteViewerComponent},
    //{path: 'site-viewer/:taskId', component: SiteViewerComponent},
    
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { rejects } from 'assert';
import { TaskService } from 'zontro-openapi-frontend';
declare var anychart: any
@Component({
  selector: 'app-delay-sunburst',
  templateUrl: './delay-sunburst.component.html',
  styleUrls: ['./delay-sunburst.component.css']
})
export class DelaySunburstComponent implements OnInit, AfterViewInit  {
  @ViewChild('chartContainer') container: any;
  projectId: any = 'Aerospace_REV3';
  date = '2022-01-14T22:00:00.000Z'
  //idSelect: any;
  dataTree: any
  chart: any
  @Output() idSelect = new EventEmitter<any>()
  prevId: any = 1;

  constructor(private taskService: TaskService,
              private route: ActivatedRoute,) { }

  ngOnInit(){
    const routeParams = this.route.snapshot.paramMap
    const projectId = routeParams.get('projectId');
    this.projectId = projectId
    this.taskService.getDelay(this.projectId,this.date,815).subscribe(
      (data:any) => {
        
        this.dataTree = anychart.data.tree(data, 'as-table');
        this.chart = anychart.sunburst(this.dataTree);
        this.chart.container(this.container.nativeElement);
        
        var padding = this.chart.padding();
        padding.top('0px');
        padding.right('0%');
        padding.bottom('0px');
        padding.left('0px');
        var margin = this.chart.margin();
        margin.top('0px');
        margin.right('0px');
        margin.bottom('0px');
        margin.left('0px');
        this.chart.level(-2).labels().position('radial');
        this.chart.level(-2).labels().minFontSize(8).textOverflow('...');
        this.chart.labels().hAlign('center');
        this.chart.leaves().labels().minFontSize(8).textOverflow('...');
         this.chart.level(3).enabled(true)
         this.chart.level(4).enabled(true)
        this.chart.level(5).enabled(true)
        this.chart.level(6).enabled(true)
        this.chart.level(7).enabled(false)
        this.chart.level(3).thickness('5%')
        this.chart.level(4).thickness('5%')
        this.chart.level(5).thickness('5%')
        this.chart.level(6).thickness('5%')
        this.chart.radius('49%')
        this.chart.tooltip().format("{%name}\n\nid: {%id}\nprogress:{%progress}%\ndelay:{%delay}days");
        this.chart.listen("drillChange", this.getData.bind(this))
        //this.chart.listen("animationEnd", this.getData.bind(this))
        this.chart.draw();
      })
  }
  getData(e: any) {
    this.idSelect.emit(e.path[e.path.length-1].node.ka.id) 
    var chartLevel = this.chart.state.target.hn.currentrootdepth
    var maxChartLevel = this.chart.state.target.hn.treemaxdepth
    if(this.prevId==e.path[e.path.length-1].node.ka.id) {
      for(var i=chartLevel+1;i<=maxChartLevel;i++) {
        try{
          var l1 = this.chart.level(i)
          l1.thickness('5%');
        } catch(err) {
          console.log(err)
        break
        }
      }
      for(var j=0;j<=chartLevel;j++) {
        try{
          var l1 = this.chart.level(j)
          l1.thickness('30%');
        } catch(err) {
          console.log(err)
          break
        }
      } 
    } else {
      for(var i=chartLevel+3;i<=maxChartLevel;i++) {
       try{
        var l1 = this.chart.level(i)
        l1.thickness('5%');
      } catch(err) {
        console.log(err)
        break
      }
      }
      for(var j=0;j<=chartLevel+2;j++) {
        try{
          var l1 = this.chart.level(j)
          l1.thickness('30%');
        } catch(err) {
          console.log(err)
          break
        }

      }
    }
    this.prevId = e.path[e.path.length-1].node.ka.childOf 
  }
  ngAfterViewInit() {
  }
  ngOnDestroy() {
    if(this.chart) {
      this.chart.dispose();
    }
  }
}

<nav class="navbar navbar-expand-lg pb-1  navbar-light bg-light pt-0 mb-4 shadow fixed-top" style="height: 3% !important;">
    <div class="container-fluid">
        <a class="navbar-brand" href="#">
            <img src="../../assets/images/logo.png" alt="" width="30" height="30" class="d-inline-block align-text-top">
            <a style="color: #4E91DA; font-weight: 650;">    Zontro</a>    
            <a class="navbar-brand" href="#" style="color: rgb(71, 71, 71); font-size: smaller; padding-left: 20%;" *ngIf = "projectDetails!==undefined">
                Project: {{projectDetails.projectName}} ({{orgName}}) 
            </a>   
        </a>
        <div>
            <div ngbDropdown class="d-inline-block">
                <button type="button" class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>{{userInitials | uppercase }}</button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <button ngbDropdownItem (click)="signout()">Signout</button>
                </div>
            </div>
        </div>
    </div>
</nav>

<div class="container-fluid mt-5 pt-4">
    <div class="row ">
        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
            <!-- <button class="btn btn-primary" type="button" (click)="skip()">Preview Master Schedule</button>
            <button class="btn btn-primary" type="button" [disabled]="fileUploaded" (click)="confirmProject()">Preview BIM Viewer</button> -->
            <button class="btn btn-primary" type="button" (click) = "backToOverview()">Back to Project List</button>
            <!-- <button class="btn btn-primary" type="button" >Preview BIM Viewer</button> -->
        </div>
    </div>
    <br>
    <div class="card h-100 pl-4 " >
        <div class="card-header">
            <div class="row" *ngIf = "projectDetails!==undefined">
                <p style="font-size: 18px; ">Project: <span style="font-weight: 600; color: rgb(93, 97, 99);">{{projectDetails.projectName}}</span></p>
                <p style="font-size: 18px; ">Owner: <span style="font-weight: 600; color: rgb(93, 97, 99);">{{projectDetails.projectOwner}}</span></p>
                <p style="font-size: 18px; ">Created on: <span style="font-weight: 600; color: rgb(93, 97, 99);">{{projectDetails.creationDate.substring(0,10)}}</span></p>
                <p style="font-size: 18px; ">Organization: <span style="font-weight: 600; color: rgb(93, 97, 99);">{{projectDetails.orgName}}</span></p>
            </div>
        </div>
        <div class="card-body">
            <h5 class="card-title">Files</h5>
        <table class="table table-hover" >
            <thead style="border-bottom: hidden !important;">
                <tr style="background-color: #DBEDFF; font-weight: 500;">
                    <td scope="col">Name</td>
                    <td scope="col">Category</td>
                    <td >SubCategory</td>
                    <td >Status</td>
                </tr>
            </thead>
            <tbody style="border-top: hidden;">
                <tr *ngIf="!fileList || fileList.length==0">No files uploaded yet</tr>
                <tr *ngFor="let files of fileList">
                    <td>{{files.fileName}}</td>
                    <td scope="row">
                        <i *ngIf="files.fileType === 'BIM-Schedule Mapping'" class="far fa-file-excel"></i>
                        <i *ngIf="files.fileType === 'Schedule'" class="fas fa-tasks"></i>
                        <i *ngIf="files.fileType === 'Site Image'" class="far fa-image"></i>
                        <i *ngIf="files.fileType === 'BIM'"  class="far fa-folder"></i>
                        <i *ngIf="files.fileType === 'Others'" class="far fa-file"></i>
                        <i class="fas fa-cubes" *ngIf="files.fileType === 'Lidar Scan'"></i>
                        &nbsp;&nbsp;{{files.fileType}}</td>
                    <td >{{files.subcategory}}</td>
                    <td [ngStyle]="{'background-color': 
                                    files.status==='Master schedule processed. Ready to view on Dashboard' || 
                                    files.status==='BIM Model for Forge successfully created. Ready to view on Site Viewer' ? '#c8e6c9' : 
                                    (files.status==='File uploaded to storage, waiting for batch processing.' ? '#eceff1' : '#fff9c4') }"> {{files.status}}</td> 
                    <!-- <td scope="col-sm-1"><i class="far fa-trash-alt clickable-icon" (click)="deleteFile(files)"></i></td> -->
                </tr>
            </tbody>
        </table>
        <br><br>
        <h5>Note:</h5>
        <h6>1. The uploaded files will be viewable from Project List once the status for files under the categories Schedule and BIM turns green.</h6>
        <h6>2. Time to process a BIM file of size 700MB(approx) is around 15-20 minutes.</h6>  
        <h6>3. Time to process Mapping files and Lidar Scan is around 48 hours.</h6>
        <!-- <div *ngFor="let files of fileList">
              {{files}}
          </div> -->
                                         <!-- <div *ngIf="message && (!isSaving)"  style="color: red;">{{message}}</div> -->
            
        </div>
    <!-- <div class="card-footer text-muted text-center">
        Uploaded by {{username}}
    </div> -->
    </div>
</div>
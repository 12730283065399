import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return from(this.handle(request, next))
  }

  async handle(req: HttpRequest<any>, next: HttpHandler) {
    let token = await this.authService.getIdToken()
    //console.log(token)
    let headers: any = {
      'Authorization': `Bearer ${token}`
    }
    if(!(req.body instanceof FormData)){
      headers['Content-Type']= 'application/json'
    }
    const authReq = req.clone({
      setHeaders: headers
    })
    //console.log(authReq)
    return next.handle(authReq).toPromise()
  }
}

<nav class="navbar navbar-expand-lg pb-1  navbar-light bg-light pt-0 mb-4 shadow fixed-top" style="height: 3% !important;">
    <div class="container-fluid">
        <a class="navbar-brand" href="#">
            <img src="../../assets/images/logo.png" alt="" width="30" height="30" class="d-inline-block align-text-top">
            <a style="color: #4E91DA; font-weight: 650;">    Zontro</a>    
            <a class="navbar-brand" href="#" *ngIf = "orgName!=='' && !projectConfirmed" style="color: rgb(71, 71, 71); font-size: smaller; padding-left: 20%;">
                Organization: {{orgName}} 
            </a> 
            <a class="navbar-brand" href="#" *ngIf = "projectConfirmed" style="color: rgb(71, 71, 71); font-size: smaller; padding-left: 20%;">
                Project: {{projectName}} ({{orgName}}) 
            </a>   
        </a>
        <div>
            <div ngbDropdown class="d-inline-block">
                <button type="button" class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>{{userInitials | uppercase }}</button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <button ngbDropdownItem (click)="signout()">Signout</button>
                </div>
            </div>
        </div>
    </div>
</nav>
<div class="container-fluid">
    <div class="row">
      <div class="col-sm-2 mt-1 pt-5 ml-2">
        <div class="d-grid gap-2 d-md-flex ml-4">
            <button class="nav-item nav-link-edit w-10 btn btn-primary mr-3" [routerLink]="['../overview']">Back to Project List</button >
        </div>
        
        <!-- <button class="w-20 btn btn-primary mr-3 ">Create Project</button> -->
      </div>
    </div>
</div>
<div class="container-fluid mt-5 pt-4">
    <div class="card h-100 pl-4 " >
        <div class="card-header text-center">
            {{title}}
        </div>
        <div class="card-body">
            <h5 class="card-title text-center">Upload Project Files</h5>
            <!-- <p class="card-text text-center">(Master Schedule, BIM, Schedule to BIM Mapping, LIDAR Scan, Site images)</p> -->
            <div class="project-name">
                <div class="row">
                    <div class="col">
                        <div class="form-floating mb-2 " *ngIf = "!projectConfirmed">
                            <input type="email" class="form-control " size="45" name= "projectName" id="projectName" [(ngModel)]="projectName" placeholder="name@example.com" required>
                            <label for="projectName">Project Name</label>
                        </div> 
                        <div class="project-title" *ngIf = "projectConfirmed && !projectExists">

                            <h5 style="color: rgb(59, 151, 255);"> <span class="text-muted">Project:</span> {{projectName}}</h5> <br>
                        </div>       
                    </div>
                </div>
                <a class="btn btn-primary text-center" *ngIf = "!projectConfirmed" (click) = "addProject()">Confirm</a>
                <a class="btn btn-primary text-center" *ngIf = "projectConfirmed && projectExists" (click) = "loadProject()">Open Existing Project</a>
                <div *ngIf="message && (!isSaving)"  style="color: red;">{{message}}</div>
                <div class="row">
                    <div >
                        <form id="msform" *ngIf = "projectConfirmed && !projectExists">
                            <!-- progressbar -->
                            <!-- <ul id="progressbar">
                                <li class="active">Master Schedule</li>
                                <li>BIM</li>
                                <li>Mapping</li>
                                <li>Others</li>
                            </ul> -->
                            <!-- fieldsets -->
                            <fieldset *ngIf = "step==1">
                                <h6 class="fs-title">Master Schedule (mpp or xml) </h6>
                                <!-- <p class="fs-subtitle">Upload Master Schedule (in mpp or xml format)</p> -->
                                <div class="row" >
                                    <div class="col text-left" >
                                        <p  class="file-selector"  
                                            style=" height:30px; color: cornflowerblue;" 
                                            *ngIf="!scheduleUploaded">
                                                <!-- <i class="fa fa-upload" aria-hidden="true"></i>  -->
                                               <a onclick="document.getElementById('fileSchedule').click()"> Upload File </a>
                                        </p>
                                        <input  type="file"    
                                                id="fileSchedule" 
                                                name="fileSchedule"
                                                accept=".mpp,.xml, text/xml, application/xml, application/vnd.ms-project, application/msproj, application/msproject, application/x-msproject, application/x-ms-project, application/x-dos_ms_project, application/mpp, zz-application/zz-winassoc-mpp" 
                                                #fileSchedule 
                                                (change)="processFile(fileSchedule)" 
                                                style="display:none" />   
                                    </div>
                                    <div class="col-sm-3 text-right" *ngIf="isSaving || fileProcessed" style=" height:30px; color: cornflowerblue;">
                                        <p>{{fileName}}</p>
                                    </div>
                                    <!-- <div class=" col-sm-1 " *ngIf = "fileProcessed && !revitInputs">
                                        <i class="fas fa-cloud-upload-alt fa-lg clickable-icon"  aria-hidden="true" (click)="onSave()" ></i>
                                    </div> -->
                                    <!-- accept=".mpp,.xml, text/xml, application/xml, application/vnd.ms-project, application/msproj, application/msproject, application/x-msproject, application/x-ms-project, application/x-dos_ms_project, application/mpp, zz-application/zz-winassoc-mpp" -->
                                    <div class="col-sm-1" *ngIf="isSaving" style=" height:30px; color: cornflowerblue;">
                                        <i class="fa fa-spinner fa-pulse fa-1x fa-fw"></i> 
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                <!-- <div *ngIf="message && (!isSaving)"  style="color: red;">{{message}}</div> -->
                                <div class="row ">
                                    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                                        <button class="btn btn-primary" type="button" *ngIf = "fileProcessed && !revitInputs && ganttParsed" (click)="onSave()">Upload</button>
                                        <button class="btn btn-primary" type="button" *ngIf="!fileUploaded && scheduleUploaded" (click)="next()">Next</button>
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset *ngIf = "step==2">
                                <h6 class="fs-title">BIM (zipped)</h6>
                                <div class="row" >
                                    <div class="col text-left" >
                                        <p  class="file-selector"  
                                            style=" height:30px; color: cornflowerblue;" >
                                                <!-- <i class="fa fa-upload" aria-hidden="true"></i>  -->
                                               <a onclick="document.getElementById('fileSchedule').click()"> Upload File </a>
                                        </p>
                                        <input  type="file"    
                                                id="fileSchedule" 
                                                name="fileSchedule"
                                                accept=".zip"
                                                #fileSchedule 
                                                (change)="processFile(fileSchedule)" 
                                                style="display:none" />   
                                    </div>
                                    <div class="col-sm-3 text-right" *ngIf="isSaving || fileProcessed" style=" height:30px; color: cornflowerblue;">
                                        <p>{{fileName}}</p>
                                    </div>
                                    <!-- <div class=" col-sm-1 " *ngIf = "fileProcessed && !revitInputs">
                                        <i class="fas fa-cloud-upload-alt fa-lg clickable-icon"  aria-hidden="true" (click)="onSave()" ></i>
                                    </div> -->
                                    <!-- accept=".mpp,.xml, text/xml, application/xml, application/vnd.ms-project, application/msproj, application/msproject, application/x-msproject, application/x-ms-project, application/x-dos_ms_project, application/mpp, zz-application/zz-winassoc-mpp" -->
                                    <div class="col-sm-1" *ngIf="isSaving" style=" height:30px; color: cornflowerblue;">
                                        <i class="fa fa-spinner fa-pulse fa-1x fa-fw"></i> 
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                <div class="input-group mb-3" >
                                    <span class="input-group-text input-span" id="basic-addon1"  >BIM Subdirectory</span>
                                    <input  type="text" 
                                        class="form-control" 
                                        placeholder="Phase 2" 
                                        [(ngModel)]="revitSubFolder" 
                                        aria-label="revitSubFolder" 
                                        aria-describedby="revitSubFolder" 
                                        (keyup)="checkString()" 
                                        name="subd"
                                        aria-describedby="basic-addon1">
                                </div>
                                <div class="input-group mb-3 " >
                                    <span class="input-group-text input-span" id="basic-addon"  >Root Revit filename</span>
                                    <select class="form-select" name="rootFileName"  aria-label="rootFileName select" [(ngModel)]="rootFileName" (mouseup)="checkString()">
                                        <option *ngFor="let item of rootFileList" [value]="item">
                                            {{item}}
                                        </option>
                                      </select>
                                </div>
                                <!-- <div *ngIf="message && (!isSaving)"  style="color: red;">{{message}}</div> -->
                                <div class="row">
                                    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                                        <button class="btn btn-primary" type="button" (click)="previous()">Previous</button>
                                        <button class="btn btn-primary" type="button" *ngIf = "fileProcessed && !revitInputs" (click)="onSave()">Upload</button>
                                        <button class="btn btn-primary" type="button" *ngIf="!fileUploaded" (click)="next()">Next</button>
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset *ngIf = "step==3">
                                <h6 class="fs-title">BIM to Master Schedule Mapping (mapping excel and text files in zipped format) </h6>
                                <!-- <p class="fs-subtitle">Upload Master Schedule (in mpp or xml format)</p> -->
                                <div class="row" >
                                    <div class="col text-left" >
                                        <p  class="file-selector"  
                                            style=" height:30px; color: cornflowerblue;" >
                                                <!-- <i class="fa fa-upload" aria-hidden="true"></i>  -->
                                               <a onclick="document.getElementById('fileSchedule').click()"> Upload File </a>
                                        </p>
                                        <input  type="file"    
                                                id="fileSchedule" 
                                                name="fileSchedule"
                                                accept=".zip"
                                                #fileSchedule 
                                                (change)="processFile(fileSchedule)" 
                                                style="display:none" />   
                                    </div>
                                    <!-- text/plain, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" -->
                                    <div class="col-sm-3 text-right" *ngIf="isSaving || fileProcessed" style=" height:30px; color: cornflowerblue;">
                                        <p>{{fileName}}</p>
                                    </div>
                                    <!-- <div class=" col-sm-1 " *ngIf = "fileProcessed && !revitInputs">
                                        <i class="fas fa-cloud-upload-alt fa-lg clickable-icon"  aria-hidden="true" (click)="onSave()" ></i>
                                    </div> -->
                                    <!-- accept=".mpp,.xml, text/xml, application/xml, application/vnd.ms-project, application/msproj, application/msproject, application/x-msproject, application/x-ms-project, application/x-dos_ms_project, application/mpp, zz-application/zz-winassoc-mpp" -->
                                    <div class="col-sm-1" *ngIf="isSaving" style=" height:30px; color: cornflowerblue;">
                                        <i class="fa fa-spinner fa-pulse fa-1x fa-fw"></i> 
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="input-group mb-3 " >
                                        <span class="input-group-text input-span" id="basic-addon2"  >Subdirectory</span>
                                        <select class="form-select" name="selectedSubdirectory"  aria-label="selectedSubdirectory select" [(ngModel)]="selectedSubdirectory" (change)="checkSelect()">
                                            <option *ngFor="let item of subdirectories" [value]="item">
                                                {{item}}
                                            </option>
                                          </select>
                                    </div>
                                </div>
                                <!-- <div *ngIf="message && (!isSaving)"  style="color: red;">{{message}}</div> -->
                                <div class="row ">
                                    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                                        <button class="btn btn-primary" type="button" (click)="previous()">Previous</button>
                                        <button class="btn btn-primary" type="button" *ngIf = "fileProcessed && !revitInputs" (click)="onSave()">Upload</button>
                                        <button class="btn btn-primary" type="button" *ngIf="!fileUploaded" (click)="next()">Next</button>
                                    </div>
                                </div>
                            </fieldset>
                            <fieldset *ngIf = "step==4">
                                <h6 class="fs-title">Lidar Scan Files (zipped) </h6>
                                <!-- <p class="fs-subtitle">Upload Master Schedule (in mpp or xml format)</p> -->
                                <div class="row" >
                                    <div class="col text-left" >
                                        <p  class="file-selector"  
                                            style=" height:30px; color: cornflowerblue;" >
                                                <!-- <i class="fa fa-upload" aria-hidden="true"></i>  -->
                                               <a onclick="document.getElementById('fileSchedule').click()"> Upload File </a>
                                        </p>
                                        <input  type="file"    
                                                id="fileSchedule" 
                                                name="fileSchedule"
                                                accept=".zip"
                                                #fileSchedule 
                                                (change)="processFile(fileSchedule)" 
                                                style="display:none" />   
                                    </div>
                                    <!-- "image/jpg, image/jpeg, video/mp4, video/x-m4v, .geoslam"  -->
                                    <div class="col-sm-3 text-right" *ngIf="isSaving || fileProcessed" style=" height:30px; color: cornflowerblue;">
                                        <p>{{fileName}}</p>
                                    </div>
                                    <!-- <div class=" col-sm-1 " *ngIf = "fileProcessed && !revitInputs">
                                        <i class="fas fa-cloud-upload-alt fa-lg clickable-icon"  aria-hidden="true" (click)="onSave()" ></i>
                                    </div> -->
                                    <!-- accept=".mpp,.xml, text/xml, application/xml, application/vnd.ms-project, application/msproj, application/msproject, application/x-msproject, application/x-ms-project, application/x-dos_ms_project, application/mpp, zz-application/zz-winassoc-mpp" -->
                                    <div class="col-sm-1" *ngIf="isSaving" style=" height:30px; color: cornflowerblue;">
                                        <i class="fa fa-spinner fa-pulse fa-1x fa-fw"></i> 
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="input-group mb-3 " >
                                        <span class="input-group-text input-span" id="basic-addon2"  >Subdirectory</span>
                                        <select class="form-select" name="selectedSubdirectory"  aria-label="selectedSubdirectory select" [(ngModel)]="selectedSubdirectory" (change)="checkSelect()">
                                            <option *ngFor="let item of subdirectories" [value]="item">
                                                {{item}}
                                            </option>
                                          </select>
                                    </div>
                                </div>
                                <div id="calendar">
                                    <form class="row row-cols-sm-auto">
                                        <div class="col-12">
                                            <div class="input-group">
                                                <input class="form-control" 
                                                   placeholder="yyyy-mm-dd"
                                                   name="dp" 
                                                   [(ngModel)]="model" 
                                                   ngbDatepicker #d="ngbDatepicker"
                                                   (ngModelChange)="changeDate($event)">
                                                <button class="btn btn-outline-secondary " (click)="d.toggle()" type="button" style="background-color:white">
                                                    <i class="fas fa-calendar-alt fa-fw"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <!-- <div *ngIf="message && (!isSaving)"  style="color: red;">{{message}}</div> -->
                                <div class="row ">
                                    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                                        <button class="btn btn-primary" type="button" (click)="previous()">Previous</button>
                                        <button class="btn btn-primary" type="button" *ngIf = "fileProcessed && !revitInputs" (click)="onSave()">Upload</button>
                                        <button class="btn btn-primary" type="button" *ngIf="!fileUploaded" (click)="confirmProject()">Confirm</button>
                                        <button class="btn btn-primary" type="button" *ngIf="!skipOptional && fileUploaded" (click)="skip()">Skip</button>
                                        <!-- <button class="btn btn-primary" type="button" [disabled]="fileUploaded" (click)="confirmProject()">Confirm</button> -->
                                    </div>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
                
            <!--    <div class="row" *ngIf = "projectConfirmed && !projectExists">
                    <div class="col text-left">
                        <p  class="file-selector"  
                            style=" height:30px; color: cornflowerblue;" >
                               <a onclick="document.getElementById('fileSchedule').click()"> Upload Files </a>
                        </p>
                        <input  type="file"    
                                id="fileSchedule" 
                                name="fileSchedule" 
                                #fileSchedule 
                                (change)="processFile(fileSchedule)" 
                                style="display:none" />   
                    </div>
                    <div class="col-sm-3 text-right" *ngIf="isSaving || fileProcessed" style=" height:30px; color: cornflowerblue;">
                        <p>{{fileName}}</p>
                    </div>
                    <div class=" col-sm-1 " *ngIf = "fileProcessed && !revitInputs">
                        <i class="fas fa-cloud-upload-alt fa-lg clickable-icon"  aria-hidden="true" (click)="onSave()" ></i>
                    </div>
                    <div class="col-sm-1" *ngIf="isSaving" style=" height:30px; color: cornflowerblue;">
                        <i class="fa fa-spinner fa-pulse fa-1x fa-fw"></i> 
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <div class="input-group mb-3" *ngIf="addFiles">
                    <span class="input-group-text input-span" id="basic-addon1"  >BIM Subdirectory</span>
                    <input  type="text" 
                        class="form-control" 
                        placeholder="Phase 2" 
                        [(ngModel)]="revitSubFolder" 
                        aria-label="revitSubFolder" 
                        aria-describedby="revitSubFolder" 
                        (keyup)="checkString()" 
                        aria-describedby="basic-addon1">
                </div>
                <div class="input-group mb-3 " *ngIf="addFiles">
                    <span class="input-group-text input-span" id="basic-addon"  >Root Revit filename</span>
                    <input  type="text" 
                            class="form-control" 
                            placeholder="YCC_unit1.rvt" 
                            [(ngModel)]="rootFileName" 
                            aria-label="rootFileName" 
                            aria-describedby="rootFileName" 
                            (keyup)="checkString()" 
                            aria-describedby="basic-addon">
                </div>
                <div *ngIf="message && (!isSaving)"  style="color: red;">{{message}}</div> -->
            </div> 

            <!-- File List -->
            <div *ngIf = "projectConfirmed  && !projectExists"><h5>Files</h5></div>   
            <table class="table table-hover" *ngIf = "projectConfirmed  && !projectExists" >
                <thead style="border-bottom: hidden !important;">
                    <tr style="background-color: #DBEDFF; font-weight: 500;">
                        <td scope="col">Name</td>
                        <td scope="col">Category</td>
                        <td *ngIf = "subCat">SubCategory</td>
                        <td scope = "col-sm-1"></td>
                    </tr>
                </thead>
                <tbody style="border-top: hidden;">
                    <tr *ngIf="!fileList || fileList.length==0">No files uploaded yet</tr>
                    <tr *ngFor="let files of fileList">
                        <td>{{files.fileName}}</td>
                        <td scope="row">
                            <i *ngIf="files.fileType === 'BIM-Schedule Mapping'" class="far fa-file-excel"></i>
                            <i *ngIf="files.fileType === 'Schedule'" class="fas fa-tasks"></i>
                            <i *ngIf="files.fileType === 'Site Image'" class="far fa-image"></i>
                            <i *ngIf="files.fileType === 'BIM'"  class="far fa-folder"></i>
                            <i *ngIf="files.fileType === 'Others'" class="far fa-file"></i>
                            <i class="fas fa-cubes" *ngIf="files.fileType === 'Lidar Scan'"></i>
                            &nbsp;&nbsp;{{files.fileType}}</td>
                        <td *ngIf = "subCat">{{files.subcategory}}</td>    
                        <td scope="col-sm-1"><i class="far fa-trash-alt clickable-icon" (click)="deleteFile(files)"></i></td>
                    </tr>
                </tbody>
              </table>
              <!-- <div *ngFor="let files of fileList">
                  {{files}}
              </div> -->
        </div>
        <div class="card-footer text-muted text-center">
            Uploaded by {{username}}
        </div>
    </div>
</div>
<!-- <app-schedule-preview
    style="display:none"
    
    [myMppFile] = "myMppFile"
    (scheduleAdded) = "scheduleAdded($event)"
    [newMppSaved] = "uploadMpp">
</app-schedule-preview> -->
<app-gantt-schedule 
    style="display:none"
    [myMppFile] = "myMppFile"
    (scheduleAdded) = "scheduleAdded($event)"
    [newMppSaved] = "uploadMpp">
</app-gantt-schedule>

<!-- *ngIf = "step==1 && ganttShow && projectConfirmed && !projectExists && scheduleUploaded" -->
/**
 * Zontro UI APIs
 * Zontro APIs for UI and backend
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface Note { 
    noteId?: number;
    panoId?: number;
    userId?: string;
    text?: string;
    position?: string;
    areaId?: number;
    cat?: string;
    scanName?: string;
    thumbnail?: string;
    resolved?: boolean;
}
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { interval, Subscription } from 'rxjs'
import { AuthService } from 'src/app/services/auth.service';
import { ProjectService, UploadService, UserService } from 'zontro-openapi-frontend';
@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.css']
})
export class PreviewComponent implements OnInit {
  mySubscription: Subscription
  currentUser: any;
  username: any;
  userInitials!: string;
  clientId: string = '';
  orgName: string = ''
  projectId: any = '';
  fileList: any = [];
  sub: any;
  projectDetails: any;
  constructor(              
              private authService: AuthService,
              private userService: UserService,
              private uploadService: UploadService,
              private projectService: ProjectService,
              private route: ActivatedRoute,
              private router: Router) { 
                                      this.mySubscription = interval(5000).subscribe((x =>{
                                      this.doStuff();
                                      }));
                                      }
  
  async ngOnInit() {
    const routeParams = this.route.snapshot.paramMap
    const orgName = routeParams.get('orgName');
    const projectId = routeParams.get('projectId');
    this.projectId = projectId
    this.currentUser = await this.authService.getCurrentUser()
    const { attributes } = this.currentUser;
    this.username = {attributes}.attributes.name
    var initials = []
    initials = this.currentUser.attributes.name.trim().split(" ");
    this.userInitials=""
    if(initials.length>1) {
      this.userInitials = this.userInitials.concat(initials[0].substring(0,1),initials[initials.length-1].substring(0,1))
    } else {
      this.userInitials = this.userInitials.concat(initials[0].substring(0,1))
    }

    if(this.currentUser){
      this.userService.getUserDetails(this.currentUser.username).subscribe(
        (data: any) => {
          if(!jQuery.isEmptyObject(data)) {
              this.orgName = data[0].orgName
              this.clientId = data[0].clientId
              this.uploadService.getFileStatusUI(this.projectId).subscribe(result => {
              this.fileList  = result
              })
              this.projectService.getProjectDetails(this.projectId,this.clientId)
                                                  .subscribe((result: any) => { 
                                                    this.projectDetails = result[0]
                                                  })
          }
          if(data.length==0) {
            this.router.navigate(['signin'])
          }
        }
      )
    }
    
  }
  doStuff() {
    this.uploadService.getFileStatusUI(this.projectId).subscribe(result => {
      this.fileList  = result
      console.clear()
    })
  }
  backToOverview() {
    this.router.navigate(['overview'])
  }
  signout() {
    this.authService.signOut()
    this.authService.setLoggedIn(false)
  }
  ngOnDestroy() {
    if(this.mySubscription) this.mySubscription.unsubscribe()
  }
}

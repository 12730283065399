import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { color } from 'echarts';
import { ProjectService } from 'zontro-openapi-frontend';
import { BimcompService } from 'zontro-openapi-frontend';
import { TaskService } from 'zontro-openapi-frontend'
declare const Autodesk: any;
//import * as THREE from 'three'
//const THREE = window.THREE;
declare const THREE: any
//declare const  SetNavigationExtension: any
@Component({
  selector: 'app-forge',
  templateUrl: './forge.component.html',
  styleUrls: ['./forge.component.css']
})
export class ForgeComponent implements OnInit {
  viewer: any
  @Input() panoPos: any
  @Input() selectedTask: any
  @Input() areaUrn: any
  @Input() closestUrn: any
  @Input() area: any
  @Input() panoYaw: any
  @Input() setLock: any;
  @Input() hfovS: any
  @Input() showDeviation : any
  @Output() devPopup = new EventEmitter<any>()
  @Output() modelLoaded = new EventEmitter<any>()
  bimLoaded: boolean = false
  projectId: any ='Aerospace_REV3'
  config3d = {
    extensions: ['SetNavigationExtension'
                 ],
  };
  currentUrn: any
  //currentUrn: any = "urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6bXZpem5fYnVja2V0X2FzM191bml0M3dob2xlX2xpbmsvQVMzX1VuaXQzX1dob2xlLnppcA";
  token: string | undefined;
  bimColor!: number;
  deviatedList: any =[];
  enableOcclusion = false;
  dvExt: any;
  showDev: boolean=false
  showMod: boolean = false;
  allDbId: any = [];
  options: any
  colorClear: any = false;
  taskIdFromRoute!: string;
  rejectYaw: boolean = true
  dtv: any;
  style: any;
  viewableData: any;
  devShown: boolean = false;
  constructor(
    private projectService: ProjectService,
    private bimService: BimcompService,
    private taskService: TaskService,
    private route: ActivatedRoute,
    ) { }

  ngOnInit(){
    
    this.projectService.getForgeToken(this.projectId,this.area.areaId).subscribe(
      result => {
        if(this.closestUrn!==undefined && this.closestUrn){
          this.currentUrn = this.closestUrn
        } else {this.currentUrn = result.urn;}
        this.token = result.accessToken;
        this.options = {
          env: 'AutodeskProduction2',
          api: 'streamingV2',  
          getAccessToken: function(onTokenReady: any) {
            var token = result.accessToken
            var timeInSeconds = result.expiresIn;
            onTokenReady(token, timeInSeconds);
          }
        };
        Autodesk.Viewing.Initializer(this.options, this.loadViewer.bind(this));
      })
      

  }
  async loadViewer(){
    const routeParams = this.route.snapshot.paramMap
    this.taskIdFromRoute = String(routeParams.get('taskId'));
    if(this.taskIdFromRoute=='home') {
      this.colorClear=true
    }
    var htmlDiv = document.getElementById('forgeViewer');
    this.viewer = new Autodesk.Viewing.GuiViewer3D(htmlDiv);
    var startedCode = await this.viewer.start();
    if (startedCode > 0) {
        console.error('Failed to create a Viewer: WebGL not supported.');
        return;
    }
    console.log('Initialization complete, loading a model next...');
    var documentId = this.currentUrn
    Autodesk.Viewing.Document.load(documentId, this.onDocumentLoadSuccess.bind(this), this.onDocumentLoadFailure);
    const dataVizExtn = await this.viewer.loadExtension("Autodesk.DataVisualization");
    this.dvExt = dataVizExtn
    const DataVizCore = Autodesk.DataVisualization.Core;
    this.dtv = DataVizCore
    const viewableType = DataVizCore.ViewableType.SPRITE;
    const spriteColor = new THREE.Color(0xffffff);
    const baseURL = "https://shrikedoc.github.io/data-visualization-doc/_static/";
    const spriteIconUrl = `${baseURL}fan-00.svg`;
    const spriteIcon = 'assets/images/warning.png'
    const spriteIcon2 = 'assets/images/dev1.jpg'
    const style = new DataVizCore.ViewableStyle(
        viewableType,
        spriteColor,
        spriteIcon
    );
    const style1 = new DataVizCore.ViewableStyle(
      viewableType,
      spriteColor,
      spriteIconUrl
    );
    
    const style2 = new DataVizCore.ViewableStyle(
                    viewableType,
                    spriteColor,
                    spriteIcon2
                  );
    this.style = style2
    const viewableData = new DataVizCore.ViewableData();
    this.viewableData=viewableData
    viewableData.spriteSize = 30; // Sprites as points of size 24 x 24 pixels
    const showViewables = true;
    

    this.viewer.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT,()=> {
      this.modelLoaded.emit(true)
      this.SetNavigation(this.panoPos.x1,this.panoPos.x2,this.panoPos.y1,this.panoPos.y2,this.panoPos.z1,this.panoPos.z2);
      this.rejectYaw=false
    })
    this.viewer.navigation.setIsLocked(true)
    if(this.colorClear==false){
      this.bimService.getBimDetails(this.projectId,this.selectedTask, '2014-11-14').subscribe(
        async result => {
          if(!jQuery.isEmptyObject(result)) {
            this.viewer.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, ()=> {
              this.viewer.hideAll()

//BIM ELEMENT LEVEL NODE COLORING
              result.forEach((id: any) => {
            //this.viewer.isolate([id.dbId], this.viewer.impl.model)
                if(id.progress!=null){
                  this.viewer.show([id.dbId], this.viewer.impl.model)
                  if(id.progress>80 ){
                    this.viewer.setThemingColor([id.dbId], new THREE.Vector4(.2980, .6863, .3137))
                  } else if(id.progress<=80 && id.progress>60){
                    this.viewer.setThemingColor([id.dbId], new THREE.Vector4(.4784, .7529, .3059))
                  } else if(id.progress<=60 && id.progress>40){
                    this.viewer.setThemingColor([id.dbId], new THREE.Vector4(.6510, .8196, .3020))
                  } else if(id.progress<=40 && id.progress>20){
                    this.viewer.setThemingColor([id.dbId], new THREE.Vector4(.8235, .8784, .3137))
                  } else if(id.progress<=20 && id.progress>=0) {
                    this.viewer.setThemingColor([id.dbId], new THREE.Vector4(1, .9333, .3451))
                  } 
                }
                this.allDbId.push(id.dbId)
              })
          //this.viewer.hideAll()
            }) 
          }

//TASK LEVEL NODE COLORING
        // this.taskService.getTaskProgress(this.projectId,this.selectedTask).subscribe(
        //   taskResult=> {
        //     this.viewer.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, ()=> {
          //this.viewer.hideAll()
        //       if(taskResult[0].started==0) {
        //         result.forEach((id: any) => {
        //           this.viewer.show([id.dbId], this.viewer.impl.model)
        //           this.viewer.setThemingColor([id.dbId], new THREE.Vector4(0.392, 0.7098, 0.9647, 1)) })
        //       } else if(taskResult[0].started==1 && taskResult[0].progress<=0.5){
        //         result.forEach((id: any) => {
        //           this.viewer.show([id.dbId], this.viewer.impl.model)
        //           this.viewer.setThemingColor([id.dbId], new THREE.Vector4(1, 0.898, 0.498, 1)) })
        //       } else  {
        //         result.forEach((id: any) => {
        //           this.viewer.show([id.dbId], this.viewer.impl.model)
        //           this.viewer.setThemingColor([id.dbId], new THREE.Vector4(0.7725, 0.88235, 0.647, 1)) })
        //       }
        //     })  
        //   }
        // )

//SPRITE START
        // this.deviatedList = []
        // for(var i=0;i<result.length;i++){
        //   if(result[i].deviated==1){
        //     var offsetCoord = this.area.urnOffset.split(', ')
            
        //     var data = {
        //       dbId: result[i].dbId,
        //       position: {
        //         x:result[i].transX-offsetCoord[0],
        //         y:result[i].transY-offsetCoord[1],
        //         z:result[i].transZ-offsetCoord[2]
        //       }
        //     }  
        //     this.deviatedList.push(data)
        //   } 
        // }
        // this.deviatedList.forEach((myData: any, index: any) => {
        //   const dbId = 10 + index;
        //   const position = myData.position;
        //   const viewable = new DataVizCore.SpriteViewable(position, style, dbId);
        //   viewableData.addViewable(viewable);
        // });
        var offsetCoord = this.area.urnOffset.split(', ')
        if(parseFloat(this.taskIdFromRoute)==1734) {
          const myDataList = [
            { dbId:486802, position: { x:527.925-offsetCoord[0],y:458.346-offsetCoord[1],z:36.9915-offsetCoord[2] } },
            { dbId:486486, position: { x: 527.925-offsetCoord[0] , y: 473.061-offsetCoord[1], z: 36.9094-offsetCoord[2] } },
            { dbId:8412, position: { x: 534.503-offsetCoord[0] , y: 417.245-offsetCoord[1], z: 36.9915-offsetCoord[2] } }];
          myDataList.forEach((myData: any, index: any) => {
            const dbId = 10 + index;
            const position = myData.position;
            const viewable = new DataVizCore.SpriteViewable(position, style, dbId);

            viewableData.addViewable(viewable);
          });
           await viewableData.finish();
           dataVizExtn.addViewables(viewableData);
           
        }
        
//SPRITE END
//ON SPRITE HOVER 
        //this.viewer.addEventListener(DataVizCore.MOUSE_HOVERING, this.onSpriteHovering);

//ON SPRITE CLICKED 
        // this.viewer.addEventListener(DataVizCore.MOUSE_CLICK, async (e:any) => {
        //  // await this.viewer.select(this.deviatedList[e.dbId-10].dbId,this.viewer.impl.model,Autodesk.Viewing.SelectionType.OVERLAYED)
        //   //this.viewer.utilities.fitToView();
        //   //this.viewer.impl.model.getProperties(this.deviatedList[e.dbId-10].dbId, (i: any)=>console.log(i))
        //   this.viewer.impl.model.getProperties(e.dbId, (i: any)=>console.log(i))
        //   //Autodesk.Viewing.Model.getProperties()
        // });


       // this.viewer.impl.model.hideAll()
        //this.viewer.impl.model.setLayerVisible(null,false,true)
        this.viewer.addEventListener(DataVizCore.MOUSE_CLICK, this.onSelectionChanged.bind(this));

        })
      }
    // this.viewer.isolate(this.dbIdArr);
    // this.viewer.fitToView(this.dbIdArr);
    // this.viewer.addEventListener(
    //   Autodesk.Viewing.SELECTION_CHANGED_EVENT,this.selectDbIds.bind(this)           
    // )
    //this.viewer.clearThemingColors();
    //const currentSelection = this.viewer.getSelection();
// );
//DEVIATION ONSELECT
    //this.viewer.addEventListener(Autodesk.Viewing.SELECTION_CHANGED_EVENT, this.onSelectionChanged.bind(this))
  }

  // async onSpriteHovering(event: any) {
  //   const targetDbId = event.dbId;
  //   if (event.hovering) {
  //     //this.viewer.select(this.deviatedList[targetDbId-10].dbId);
  //     //this.viewer.select([34044]);
  //     await this.viewer.select(46610,this.viewer.impl.model,Autodesk.Viewing.SelectionType.OVERLAYED)
  //     console.log(`The mouse hovers over ${targetDbId}`);
  //   } else {
  //       console.log(`The mouse hovers off ${targetDbId}`);
  //   }
  // }
  // onSpriteClicked(event: any) {
 
  //   this.viewer.select(this.deviatedList[event.dbId-10].dbId);
  //   //this.viewer.utilities.fitToView();
  //  console.log(`Sprite clicked: ${event}`);
  // }
  // async onModelLoaded() {
  //   const dataVizExtn = await this.viewer.loadExtension("Autodesk.DataVisualization");

  //   const DataVizCore = Autodesk.DataVisualization.Core;
  //   const viewableType = DataVizCore.ViewableType.SPRITE;
  //   const spriteColor = new THREE.Color(0xffffff);
  //   const baseURL = "https://shrikedoc.github.io/data-visualization-doc/_static/";
  //   const spriteIconUrl = `${baseURL}fan-00.svg`;

  //   const style = new DataVizCore.ViewableStyle(
  //       viewableType,
  //       spriteColor,
  //       spriteIconUrl
  //   );

  //   const viewableData = new DataVizCore.ViewableData();
  //   viewableData.spriteSize = 24; // Sprites as points of size 24 x 24 pixels

  //   const myDataList = [
  //       { position: { x: 0.325579, y: -29.948, z: 50.114 } },
  //       { position: { x: 20, y: 22, z: 3 } },
  //   ];

  //   myDataList.forEach((myData, index) => {
  //       const dbId = 10 + index;
  //       const position = myData.position;
  //       const viewable = new DataVizCore.SpriteViewable(position, style, dbId);

  //       viewableData.addViewable(viewable);
  //   });
  //   await viewableData.finish();
  //   dataVizExtn.addViewables(viewableData);
  // }

  onSelectionChanged(dbIds: any){
   this.devShown=!this.devShown
   if(dbIds.dbId == 10) {
    var deviation = {bimId:5674011, text: "Actual deviation of 20 cm in Y. Algorithm detected 17cm in Y.", img:"5674011.PNG"}
    this.devPopup.emit({show:this.devShown, deviation})
   } else if (dbIds.dbId==11) {
    var deviation = {bimId:5673820, text: "Actual deviation of 20 cm in Y. Algorithm detected 25cm in Y.", img:"5673820.PNG"}
    this.devPopup.emit({show:this.devShown, deviation})
   } else if (dbIds.dbId==12) {
    var deviation = {bimId:3202805, text: "Actual deviation of 18 cm in Y, Algorithm detected 17cm in Y.", img:"3202805.PNG"}
    this.devPopup.emit({show:this.devShown, deviation})
   } 
   
   
   
   //this.viewer.utilities.fitToView();
    //this.viewer.impl.model.getProperties(dbIds.dbIdArray[0], (i: any)=>console.log(i))
    // this.viewer.impl.model.getProperties(dbIds.dbIdArray[0], (i: any)=>{
    //  // console.log(i) 
    //   i.properties.forEach((prop: any) => {
    //     // any custom action with data?
    //     prop.displayName 
    //     prop.displayValue
    //     prop.displayCategory
    //     // etc
    //   });
    // })
    // this.devShown = !this.devShown
    // var offsetCoord = this.area.urnOffset.split(', ')
    // if(this.devShown==true) {
    //   var details1 = { dbId:486802, position: { x:538.925-offsetCoord[0],y:459.346-offsetCoord[1],z:36.9915-offsetCoord[2] } }
    //   const position1 = details1.position;
    //   const dbId1 = 15
    //   const viewable1 = new this.dtv.SpriteViewable(position1, this.style, dbId1);
    //   this.viewableData.spriteSize=100
    //   this.viewableData.addViewable(viewable1);
    //}

  }

  onDocumentLoadSuccess(viewerDocument: any) {
    var defaultModel = viewerDocument.getRoot().getDefaultGeometry();
    this.viewer.start()
	  this.viewer.loadDocumentNode(viewerDocument, defaultModel);
  }
  onDocumentLoadFailure() {
    console.error('Failed fetching Forge manifest');
  }
//POSITION CHANGE FUNCTION (TRIGGERED FROM OTHER COMPONENTS)
  SetNavigation(x1: any,x2: any,y1: any,y2: any,z1: any,z2: any) {
    if (x1 != undefined && x2 != undefined && y1 != undefined && y2 != undefined && z1 != undefined && z2 != undefined) {
      this.viewer.navigation.setView({ x: x1, y: y1, z: z1 }, { x: x2, y: y2, z: z2 });
      this.viewer.navigation.toPerspective();
      this.viewer.navigation.setVerticalFov(75, true);
      this.viewer.getCamera().perspectiveCamera.zoom=0.5;
      (<HTMLInputElement>document.getElementById("toolbar-bimWalkTool")).click();
    }
  };

//SPRITE ICONS OCCLUSION TOGGLE
  // showConflicts() {
  //   this.showDev = !this.showDev
  //   this.enableOcclusion = !this.enableOcclusion;
  //   this.dvExt.showHideViewables(true, this.enableOcclusion);
  // }
  showModel() {
    this.showMod = !this.showMod
    if(this.showMod==true) {
      this.viewer.showAll()
    } else {
    this.viewer.hideAll()
    this.allDbId.forEach(async (id: any) => {
      await this.viewer.show([id], this.viewer.impl.model)
    })
    }
    
  }

//POSITION CHANGE TRIGGERED FROM OTHER COMPONENTS
  ngOnChanges(changes: SimpleChanges){
    if(changes['panoPos']!==undefined && this.panoPos) {
     if(!changes["panoPos"].isFirstChange()){
      let newValue = changes['panoPos'].currentValue;
      this.viewer.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, ()=> {
        this.bimLoaded=true
        this.SetNavigation(newValue.x1,newValue.x2,newValue.y1,newValue.y2,newValue.z1,newValue.z2);
        (<HTMLInputElement>document.getElementById("toolbar-bimWalkTool")).click();
        this.viewer.navigation.setIsLocked(false)
        this.viewer.navigation.setIsLocked(true)
      })
      if(this.bimLoaded=true) {
        this.SetNavigation(newValue.x1,newValue.x2,newValue.y1,newValue.y2,newValue.z1,newValue.z2);
        (<HTMLInputElement>document.getElementById("toolbar-bimWalkTool")).click();
        this.viewer.navigation.setIsLocked(false)
        this.viewer.navigation.setIsLocked(true)
      }
    }
    }
    // if(changes['closestUrn']!==undefined && this.closestUrn){
    //   let newUrn = changes['closestUrn'].currentValue;
    //   this.currentUrn=newUrn
    //   Autodesk.Viewing.Initializer(this.options, this.loadViewer.bind(this))
    // } else 
    if(changes['areaUrn']!==undefined && this.areaUrn){
      if(!changes["areaUrn"].isFirstChange()){
        let newUrn = changes['areaUrn'].currentValue;
        if(this.currentUrn!==newUrn){
          this.rejectYaw=true
          this.currentUrn=newUrn
          this.colorClear = true
          // this.viewer.finish();
          // this.viewer = null;
          // Autodesk.Viewing.shutdown();
          Autodesk.Viewing.Initializer(this.options, this.loadViewer.bind(this))
        // this.viewer.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, ()=> {
        //   this.viewer.clearThemingColors(this.viewer.impl.model)
        //   this.viewer.showAll()
        //})

        }
      }
    }
    if(changes['panoYaw']!==undefined && this.panoYaw){
      let newYaw = changes['panoYaw'].currentValue;
      var xtarget = this.panoPos.x1+(5*Math.cos(newYaw.yaw* Math.PI / 180))
      var ytarget = this.panoPos.y1+(5*Math.sin(newYaw.yaw* Math.PI / 180))
      var xMax = 90;
      var xMin = 0;
      var pitchDiff = 15
      var pitchOrigin = Math.round(this.panoPos.z1)

      var ztarget = pitchOrigin + (newYaw.pitch/(xMax/pitchDiff))
        if(this.rejectYaw==false) {
          this.SetNavigation(this.panoPos.x1, xtarget, this.panoPos.y1, ytarget, this.panoPos.z1, ztarget );
          (<HTMLInputElement>document.getElementById("toolbar-bimWalkTool")).click();
          this.viewer.navigation.setIsLocked(false)
          this.viewer.navigation.setIsLocked(true)
        }

    }
    if(changes['hfovS']!==undefined && this.hfovS){
      let zoom = changes['hfovS'].currentValue;
    }
    if(changes['showDeviation']!==undefined){
      let newValue = changes['showDeviation'].currentValue;
      this.devShown=newValue
    }
    
  }

}

<nav class="navbar navbar-expand-lg pb-1  navbar-light bg-light pt-0 mb-4 shadow fixed-top" style="height: 3% !important;">
    <div class="container-fluid">
      <a class="navbar-brand" href="#">
        <img src="../../assets/images/logo.png" alt="" width="30" height="30" class="d-inline-block align-text-top">
        <a style="color: #4E91DA; font-weight: 650;">    Zontro</a>    
        <a class="navbar-brand" href="#" *ngIf="project!==undefined" style="color: rgb(71, 71, 71); font-size: smaller; padding-left: 20%;">
            Project: {{project.projectName}} ({{project.orgName}}) 
        </a>   
      </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
        <ul class="navbar-nav justify-content-center" style="padding-left: 50%!important;" >
          <ul ngbNav #nav="ngbNav" [(activeId)]="active" [destroyOnHide]="false" class="nav-tabs">
            <li [ngbNavItem]="1" [destroyOnHide]="false">
              <a ngbNavLink> &nbsp;&nbsp; Dashboard &nbsp;&nbsp; </a>
              <ng-template ngbNavContent>
                <app-dashboard
                  (selectedTask) = passIdToSV($event)>
                </app-dashboard>
              </ng-template>
            </li>
            <li [ngbNavItem]="2">
              <a ngbNavLink> &nbsp;&nbsp; Site View &nbsp;&nbsp; </a>
              <ng-template ngbNavContent>
                <app-site-view
                *ngIf = "showSV"
                [dbTask] = "selectedTask"
                (activeTab) = changeTab($event)></app-site-view>
                <app-bim-preview *ngIf = "!showSV">

                </app-bim-preview>
              </ng-template>
            </li>
          </ul>
        </ul>
      </div>
      <div>
        <!-- <button type="button" class="btn btn-primary btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
          {{userInitials | uppercase }}
        </button>
        <ul class="dropdown-menu">
          <li><a class="dropdown-item" (click)="signout()">Signout</a></li>
        </ul> -->
        <div ngbDropdown class="d-inline-block">
          <button type="button" class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>{{userInitials | uppercase }}</button>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
            <button ngbDropdownItem (click)="signout()">Signout</button>
          </div>
        </div>
      </div>
  </div>
</nav>
<div class="container-fluid">
  <div class="row">
    <div class="col-sm-2 mt-1 pt-5 ml-2">
      <div class="d-grid gap-2 d-md-flex ml-4">
          <button class="nav-item nav-link-edit w-10 btn btn-primary mr-3" [routerLink]="['../../../overview']">Back to Project List</button >
      </div>
      
      <!-- <button class="w-20 btn btn-primary mr-3 ">Create Project</button> -->
    </div>
    <div class="col mt-1 pt-5 ml-0 d-md-flex justify-content-md-end mr-0">
      <table *ngIf = "showLegend && active==2" class="table text-center mr-0" style="width: 700px;" >
        <tbody style="font-size: 12px;">
            <tr >
              <td style="background-color: #bbdefb;">Deviated</td>
              <td ></td>
              <td style="background-color: #ffee58;">0-10%</td>
              <td style="background-color: #ebe854;">11-20%</td>
              <td style="background-color: #d7e251;">21-30%</td>
              <td style="background-color: #c3db4f;">31-40%</td>
              <td style="background-color: #afd44d;">41-50%</td>
              <td style="background-color: #9ccd4d;">51-60%</td>
              <td style="background-color: #89c64d;">61-70%</td>
              <td style="background-color: #75be4e;">71-80%</td>
              <td style="background-color: #61b74f;">81-90%</td>
              <td style="background-color: #61b74f;">91-100%</td>
            </tr>
        </tbody>
    </table>
    </div>
    <div class="col-sm-1 mt-1 pt-5 ml-0">
      <div *ngIf= "active==2" class="d-grid gap-2 d-md-flex justify-content-md-end ml-0">
        <button class="btn btn-1 btn-primary" type="button" (click) = "toggleLegend()">&nbsp;&nbsp;</button>
      </div>
    </div>
  </div>
</div>

  <div class="container-fluid">
    <div class="row">
      <div [ngbNavOutlet]="nav" ></div>
    </div>
  </div>
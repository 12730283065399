<div class="container-fluid">
    <div class="row" >
        <div class="col " >
            <div class="card shadow p-1 mb-1 bg-body rounded" style="height: max-content;" >
              <div class="card-body p-0 m-2 " x>

                <div class="row task-row"  *ngFor="let item of delayArr " (click) = "onSelected(item)" >
                    <ng-template #HtmlContent>
                        <app-delay-tooltip
                        [tooltipData] = "item"
                        [parentTree] = "parent"
                        ></app-delay-tooltip>
                    </ng-template>
                    <div *ngIf="item.maxLevel && item.actualP>0 && item.actualP<100" 
                        
                        [tooltip]="$any(HtmlContent)"
                        theme='light'
                        tooltipClass='tooltp'
                        contentType="template"
                        placement="bottom" 
                        class="col-sm-3" 
                        style="max-width: 180px !important;"
                        (mouseenter)="getParent(item.id)">
                        <p class="overflow-hidden text-truncate text-nowrap"  style="font-size: 12px;">{{item.text}}</p>
                    </div>
                    <div *ngIf="item.maxLevel && item.actualP>0 && item.actualP<100" 
                        [tooltip]="$any(HtmlContent)"
                        theme='light'
                        tooltipClass='tooltp'
                        contentType="template"
                        placement="bottom" 
                        class="col-7" 
                         >
                        <div [ngStyle]="{ 'width' : item.plannedP+'%' }" class="progress p-0 m-1" style="height: 7px;background-color: #DBEDFF;">
                            <div  class="progress-bar" role="progressbar" style=" width:100%; background-color:#ADD2FB;" aria-valuenow="20" aria-valuemin="0" aria-valuemax="1"></div>
                        </div>
                        <div [ngStyle]="{ 'width' : item.actualP+'%' }" class="progress p-0 m-1" style="height: 7px;background-color:#ffdeb0;">
                            <div  class="progress-bar" role="progressbar" style=" width:100%;  background-color:#FAAB3F;" aria-valuenow="20" aria-valuemin="0" aria-valuemax="1"></div>
                        </div>
                    </div>
                    <div *ngIf="item.maxLevel && item.actualP>0 && item.actualP<100" class="col-sm-1" style="width: max-content !important;">
                        <p style=" font-size: 11px;">+{{item.delay}} days</p>
                    </div>
                </div>  
        <!-- <ngx-charts-bar-horizontal-2d
            [view]="view"
            [scheme]="colorScheme"
            [results]="multi1"
            [xAxis]="showXAxis"
            [yAxis]="showYAxis"
            [showXAxisLabel]="false"
            [showYAxisLabel]="false"
            [dataLabelFormatting] = "formatDataLabel"
            [showDataLabel]="true"
            (select)="onSelect($event)"
            (activate)="onActivate($event)"
            (deactivate)="onDeactivate($event)"
            >
        </ngx-charts-bar-horizontal-2d> -->
        </div>
        </div>
        </div>
    </div>
</div>
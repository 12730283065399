/**
 * Zontro UI APIs
 * Zontro APIs for UI and backend
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ApiResponse } from '../model/apiResponse';
import { PanoAll } from '../model/panoAll';
import { PanoArea } from '../model/panoArea';
import { ClosestPano } from '../model/closestPano';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class PanoService {

    protected basePath = 'http://zontro-webui.mvizn.com:3200/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Add new Pano deatils
     * 
     * @param body added Pano object
     * @param projectId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addnewPano(body: PanoAll, projectId: string, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public addnewPano(body: PanoAll, projectId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public addnewPano(body: PanoAll, projectId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public addnewPano(body: PanoAll, projectId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addnewPano.');
        }

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling addnewPano.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ApiResponse>('post',`${this.basePath}/pano/addnew/${encodeURIComponent(String(projectId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns all pano detailed for the Project
     * 
     * @param projectId project id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPanoList(projectId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<PanoAll>>;
    public getPanoList(projectId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PanoAll>>>;
    public getPanoList(projectId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PanoAll>>>;
    public getPanoList(projectId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getPanoList.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<PanoAll>>('get',`${this.basePath}/pano/get/${encodeURIComponent(String(projectId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns all panos for each area by date
     * 
     * @param projectId project id
     * @param areaId area ID
     * @param date date to fetch the notes for
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPanobyArea(projectId: string, areaId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<PanoArea>>;
    public getPanobyArea(projectId: string, areaId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PanoArea>>>;
    public getPanobyArea(projectId: string, areaId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PanoArea>>>;
    public getPanobyArea(projectId: string, areaId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getPanobyArea.');
        }

        if (areaId === null || areaId === undefined) {
            throw new Error('Required parameter areaId was null or undefined when calling getPanobyArea.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<PanoArea>>('get',`${this.basePath}/pano/getbyarea/${encodeURIComponent(String(projectId))}/${encodeURIComponent(String(areaId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns all pano for task by date
     * 
     * @param projectId project id to fetch the pano images
     * @param taskId task ID
     * @param date date to fetch the notes for old data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPanobyTask(projectId: string, taskId: number, date: string, observe?: 'body', reportProgress?: boolean): Observable<Array<PanoArea>>;
    public getPanobyTask(projectId: string, taskId: number, date: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PanoArea>>>;
    public getPanobyTask(projectId: string, taskId: number, date: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PanoArea>>>;
    public getPanobyTask(projectId: string, taskId: number, date: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getPanobyTask.');
        }

        if (taskId === null || taskId === undefined) {
            throw new Error('Required parameter taskId was null or undefined when calling getPanobyTask.');
        }

        if (date === null || date === undefined) {
            throw new Error('Required parameter date was null or undefined when calling getPanobyTask.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<PanoArea>>('get',`${this.basePath}/pano/getbytask/${encodeURIComponent(String(projectId))}/${encodeURIComponent(String(date))}/${encodeURIComponent(String(taskId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
        /**
     * Returns all pano for task by date
     * 
     * @param projectId project id to fetch the pano images
     * @param taskId task ID
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
         public getClosestPano(projectId: string, taskId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<ClosestPano>>;
         public getClosestPano(projectId: string, taskId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ClosestPano>>>;
         public getClosestPano(projectId: string, taskId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ClosestPano>>>;
         public getClosestPano(projectId: string, taskId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
     
             if (projectId === null || projectId === undefined) {
                 throw new Error('Required parameter projectId was null or undefined when calling getPanobyTask.');
             }
     
             if (taskId === null || taskId === undefined) {
                 throw new Error('Required parameter taskId was null or undefined when calling getPanobyTask.');
             }
         
             let headers = this.defaultHeaders;
     
             // to determine the Accept header
             let httpHeaderAccepts: string[] = [
                 'application/json'
             ];
             const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
             if (httpHeaderAcceptSelected != undefined) {
                 headers = headers.set('Accept', httpHeaderAcceptSelected);
             }
     
             // to determine the Content-Type header
             const consumes: string[] = [
             ];
     
             return this.httpClient.request<Array<PanoArea>>('get',`${this.basePath}/pano/getclosest/${encodeURIComponent(String(projectId))}/${encodeURIComponent(String(taskId))}`,
                 {
                     withCredentials: this.configuration.withCredentials,
                     headers: headers,
                     observe: observe,
                     reportProgress: reportProgress
                 }
             );
         }

}

import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { PanoService, TaskService } from 'zontro-openapi-frontend';

@Component({
  selector: 'app-site-view',
  templateUrl: './site-view.component.html',
  styleUrls: ['./site-view.component.css']
})
export class SiteViewComponent implements OnInit {
  @Input() dbTask: any
  @Output() activeTab = new EventEmitter<any>()

  scan_name: any;
  areaId: any;
  forgePos: any;
  viewS: any;
  taskId: any = 0
  projectId: any = 'Aerospace_REV3'
  closestPano: any;
  large: boolean = false;
  showLoader: boolean = true;
  
  panoLocs: any;
  setLock: number = 1;
  scanFromPano: any;
  oldTaskId: any;
  showDeviation: any;
  deviationDetails: any;
  mapPos: any;
  panoScene: any;
  taskDetail: any;
  constructor(private changeDetectorRef: ChangeDetectorRef, 
              private panoService: PanoService,
              private taskService: TaskService) { }

  ngOnInit() {

    setTimeout(() => {this.doResize()}, 2000)
  }
  ngOnChanges(changes: SimpleChanges) { 
    if(changes['dbTask']!==undefined && this.dbTask){
      let newValue = changes['dbTask'].currentValue;
      this.dbTask=newValue
      this.taskId = newValue.id
      this.panoService.getClosestPano(this.projectId, this.taskId).subscribe(
        result => {
          // this.taskService.getTaskById(this.projectId, this.taskId).subscribe(
          //   result1 => {
          if(!jQuery.isEmptyObject(result)) {
            if(newValue.dev==true){
              this.scan_name = result
              if(newValue.id==1734) {
                this.scan_name.panoId = "R0011929.JPG"
              } else if(newValue.id==1741) {
                this.scan_name.panoId = "R0011939.JPG"
              } else if(newValue.id==1952) {
                this.scan_name = {
                  areaId: "unit3floor1",
                  bounds: "322.82, 479.79, 516.90, 666.55",
                  floorPlan: "bim_u3f1.jpg",
                  offsetYaw: 90,
                  panoId: "R0011923.JPG",
                  scanDate: "2022-03-03T08:47:48.000Z",
                  scanName: "20220303-14_17_48",
                  scanPanoPath: "Z:\\Zontro\\BackendFromD\\AS3_Algorithms\\m20220303\\munit3\\mfloor1\\mscan\\pano",
                  urn: "urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6bXZpem5fYnVja2V0X2FzM191bml0M3dob2xlX2xpbmsvQVMzX1VuaXQzX1dob2xlLnppcA",
                  urnOffset: "287.01859167688576, 621.3871459520716, -15.010679910375941"
                }
              } else if(newValue.id==1999) {
                this.scan_name = {
                  areaId: "unit3floor2",
                  bounds: "322.82, 479.79, 476.02, 666.55",
                  floorPlan: "bim_u3f2.jpg",
                  offsetYaw: -90,
                  panoId: "R0011936.JPG",
                  scanDate: "2022-03-03T09:07:36.000Z",
                  scanName: "20220303-14_37_36",
                  scanPanoPath: "Z:\\Zontro\\BackendFromD\\AS3_Algorithms\\m20220303\\munit3\\mfloor2\\mscan\\pano",
                  urn: "urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6bXZpem5fYnVja2V0X2FzM191bml0M3dob2xlX2xpbmsvQVMzX1VuaXQzX1dob2xlLnppcA",
                  urnOffset: "287.01859167688576, 621.3871459520716, -15.010679910375941"
                }
              } else if(newValue.id==2019) {
                this.scan_name = {
                  areaId: "unit3floor2",
                  bounds: "322.82, 479.79, 476.02, 666.55",
                  floorPlan: "bim_u3f2.jpg",
                  offsetYaw: -90,
                  panoId: "R0011936.JPG",
                  scanDate: "2022-03-03T09:07:36.000Z",
                  scanName: "20220303-14_37_36",
                  scanPanoPath: "Z:\\Zontro\\BackendFromD\\AS3_Algorithms\\m20220303\\munit3\\mfloor2\\mscan\\pano",
                  urn: "urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6bXZpem5fYnVja2V0X2FzM191bml0M3dob2xlX2xpbmsvQVMzX1VuaXQzX1dob2xlLnppcA",
                  urnOffset: "287.01859167688576, 621.3871459520716, -15.010679910375941"
                }              } 
            } else {
              this.scan_name = result
		if(newValue.id==1734) {
                  this.scan_name.panoId = undefined
              	}
            }
            
          }
        }
      )
      var bodyArr = []
      var body = {taskId:parseFloat(this.taskId)}
      bodyArr.push(body)
      this.taskService.getTaskDetails(this.projectId,bodyArr).subscribe(
        result1 => {
          this.taskDetail = result1[0]
        }
      )
              
    } 
  }
  passScan(data:any) {
    this.scan_name = data 
    this.changeDetectorRef.detectChanges();
    this.oldTaskId = this.taskId
  }

  passForgePos(ev: any){
    this.forgePos = {
      x1:ev.xPos,
      x2:ev.xTarget,
      y1:ev.yPos,
      y2:ev.yTarget,      
      z1:ev.zPos,
      z2:ev.zTarget,
    }
  }
  onViewChange(data: any) {
    this.viewS = data;
    //this.changeDetectorRef.detectChanges();
  }
  doResize(){
    if(this.large) {
      this.large = !this.large;
    }  else {
      this.large = !this.large;
    }
    if(this.large==false) {
      this.showLoader=true
    }
    this.changeDetectorRef.detectChanges();
  }
  removeLoader(ev: any) {
    if(ev == false){
      this.showLoader = true
    } else {
      this.showLoader = false
      this.activeTab.emit(1)
    }
  }
  passMapLocs(data: any) {
    this.panoLocs=data
    this.changeDetectorRef.detectChanges();
  }
  passMapScene(event: any) {
    this.panoScene = event
  }
  posFromMap(ev: any){
    this.mapPos = ev
  }
  viewLocked() {
    if(this.setLock==2){
      this.setLock=1
    } else this.setLock=2
    this.changeDetectorRef.detectChanges();
  } 
  passScanFromPano(ev: any) {
   this.scanFromPano = ev
  }
  showDevPopup(ev: any) {
    this.showDeviation = ev.show
    this.deviationDetails = ev.deviation
  }
  closeDeviation() {
    this.showDeviation = false
    this.changeDetectorRef.detectChanges();
  }

}

<!-- <nav class="navbar navbar-expand-lg pb-4  navbar-light bg-light pt-4 mb-4 shadow" style="height: 3% !important;">
  <div class="container-fluid">
    <a class="navbar-brand" href="#">
      <img src="../../assets/images/logo.png" alt="" width="30" height="30" class="d-inline-block align-text-top">
      <a style="color: #4E91DA; font-weight: 650;">    Zontro</a>    
      <a class="navbar-brand" href="#" style="color: rgb(71, 71, 71); font-size: smaller; padding-left: 20%;">
          Project: JTC Aerospace 
      </a>   
    </a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
      <ul class="navbar-nav justify-content-center" style="padding-left: 50%!important;" >
          <li class="nav-item" >
            <a id="siteV" class="nav-link" href="site-viewer/392" target='_blank'>Site View</a>
            
          </li>
          <li class="nav-item">
            <a class="nav-link active"  aria-current="page"  href="dashboard"  >DashBoard</a>
          </li>
        </ul>
    </div>
  </div>
</nav> -->
<div style="padding-top: .5% !important;">
<app-overall-cards
    (projectTaskId) = passProjectTaskId($event)
    [overallDelay] = "overallDelay">
</app-overall-cards>
</div>
<div class="container-fluid mt-2 ">
    <div class="row m-2 "> 
        <div class="col shadow bg-body rounded" >
          <div class="card  " style="height: 600px; overflow-x: hidden !important;" >
            <div class="card-body p-0 m-1">
              <h6 style="padding-left: 3%;">Overall Progress</h6> 
              <!-- <hr class="style14"> -->
              <app-parent-tree
                [projectTaskId] = "projectTaskId"
                (idForGantt) = passIdForGantt($event)>
              </app-parent-tree>
            </div>
          </div>
            
        </div>
        <div class="col shadow bg-body rounded">
          <div class="shadow" style="height: 600px; overflow-x: hidden !important; overflow-y: hidden !important;" >
            <div class="card-body p-0 m-1">
              <h6 style="padding-left: 3%; padding-top: 5px;">Delayed Tasks</h6> 
              <!-- <hr class="style14"> -->
              <app-delay-sunburst
                (idSelect) = passIdSelect($event)>
              </app-delay-sunburst>


              <!-- <app-sunburst></app-sunburst> -->
              <!-- <app-delay-chart
                [delayArr] = "delayArr"
                (idForGantt) = passIdForGantt($event)>
              </app-delay-chart> -->
            </div>
          </div>
        </div>
    </div>
</div>
<div class="container-fluid mt-2">
  <div class="row m-2" >
    <div class="card shadow p-0 mb-1 bg-body rounded">
      <div class="card-body p-0 m-1">
        <div class="row">
          <div class="col">
            <h6 style="padding: .5% 0 0% 2%;">Project Schedule</h6> 
            <!-- <hr class="style14"> -->
          </div>
          <div class="col" style="float: right;">
            <!-- <div class="form-floating" style="float: right;">
              <div class="btn-group" role="group" aria-label="First group">
                <button class=" btn btn-sm btn-outline-primary primary-color " >
                  <i class="far fa-clock"></i>   Version, Present</button>
                <button class=" btn btn-sm btn-outline-primary primary-color" >
                  <i class="fas fa-file-download"></i>   Download Report
                </button>
              </div>
            </div> -->
          </div>
        </div>
        <div class="row">
        <app-gantt-schedule
          [idForGantt] = "idForGantt"
          [idForGantt] = "idFromSB"
          (delayAr) = passDelay($event)
          (selectedTask) = passIdToSV($event)>
        </app-gantt-schedule>
        </div>
      </div>
    </div>
  </div> 
</div>
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-delay-chart',
  templateUrl: './delay-chart.component.html',
  styleUrls: ['./delay-chart.component.css']
})
export class DelayChartComponent implements OnInit {
  @Input() delayArr: any
  @Output() idForGantt = new EventEmitter<any>()
  parent: any = []
  constructor(private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit() {
  }
  ngOnChanges(changes: SimpleChanges) {
    if(changes['delayArr']!==undefined && this.delayArr){
      let data = changes['delayArr'].currentValue;
    }
  }
  onSelected(task: any) {
    this.idForGantt.emit(task.id)
    this.changeDetectorRef.detectChanges();
  }
  getParent(id: any) {
    this.parent = []
    for(var i=0;i<this.delayArr.length;i++){
      if(this.delayArr[i].id==id){
        this.parent.push(this.delayArr[i].text)
        for(var j=0;j<this.delayArr.length;j++){
          if(this.delayArr[j].id==this.delayArr[i].parent){
            this.parent.push(this.delayArr[j].text)
            for(var k=0;k<this.delayArr.length;k++){
              if(this.delayArr[k].id==this.delayArr[j].parent){
                this.parent.push(this.delayArr[k].text)
                for(var l=0;l<this.delayArr.length;l++){
                  if(this.delayArr[l].id==this.delayArr[k].parent){
                    this.parent.push(this.delayArr[l].text)
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

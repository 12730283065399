import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProjectService } from 'zontro-openapi-frontend';
import { AuthService } from '../services/auth.service';
import { SiteViewComponent } from '../site-view/site-view.component';
@Component({
  selector: 'app-main-ui',
  templateUrl: './main-ui.component.html',
  styleUrls: ['./main-ui.component.css']
})
export class MainUiComponent implements OnInit {
  selectedTask: any;
  active = 2;
  currentUser: any;
  userInitials: any
  projectId: any;
  orgName: any
  showSV: boolean = false;
  project:any
  showLegend: boolean = false;

  constructor(private changeDetectorRef: ChangeDetectorRef,
              private authService: AuthService,
              private projectService: ProjectService,
              private route: ActivatedRoute
              ) { }

  passIdToSV(id:any) {
    this.selectedTask = id
    this.changeDetectorRef.detectChanges();
    this.active = 2
  }
  changeTab(ev: any) {
    this.active = ev
  }
  signout() {
    this.authService.signOut()
    this.authService.setLoggedIn(false)
  }
  async ngOnInit() {
    try {
      
      const routeParams = this.route.snapshot.paramMap
      const orgName = routeParams.get('orgName');
      const projectId = routeParams.get('projectId');
      this.projectId = projectId
      this.orgName = orgName
      this.projectService.getProjectDetails(this.projectId,this.orgName)
                                          .subscribe((result: any) => { 
                                            this.project = result[0]
                                            if(result[0].status=='In Progress') {
                                              this.showSV = true
                                            } else {
                                              this.showSV = false
                                            }
                                          })
      this.currentUser = await this.authService.getCurrentUser()
      var initials = []
      initials = this.currentUser.attributes.name.trim().split(" ");
      this.userInitials=""
      if(initials.length>1) {
        this.userInitials = this.userInitials.concat(initials[0].substring(0,1),initials[initials.length-1].substring(0,1))
      } else {
        this.userInitials = this.userInitials.concat(initials[0].substring(0,1))
      }
    } catch(error:any){
      console.log(error) 
    }
  }
  toggleLegend() {
    this.showLegend = !this.showLegend
  }

}

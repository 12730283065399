/**
 * Zontro UI APIs
 * Zontro APIs for UI and backend
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ApiResponse } from '../model/apiResponse';
import { AreaDetail } from '../model/areaDetail';
import { AreaDetailTemp } from '../model/areaDetailTemp';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class AreaService {

    protected basePath = 'http://zontro-webui.mvizn.com:3200/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Add new area deatils
     * 
     * @param body added Scan object
     * @param projectId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addAreaDetails(body: AreaDetail, projectId: string, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public addAreaDetails(body: AreaDetail, projectId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public addAreaDetails(body: AreaDetail, projectId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public addAreaDetails(body: AreaDetail, projectId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addAreaDetails.');
        }

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling addAreaDetails.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ApiResponse>('post',`${this.basePath}/area/add/${encodeURIComponent(String(projectId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns area details of project
     * 
     * @param projectId project id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAreaDetails(projectId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<AreaDetail>>;
    public getAreaDetails(projectId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AreaDetail>>>;
    public getAreaDetails(projectId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AreaDetail>>>;
    public getAreaDetails(projectId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getAreaDetails.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<AreaDetail>>('get',`${this.basePath}/area/get/${encodeURIComponent(String(projectId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

        /**
     * Returns area details of area
     * 
     * @param projectId project id
     * @param areaId area Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
         public getAreaById(projectId: string, areaId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<AreaDetail>>;
         public getAreaById(projectId: string, areaId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AreaDetail>>>;
         public getAreaById(projectId: string, areaId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AreaDetail>>>;
         public getAreaById(projectId: string, areaId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
     
             if (projectId === null || projectId === undefined) {
                 throw new Error('Required parameter projectId was null or undefined when calling getAreaById.');
             }
             if (areaId === null || areaId === undefined) {
                throw new Error('Required parameter areaId was null or undefined when calling getAreaById.');
            }
     
             let headers = this.defaultHeaders;
     
             // to determine the Accept header
             let httpHeaderAccepts: string[] = [
                 'application/json'
             ];
             const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
             if (httpHeaderAcceptSelected != undefined) {
                 headers = headers.set('Accept', httpHeaderAcceptSelected);
             }
     
             // to determine the Content-Type header
             const consumes: string[] = [
             ];
     
             return this.httpClient.request<Array<AreaDetail>>('get',`${this.basePath}/area/getbyid/${encodeURIComponent(String(projectId))}/${encodeURIComponent(String(areaId))}`,
                 {
                     withCredentials: this.configuration.withCredentials,
                     headers: headers,
                     observe: observe,
                     reportProgress: reportProgress
                 }
             );
         }
             /**
     * Returns area details of fresh project
     * 
     * @param projectId project id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTempAreaDetails(projectId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<AreaDetailTemp>>;
    public getTempAreaDetails(projectId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<AreaDetailTemp>>>;
    public getTempAreaDetails(projectId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<AreaDetailTemp>>>;
    public getTempAreaDetails(projectId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getTempAreaDetails.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<AreaDetail>>('get',`${this.basePath}/temp/area/get/${encodeURIComponent(String(projectId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }


    

}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  awsRegion: 'ap-southeast-1',
  userPoolId: 'ap-southeast-1_mqSWDNBRV',
  userPoolWebClientId: '75c4i0sdo6emjtrkmtprft0aph', //app client ID in user pool
  // authenticationFlowType:'USER_PASSWORD_AUTH',
  authenticationFlowType:'USER_SRP_AUTH',
  //basePath: 'https://v3nshf05ug.execute-api.ap-southeast-1.amazonaws.com/dev'
 // basePath: 'http://zontro-webui.mvizn.com:4201'
 //basePath: 'http://localhost:4302'
 // basePath: 'http://demov3.zontro.io:4302'
 basePath: 'https://demov3.zontro.io/api'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

<div class="container-flex">
    <div class="row ">
    <div class="col-2">
        <div class="row mt-2"><p style="font-size: 10px; color: transparent;">i</p></div>
        <div class="row">
            <button 
                class="btn btn-sm btn-outline-primary focus mb-2 overflow-hidden text-truncate text-nowrap" 
                style=" float: right; font-size: 10px; max-width: 150px;"   
                data-toggle="tooltip" 
                data-placement="top"
                title="{{parent.text}}">
                {{parent.text}}
            </button>
        </div>
        
    </div>
    <div class="col-1" >
        <div class="row mt-3" 
       
        [ngStyle]="dataMaxLength>0 ? {'border-bottom': 'rgb(26, 162, 253) 2px dotted'} : {'border-bottom': 'rgb(256, 256, 256) 1px solid'}"
        ><p style="font-size: 10px; color: transparent;">i</p>
        </div>
        <div class="row"></div> 
    </div>
    <!-- Line ending at parent -->
    <div class="col-1">
        <div class="row line-top">
            <p  class="pline">i</p>
        </div> 
        <div class="row line" 
            *ngFor="let item of child" 
            style="border-top: rgb(26, 162, 253) 2px dotted;"
            [ngStyle]="item.sl!==dataMaxLength ? {'border-left': 'rgb(26, 162, 253) 2px dotted'} : {'border-left': 'rgb(256, 256, 256) 1px solid'}">
            <p class="pline">i</p>
        </div>
        <div class="row line">
            <p class="pline">i</p>
        </div> 
    </div>
    <div class="col-2">
        <div class="row"><p style="font-size: 10px; color: transparent;">i</p></div>
        <div class="row" *ngFor="let item of child">
            <ng-template #HtmlContent>
                <app-tooltip
                [tooltipData] = "item"></app-tooltip>
            </ng-template>
            <!-- data-toggle="tooltip"  -->
            <button 
                class="btn btn-sm btn-outline-primary mb-1 mt-2 overflow-hidden text-truncate text-nowrap" 
                style=" float: right; font-size: 10px; max-width: 150px;"   
                [tooltip]="$any(HtmlContent)"
                theme='light'
                tooltipClass='tooltp'
                contentType="template"
                placement="right"
                title="{{item.text}}"
                (click) = "openChild(item.id)">
                {{item.text}}
            </button>
            <div  class="progress progress-bg" style="height: 6px; margin: 0%; padding: 0%;">
                <div [ngStyle]="{ 'width' : item.actual+'%' }"  class="progress-bar primary-color-bg" role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
            <!--  -->
            <p class="text-muted m-0 p-0" style="font-size: 9px;">Current Progress: {{item.actual}}%</p> 
        </div>
        <div class="row"><p style="font-size: 10px; color: transparent;">i</p></div>
    </div>

    <!-- 2nd child -->
    <!--line originating from parent-->
    <div class="col-1" *ngIf = "moreChild">
        <div class="row line-top">
            <p  class="pline">i</p>
        </div> 
        <div class="row line" 
            *ngFor="let item of child" 
            [ngStyle]="item.id===selectedId && childMaxLength>0 ? {'border-top': 'rgb(26, 162, 253) 2px dotted'} : {'border-top': 'rgb(256, 256, 256) 1px solid'}">
            <p class="pline">i</p>
        </div>
        <div class="row line">
            <p class="pline">i</p>
        </div> 
    </div>
    <!--line ending at child-->
    <div class="col-1" *ngIf = "moreChild">
        <div class="row line-top">
            <p  class="pline">i</p>
        </div> 
        <div class="row line" 
            *ngFor="let item of childOfChild" 
            style="border-top: rgb(26, 162, 253) 2px dotted;"
            [ngStyle]="item.sl!==childMaxLength ? {'border-left': 'rgb(26, 162, 253) 2px dotted'} : {'border-left': 'rgb(256, 256, 256) 1px solid'}">
            <p class="pline">i</p>
        </div>
        <div class="row line">
            <p class="pline">i</p>
        </div> 
    </div>
    <!--child elements with progress--> 
    <div class="col-2" *ngIf = "moreChild">
        <div class="row"><p style="font-size: 10px; color: transparent;">i</p></div>
        <div class="row" *ngFor="let item of childOfChild">
            <ng-template #HtmlContent>
                <app-tooltip
                [tooltipData] = "item"></app-tooltip>
            </ng-template>
            <button 
                class="btn btn-sm btn-outline-primary mb-1 mt-2 overflow-hidden text-truncate text-nowrap" 
                style=" float: right; font-size: 10px; max-width: 150px;" 
                [tooltip]="$any(HtmlContent)"
                theme='light'
                tooltipClass='tooltp'
                contentType="template"
                placement="right"
                title="{{item.text}}"
                (click) = "passId(item.id)">
                {{item.text}}
            </button>
            <div class="progress progress-bg" style="height: 6px;margin: 0%; padding: 0%;">
                <div [ngStyle]="{ 'width' : item.actual+'%' }" class="progress-bar primary-color-bg" role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
            <p class="text-muted m-0 p-0" style="font-size: 9px;">Current Progress: {{item.actual}}%</p> 
        </div>
        <div class="row"><p style="font-size: 10px; color: transparent;">i</p></div>
    </div>


    <!-- main row div end -->
    </div>
</div>

<meta name="viewport" content="width=device-width, height=device-height, initial-scale=1.0">
<div id = "panoViewer">
    <div id="controls">
        <div class="row">
            <div class="col" >
                <div class="ctrl" id="next-img"
                [tooltip]="$any(HtmlContent1)"
                theme='light'
                tooltipClass='tooltp'
                contentType="template"
                placement="right">
                    <button class="btn btn-light">
                        <i class="fas fa-angle-right"></i><i class="fas fa-angle-right"></i>    
                    </button>
                </div>
            </div>
            <div class="col">
                <div class="ctrl" id="prev-img"
                [tooltip]="$any(HtmlContent2)"
                theme='light'
                tooltipClass='tooltp'
                contentType="template"
                placement="right"
                >
                    <button class="btn btn-light" >
                        <i class="fas fa-angle-left"></i><i class="fas fa-angle-left"></i>
                    </button>
                </div>  
            </div>
        </div>
    </div>
</div>
<div id="calendar">
    <form class="row row-cols-sm-auto">
        <div class="col-12">
          <div class="input-group">
            <input class="form-control" 
                   placeholder="yyyy-mm-dd"
                   [markDisabled]="isEnabled"
                   [minDate]="minDate" 
                   [maxDate]="maxDate"
                   name="dp" 
                   [(ngModel)]="model" 
                   ngbDatepicker #d="ngbDatepicker"
                   (ngModelChange)="changeDate($event)">
            <button class="btn btn-outline-secondary " (click)="d.toggle()" type="button" style="background-color:white">
                <i class="fas fa-calendar-alt fa-fw"></i>
            </button>
          </div>
        </div>
      </form>
</div>
<ng-template #HtmlContent1>
    Next Image
</ng-template>
<ng-template #HtmlContent2>
    Previous Image
</ng-template>
<!-- <div class="container-fluid p-1 date" style="width: max-content;" >
    <select class="form-select" aria-label="select" style="width: 100%;" (change)="selectScan($event)">
      <option *ngFor="let cal of scanList" 
          [value]="cal.scanName" >
          {{cal.scanDate}}
      </option>
    </select>
  </div> -->


export interface FileUpload { 
    projectId: string;
    fileId: string;
    file: Blob;
    tag: FileUpload.TagEnum;
    addedBy?: string;
    rootFileName?: string;
    scanDate?: string
}
export namespace FileUpload {
    export type TagEnum = 'mpp' | 'revit' | 'pano' | 'geoslam' | 'mapping' | 'other';
    export const TagEnum = {
        Mpp: 'mpp' as TagEnum,
        Revit: 'revit' as TagEnum,
        Pano: 'pano' as TagEnum,
        Geoslam: 'geoslam' as TagEnum,
        Mapping: 'mapping' as TagEnum,
        Other: 'other' as TagEnum
    };
}
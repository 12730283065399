import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AreaService } from 'zontro-openapi-frontend';

@Component({
  selector: 'app-area-bim-preview',
  templateUrl: './area-bim-preview.component.html',
  styleUrls: ['./area-bim-preview.component.css']
})
export class AreaBimPreviewComponent implements OnInit {
  projectId: any;
  areaList: any
  select: any;
  @Output() selectedArea = new EventEmitter<any>()
  constructor(private route: ActivatedRoute,
              private areaService: AreaService) { }

  ngOnInit() {
    const routeParams = this.route.snapshot.paramMap
    const orgName = routeParams.get('orgName');
    const projectId = routeParams.get('projectId');
    this.projectId = projectId
    this.areaService.getTempAreaDetails(this.projectId).subscribe(
      result1 => {
        this.areaList=result1
        this.selectArea(this.areaList[0])
      }
    )
  }
  selectArea(area: any) {
    if(area!==undefined){
      this.select = area.areaId;
      this.selectedArea.emit(area.urn)
    }
  }
}

<div class="container-fluid p-0 m-0">
    <div class="row p-0 m-0" style="padding-top: .5% !important;">
        <!-- <div class="col-sm v min-vh-100 "  >
            <div class="container-fluid mt-1 p-0">
                <div class="row mr-0"> -->
                    <div class="col-sm-1">
                        <app-area
                        (selectedScan)="passScan($event)"
                        [taskArea]="scan_name">
                            <!-- (selectedScan)="passScan($event)"
                            (urn)="passUrn($event)"
                            [closestPano]="closestPano" -->
                        </app-area> 
                    </div>
                    <div class="col p-0 m-0">
                        <div class="leftPane ">
                            <app-pano class="mr-0 pr-0"
                                [scan] = "scan_name"
                                [setLock] = "setLock"
                                [mapPos] = "mapPos"
                                (forgePos) = "passForgePos($event)"
                                (yawPitchScan) = "onViewChange($event)" 
                                (calDate) = "passScanFromPano($event)"
                                (mapLocs) = "passMapLocs($event)"
                                (mapScene) = "passMapScene($event)">
                            </app-pano>
                        </div>
                    </div>
                    <div class="col p-0 m-0">
                        <div class="rightPane " *ngIf="large">
                            <app-bim class="mr-0 pr-0"
                            [scan]="scan_name"
                            [scanFromPano]="scanFromPano"
                            [panoPos] = "forgePos"
                            [panoYaw] = "viewS"
                            [taskId] = "taskId"
                            [setLock] = "setLock"
                            [clearSelection] = "showDeviation"
                            (modelLoaded) = "removeLoader($event)"
                            (devPopup) = "showDevPopup($event)">
                            </app-bim>
                        </div>
                        <div id="deviation" *ngIf="showDeviation"> 
                            <div class="card text-center">
                                <div class="card-header" >
                                    <div class="row">
                                        <div class="col" style="text-align:left !important;" *ngIf = "taskDetail!==undefined && taskId!==0">Unit {{taskDetail.taskUnit}} :  {{taskDetail.taskName}}  [ID: {{taskId}}]</div>
                                        <div class="col-sm-1" style="text-align:right !important;">
                                            <button type="button" class="btn-close" aria-label="Close" (click)="closeDeviation()"></button>
                                        </div>
                                    </div>                                              
                                </div>
                                <div class="card-body" style="text-align:left !important;">
                                  <h5 class="card-title">{{deviationDetails.bimId}}</h5>
                                  <p *ngIf="deviationDetails.img!==null" class="card-text" [innerHTML]="deviationDetails.text"></p>
                                  <p *ngIf="deviationDetails.img==null" class="card-text">Deviation details not available for this element</p>
                                  <img *ngIf="deviationDetails.img!==null" src="assets/images/devs/{{deviationDetails.img}}"  class="card-img-top" alt="..." style="width: fit-content; height:fit-content;">
                                </div>
                                <!-- <div class="card-footer text-muted">
                                  {{deviationDetails.footer}}
                                </div> -->
                            </div>                                   
                        </div>
                        <div id="loader" *ngIf="showLoader"> 
                            <div id="loader-card" class="center">
                            <div class="card text-center" >
                                <div class="card-header" >
                                    <div class="row">
                                        <div class="col" style="text-align:left !important;">Please wait while the BIM model is loaded</div>
                                    </div>                                              
                                </div>
                                <div class="card-body">
                                    <div class="d-flex align-items-center">
                                        <strong>Loading...</strong>
                                        <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div> 
                    </div>
                </div>
            <!-- </div> -->
            <div class="map">
                <app-map
                [scan]="scan_name"
                [panoLocs] = "panoLocs"
                [panoScene] = "panoScene"
                (selectedMarkerFloor) = "posFromMap($event)">
                </app-map>
            </div>
            <div class="col lock"                 
                [tooltip]="$any(HtmlContent1)"
                theme='light'
                tooltipClass='tooltp'
                contentType="template"
                placement="left">
                 <button class="btn btn-light" style="float: left; " *ngIf ="setLock==2" (click)="viewLocked()"> 
                    <!-- <button class="btn btn-light" style="float: left; " *ngIf ="setLock==2" > -->
                    <i class="fa fa-unlock-alt fa-lg" aria-hidden="true" ></i>
                </button>
                <button class="btn btn-light " style="float: left; " *ngIf ="setLock ==1" (click)="viewLocked()">
                    <!-- <button class="btn btn-light " style="float: left; " *ngIf ="setLock ==1" > -->
                    <span tooltip="Tooltip" placement="top"></span>
                    <i class="fa fa-lock fa-lg" aria-hidden="true" style="color: #4E91DA;"></i>
                </button>
            <!-- </div>
        </div> -->
        
    </div>
</div>
<ng-template #HtmlContent1>
    Locked/unlocked view
</ng-template>


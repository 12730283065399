<div class="container-fluid" *ngIf="!orgChart">
    <div class="row" style="margin-bottom: 5px" *ngFor="let item of plan">
        <div class="card shadow p-1 mb-1 bg-body rounded">
            <div class="card-header" style="font-size: 12px;">
                <span class="badge primary-color-bg">{{item.sl}}</span>
                {{item.text}} 
                <button class="btn btn-sm btn-outline-primary mr-3" 
                        style="border-color: rgba(255, 255, 255, 0); float: right; font-size: 11px;"  
                        (click) = "onSelected(item)" >
                        View Details <i class="fas fa-chevron-right"></i>
                </button>
            </div>
            <div class="card-body p-1 m-1">
              <div class="row ">
                    <div class="col">
                        <p class="text-muted"  style="font-size: 12px;">Current Progress</p>
                        <div class="progress p-0 m-0" style="height: 20px;background-color: #DBEDFF;">
                            <div [ngStyle]="{ 'width' : item.actual+'%' }" class="progress-bar" role="progressbar" style=" background-color:#ADD2FB;" aria-valuenow="20" aria-valuemin="0" aria-valuemax="1"></div>
                        </div>
                        <p class="card-text"  style="font-size: 12px;">{{item.actual}}%</p>
                    </div>
                    <div class="col">
                        <p class="text-muted "  style="font-size: 12px; ">Planned Progress</p>
                        <div class="progress m-0 p-0" style="height: 20px; background-color: #DBEDFF;">
                            <div [ngStyle]="{ 'width' : item.planned+'%' }" class="progress-bar" role="progressbar" style="background-color:#ADD2FB" aria-valuenow="50" aria-valuemin="0" aria-valuemax="1"></div>
                        </div>
                        <p class="card-text"  style="font-size: 12px;">{{item.planned}}%</p>
                    </div>
                    <div class="col">
                        <div class="card card-inside">
                            <div class="card-header m-0 p-1" style="font-size: 12px;">
                              Completion
                            </div>
                            <div class="card-body m-1 p-1">
                              <p class="card-text"  style="font-size: 13px;">
                                <i class="fas fa-arrow-down"></i> Delayed by {{item.delay}}%</p>
                            </div>
                        </div>
                    </div>
              </div>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid" *ngIf="orgChart">
    <div class="row justify-content-start">
        <div class="col-2 ">
            <button class="btn btn-sm btn-outline-primary mr-3" 
                    style="border-color: rgba(255, 255, 255, 0); float: left; font-size: 11px;"  
                    (click) = "onBack()" > 
                    <i class="fas fa-chevron-left"></i> Back
            </button>
        </div>
    </div>
    <div class="row" style="margin-bottom: 5px">
        <div class="card p-0">
            <div class="card-header" style="font-size: 12px;">
                <span class="badge primary-color-bg">{{selectedTask.sl}}</span>
                {{selectedTask.text}} 
                <!-- <a class="clearfix" href="" (click) = "onSelected(item.id)" style="float: right; font-size: 11px;"> View Details </a> -->
            </div>
            <div class="card-body">
              <div class="row">
                    <div class="col">
                        <p class="card-text"  style="font-size: 12px;">Current Progress</p>
                        <div class="progress" style="height: 20px;background-color: #64b4f650;">
                            <div [ngStyle]="{ 'width' : selectedTask.actual+'%' }" class="progress-bar primary-color-bg" role="progressbar"  aria-valuenow="20" aria-valuemin="0" aria-valuemax="1"></div>
                        </div>
                        <p class="card-text"  style="font-size: 12px;">{{selectedTask.actual}}%</p>
                    </div>
                    <div class="col">
                        <p class="card-text"  style="font-size: 12px;">Planned Progress</p>
                        <div class="progress progress-bg" style="height: 20px;">
                            <div [ngStyle]="{ 'width' : selectedTask.planned+'%' }" class="progress-bar primary-color-bg" role="progressbar"aria-valuenow="50" aria-valuemin="0" aria-valuemax="1"></div>
                        </div>
                        <p class="card-text"  style="font-size: 12px;">{{selectedTask.planned}}%</p>
                    </div>
                    <div class="col">
                        <div class="card">
                            <div class="card-header" style="font-size: 12px;">
                              Completion
                            </div>
                            <div class="card-body">
                              <p class="card-text"  style="font-size: 13px;">
                                <i class="fas fa-arrow-down"></i>  Delayed by {{selectedTask.delay}}%</p>
                            </div>
                          </div>
                    </div>
              </div>
            </div>
        </div>
    </div>
    <div class="row">
        <app-parent-org-chart 
            [projectId] = "projectId"
            (idForGantt) = passIdForGantt($event)
            [selectedParent] = "selectedParent">>
        </app-parent-org-chart>
    </div>
</div>


<!-- <div class=" container-fluid " style="padding-top: .5%;">
    <div class="row">
    <div class="col-9"><p> JTC: Aerospace</p> </div>
    <div class="col-3" style="float: right;"> -->
        <!-- <div class="form-floating" style="float: right;">
            <div class="btn-group" role="group" aria-label="First group">
              <button class=" btn btn-sm btn-outline-primary primary-color " >
                <i class="fas fa-user-friends"></i>   Add Collaborators
              </button>
              <button class=" btn btn-sm btn-outline-primary primary-color" >
                <i class="far fa-file-alt"></i>   Project Documents
              </button>
            </div>
          </div> -->
    <!-- </div>
</div>
</div> -->



<div class="container-fluid " style="padding-top: .2%;">
    <div class="row" >
        <div class="col card-col">
            <div class="card shadow">
                <div class="card-body">
                    <div class="row">
                        <div class="col h-200" fxFlex="100" fxFlex.gt-sm="30" fxLayoutAlign="center center" style="position:relative;" >
                            <ngx-charts-gauge style="position:absolute"
                                [view]="view"
                                [scheme]="colorSchemePlanned"
                                [results]="planned"
                                [showAxis]=false
                                [angleSpan] = 360
                                [startAngle] = 0
                                [tooltipDisabled] = false
                                [valueFormatting]="format"
                                [margin]="[10,0,20,10]">
                            </ngx-charts-gauge>
                            <div style="position:absolute" style="margin: 27px 0px 0px 43px" fxLayoutAlign="center center" fxLayout="column">
                                <i class="fas fa-bullseye" style="font-size: 15px; color: cornflowerblue;"></i>
                            </div>
                        </div>
                        <div class="col" style="padding-top: 5%;">
                            <a class="card-primary-text" >{{plannedProgress}}%</a> <p class="text-muted card-secondary-text">Planned Progress</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col card-col" >
            <div class="card shadow">
                <div class="card-body">
                    <div class="row">
                        <div class="col h-200" fxFlex="100" fxFlex.gt-sm="30" fxLayoutAlign="center center" style="position:relative;">
                            <ngx-charts-gauge style="position:absolute;"
                                [view]="view"
                                [scheme]="colorSchemeActual"
                                [results]="actual"
                                [showAxis]=false
                                [angleSpan] = 360
                                [startAngle] = 0
                                [tooltipDisabled] = true
                                [valueFormatting]="format"
                                [margin]="[10,0,20,10]">
                            </ngx-charts-gauge>
                            <div style="position:absolute" style="margin: 27px 0px 0px 43px" fxLayoutAlign="center center" fxLayout="column">
                                <i class="fas fa-bullseye" style="font-size: 15px; color: #ffcc80"></i>
                            </div>
                        </div>
                    <div class="col" style="padding-top: 5%;">
                    <a class="card-primary-text" >{{actualProgress}}%</a> <p class="text-muted card-secondary-text">Actual Progress</p>
                </div>
            </div>
                </div>
              </div>
        </div>
        <div class="col card-col">
            <div class="card shadow">
                <div class="card-body">
                    <div class="row">
                        <div class="col" style="padding-top: 2%; padding-bottom: 2%;">
                            <!-- <img src="../../assets/images/blue-box.jpg" alt="" width="80" height="80" class="d-inline-block align-text-top"> -->
                            <!-- <i class="far fa-calendar" style="font-size: 50px; color: cornflowerblue;"></i> -->
                            <span class="fa-stack fa-2x" style="font-size: 30px;">
                                <i class="far fa-calendar-check fa-stack-1x primary-color" style="font-size: 15px;"></i>
                                <i class="far fa-circle fa-stack-2x primary-color"></i>
                            </span>
                        </div>
                        <div class="col" style="padding-top: 5%;">
                            <a class="card-primary-text">{{plannedEndDate}}</a> <p class="text-muted card-secondary-text">Planned End</p>
                        </div>
                    </div>
                </div>
              </div>
        </div>
        <div class="col card-col">
            <div class="card shadow">
                <div class="card-body">
                    <div class="row">
                        <div class="col" style="padding-top: 2%; padding-bottom: 2%;">
                            <!-- <img src="../../assets/images/blue-box.jpg" alt="" width="80" height="80" class="d-inline-block align-text-top"> -->
                            <!-- <i class="far fa-calendar-alt" style="font-size: 50px; color: cornflowerblue;"></i> -->
                            <span class="fa-stack fa-2x" style="font-size: 30px;">
                                <i class="far fa-calendar fa-stack-1x" style="font-size: 15px; color: #ffcc80"></i>
                                <i class="far fa-circle fa-stack-2x" style="color: #ffcc80"></i>
                            </span>
                        </div>
                        <div class="col" style="padding-top: 5%;">
                            <a class="card-primary-text">{{expectedEndDate}}</a>
                            <!-- <a class="card-primary-text">2022-08-25</a> -->
                             <p class="text-muted card-secondary-text">Expected End</p>
                        </div>
                    </div>
                </div>
              </div>
        </div>
    </div>
</div>

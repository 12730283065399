/**
 * Zontro UI APIs
 * Zontro APIs for UI and backend
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface AreaDetail { 
    areaId?: string;
    unit?: string;
    floor?: string;
    urn?: string;
    floorPlanBounds?: string;
    closestPano?: number;
    floorPlanPath?: string;
    urnOffset?: string
}
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { gantt } from 'dhtmlx-gantt';
import { LinkService, Task, Link, TaskService } from 'zontro-openapi-frontend';

const url = "https://export.dhtmlx.com/gantt/api.js";
@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-schedule-preview',
  templateUrl: './schedule-preview.component.html',
  providers: [TaskService, LinkService],
  styleUrls: ['./schedule-preview.component.css']
})
export class SchedulePreviewComponent implements OnInit {
  @Input() myMppFile: any
  @Input() newMppSaved: any
  currentUser: any
  projectId: any
  @Output() scheduleAdded = new EventEmitter<any>()
  @ViewChild("gantt_here", {static:true})
  ganttContainer!: ElementRef;
  loadAPI!: Promise<any>;
  projectData: any
  constructor(private taskService: TaskService,
              private linkService: LinkService) { }

  ngOnInit() {
    gantt.config.date_format = "%Y-%m-%d %H:%i:%s";
    gantt.plugins({
	    auto_scheduling: true,
	    critical_path: true,
	    drag_timeline: false,
	    fullscreen: true,
	    keyboard_navigation: true,
	    marker: true,
	    tooltip: true,
	    undo: false,
    });
    this.loadAPI = new Promise((resolve) => {
           this.loadScript();
         });

  }
  public loadScript() {
    let node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    node.async = true;
    //node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);
  }
   getparsed(project: any) {
    var i: any;
    gantt.attachEvent("onParse",  () => {
      gantt.eachTask( (task) => {
        if (gantt.hasChild(task.id)) {
          task.type = gantt.config.types.project;
        } else if (task.duration === 0) {
          task.type = gantt.config.types.milestone;
        } else {
        }
      });
    }, {once: false});
    if (project) {
      gantt.clearAll();
      if (project.config.duration_unit) {
        gantt.config.duration_unit = project.config.duration_unit;
      }                    
      gantt.parse(project.data);
      this.projectData = project.data;
    }
    this.scheduleAdded.emit(3)
  }
  ngOnChanges(changes: SimpleChanges) {
    if(changes['myMppFile']!==undefined && this.myMppFile){
        let file = changes['myMppFile'].currentValue;
        this.myMppFile=file;
        gantt.importFromMSProject({
              data: this.myMppFile,
              taskProperties: ["Notes", "Name"],
              callback: this.getparsed.bind(this) 
            });
    } 
    if(changes['newMppSaved']!==undefined && this.newMppSaved){
      let file = changes['newMppSaved'].currentValue;
      this.newMppSaved=file;
      this.projectId = this.newMppSaved.projectId
      this.currentUser = this.newMppSaved.user
      this.addMppToDb()
    } 
  }
  addMppToDb() {
    var newProject: Task[] = []
    var newLinks: Link[] = []
    gantt.eachTask( (task) => {
       if (gantt.hasChild(task.id)) {
         task.type = gantt.config.types.project;
         var newTask = {
          id: parseFloat(task.id),
          projectId: this.projectId,
          text: task.text,
          startDate: task.start_date,
          duration: task.duration,
          progress: task.progress,
          plannedStart: task.start_date,
          plannedEnd: task.end_date,
          parent: parseFloat(task.$rendered_parent),
          taskTypes: task.type,
          userId: this.currentUser,
          deleted: true,
          conflict: false,
          inputType: true 
        }
        newProject.push(newTask)
       } else {
         var newTask = {
            id: parseFloat(task.id),
            projectId: this.projectId,
            text: task.text,
            startDate: task.start_date,
            duration: task.duration,
            progress: task.progress,
            plannedStart: task.start_date,
            plannedEnd: task.end_date,
            parent: parseFloat(task.$rendered_parent),
            taskTypes: task.$rendered_type,
            userId: this.currentUser,
            deleted: true,
            conflict: false,
            inputType: true 
          }
          newProject.push(newTask)
       }

    })
    this.taskService.addNewTask(newProject,this.projectId,this.currentUser).subscribe(
      (data: any) => {
        this.scheduleAdded.emit(1)
      })
    for(var i=0;i<this.projectData.links.length;i++) {
      var newLink = {
        id: parseFloat(this.projectData.links[i].id),
        projectId: this.projectId,
        sourceId: parseFloat(this.projectData.links[i].source),
        targetId: parseFloat(this.projectData.links[i].target),
        lag: parseFloat(this.projectData.links[i].lag),
        linkType: parseFloat(this.projectData.links[i].type),
        userId: this.currentUser,
        ifDeleted: false
      }
      newLinks.push(newLink)
    }
    this.linkService.addNewLink(newLinks,this.projectId).subscribe(
         (data: any) => {
          this.scheduleAdded.emit(2)
         })
  }
  ngAfterViewInit(){
    gantt.init(this.ganttContainer.nativeElement);
  }
  ngOnDestroy() {
    gantt.clearAll();
    //gantt.destructor()
  }
}

<div class="card">
    <div class="card-body">
        <div class="row">
            <div class="col align-self-center">
                {{tooltipData.text}}
                <ngx-charts-gauge 
                [view]="view"
                [scheme]="colorSchemePlanned"
                [results]="actual"
                [showAxis]=false
                [angleSpan] = 360
                [startAngle] = 0
                [tooltipDisabled] = false
                [valueFormatting]="format"
                [margin]="[0,0,0,45]"
                >
                </ngx-charts-gauge>
            </div>
            <div class="col" style="padding-top: 5%;">
                <p style="font-size: 10px; color: rgb(44, 44, 44);">Actual Progress: <a style="color: #64b5f6; font-size: 10px;">{{tooltipData.actual}}%</a></p> 
                <p style="font-size: 10px; color: rgb(44, 44, 44);">Planned Progress: <a style="color: #64b5f6; font-size: 10px;">{{tooltipData.planned}}%</a></p> 
            </div>
        </div>
    </div>
</div>
/**
 * Zontro UI APIs
 * Zontro APIs for UI and backend
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ApiResponse } from '../model/apiResponse';
import { Task } from '../model/task';
import { TaskDelay } from '../model/taskDelay';
import { TaskDetail } from '../model/taskDetail';
import { TaskId } from '../model/taskId';
import { TaskProgress } from 'zontro-openapi-frontend/model/taskProgress';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class TaskService {

    protected basePath = 'http://zontro-webui.mvizn.com:3200/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Add task to setup project
     * 
     * @param body Created Task object
     * @param projectId project id to add new tasks to
     * @param userId owner of project
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addNewTask(body: Array<Task>, projectId: string, userId: string, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public addNewTask(body: Array<Task>, projectId: string, userId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public addNewTask(body: Array<Task>, projectId: string, userId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public addNewTask(body: Array<Task>, projectId: string, userId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addNewTask.');
        }

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling addNewTask.');
        }

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling addNewTask.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ApiResponse>('post',`${this.basePath}/task/addnew/${encodeURIComponent(String(projectId))}/${encodeURIComponent(String(userId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add a new task to the project
     * 
     * @param body Created Task object
     * @param projectId project id to add new tasks to
     * @param userId posted by
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addTask(body: Task, projectId: string, userId: string, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public addTask(body: Task, projectId: string, userId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public addTask(body: Task, projectId: string, userId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public addTask(body: Task, projectId: string, userId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addTask.');
        }

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling addTask.');
        }

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling addTask.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

         return this.httpClient.request<ApiResponse>('post',`${this.basePath}/task/add/${encodeURIComponent(String(projectId))}/${encodeURIComponent(String(userId))}`,
        //     
        //return this.httpClient.request<ApiResponse>('post',`${this.basePath}/ta/add/${encodeURIComponent(String(projectId))}/${encodeURIComponent(String(userId))}`,
       
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Add task details
     * 
     * @param body Created task object
     * @param projectId project id to add new tasks to
     * @param taskId task
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addTaskDetails(body: TaskDetail, projectId: string, taskId: number, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public addTaskDetails(body: TaskDetail, projectId: string, taskId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public addTaskDetails(body: TaskDetail, projectId: string, taskId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public addTaskDetails(body: TaskDetail, projectId: string, taskId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addTaskDetails.');
        }

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling addTaskDetails.');
        }

        if (taskId === null || taskId === undefined) {
            throw new Error('Required parameter taskId was null or undefined when calling addTaskDetails.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('post',`${this.basePath}/ms/adddetail/${encodeURIComponent(String(projectId))}/${encodeURIComponent(String(taskId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Delete task by task id
     * 
     * @param taskId task that needs to be deleted
     * @param projectId Updated project object
     * @param body Updated Delete object
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteTask(taskId: string, projectId: string, body?: Task, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public deleteTask(taskId: string, projectId: string, body?: Task, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public deleteTask(taskId: string, projectId: string, body?: Task, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public deleteTask(taskId: string, projectId: string, body?: Task, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (taskId === null || taskId === undefined) {
            throw new Error('Required parameter taskId was null or undefined when calling deleteTask.');
        }

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling deleteTask.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ApiResponse>('post',`${this.basePath}/task/delete/${encodeURIComponent(String(projectId))}/${encodeURIComponent(String(taskId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns a list of tasks for a parent
     * 
     * @param projectId project id
     * @param date selected date for gantt version
     * @param parentId parent id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getChild(projectId: string, date: string, parentId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<Task>>;
    public getChild(projectId: string, date: string, parentId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Task>>>;
    public getChild(projectId: string, date: string, parentId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Task>>>;
    public getChild(projectId: string, date: string, parentId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getChild.');
        }

        if (date === null || date === undefined) {
            throw new Error('Required parameter date was null or undefined when calling getChild.');
        }

        if (parentId === null || parentId === undefined) {
            throw new Error('Required parameter parentId was null or undefined when calling getChild.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Task>>('get',`${this.basePath}/charts/get/${encodeURIComponent(String(projectId))}/${encodeURIComponent(String(date))}/${encodeURIComponent(String(parentId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns list of task details
     * 
     * @param projectId project id for master schedule
     * @param body task list
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTaskDetails(projectId: string, body?: TaskId[], observe?: 'body', reportProgress?: boolean): Observable<Array<TaskDetail>>;
    public getTaskDetails(projectId: string, body?: TaskId[], observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TaskDetail>>>;
    public getTaskDetails(projectId: string, body?: TaskId[], observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TaskDetail>>>;
    public getTaskDetails(projectId: string, body?: TaskId[], observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getTaskDetails.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<TaskDetail>>('post',`${this.basePath}/ms/getdetail/${encodeURIComponent(String(projectId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns a list of all task IDs for a Project
     * 
     * @param projectId projectId for the list of links
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTaskId(projectId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<TaskId>>;
    public getTaskId(projectId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TaskId>>>;
    public getTaskId(projectId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TaskId>>>;
    public getTaskId(projectId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getTaskId.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<TaskId>>('get',`${this.basePath}/ms/getid/${encodeURIComponent(String(projectId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns a list of all Tasks for a Project
     * 
     * @param projectId projectId for
     * @param date selected date for gantt version
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTasks(projectId: string, date: string, observe?: 'body', reportProgress?: boolean): Observable<Array<Task>>;
    public getTasks(projectId: string, date: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Task>>>;
    public getTasks(projectId: string, date: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Task>>>;
    public getTasks(projectId: string, date: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getTasks.');
        }

        if (date === null || date === undefined) {
            throw new Error('Required parameter date was null or undefined when calling getTasks.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Task>>('get',`${this.basePath}/task/get/${encodeURIComponent(String(projectId))}/${encodeURIComponent(String(date))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
       /**
     * Returns progress and planned start for selected Task
     * 
     * @param projectId projectId
     * @param taskId selected task
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getTaskProgress(projectId: string, taskId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<TaskProgress>>;
    public getTaskProgress(projectId: string, taskId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TaskProgress>>>;
    public getTaskProgress(projectId: string, taskId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TaskProgress>>>;
    public getTaskProgress(projectId: string, taskId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getTaskProgress.');
        }
        if (taskId === null || taskId === undefined) {
            throw new Error('Required parameter taskId was null or undefined when calling getTaskProgress.');
        }
        let headers = this.defaultHeaders;
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }
    
        // to determine the Content-Type header
        const consumes: string[] = [
        ];
        return this.httpClient.request<Array<Task>>('get',`${this.basePath}/task/getprogress/${encodeURIComponent(String(projectId))}/${encodeURIComponent(String(taskId))}`,
            {
            withCredentials: this.configuration.withCredentials,
            headers: headers,
            observe: observe,
            reportProgress: reportProgress
            }
        );
    }


       /**
     * Returns sunburst delay data
     * 
     * @param projectId projectId for
     * @param date selected date for gantt version
     * @param taskId selected task as parent
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
        public getDelay(projectId: string, date: string, taskId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<TaskDelay>>;
        public getDelay(projectId: string, date: string, taskId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TaskDelay>>>;
        public getDelay(projectId: string, date: string, taskId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TaskDelay>>>;
        public getDelay(projectId: string, date: string, taskId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
            if (projectId === null || projectId === undefined) {
                throw new Error('Required parameter projectId was null or undefined when calling getDelay.');
            }
            if (date === null || date === undefined) {
                throw new Error('Required parameter date was null or undefined when calling getDelay.');
            }
            if (taskId === null || taskId === undefined) {
                throw new Error('Required parameter taskId was null or undefined when calling getDelay.');
            }
            let headers = this.defaultHeaders;
            let httpHeaderAccepts: string[] = [
                'application/json'
            ];
            const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            if (httpHeaderAcceptSelected != undefined) {
                headers = headers.set('Accept', httpHeaderAcceptSelected);
            }
        
            // to determine the Content-Type header
            const consumes: string[] = [
            ];
            return this.httpClient.request<Array<Task>>('get',`${this.basePath}/charts/sunburst/${encodeURIComponent(String(projectId))}/${encodeURIComponent(String(date))}/${encodeURIComponent(String(taskId))}`,
                {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
                }
            );
        }
        public getTaskById(projectId: string, taskId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<Task>>;
        public getTaskById(projectId: string, taskId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<Task>>>;
        public getTaskById(projectId: string, taskId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<Task>>>;
        public getTaskById(projectId: string,  taskId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
            if (projectId === null || projectId === undefined) {
                throw new Error('Required parameter projectId was null or undefined when calling getTaskById.');
            }
            if (taskId === null || taskId === undefined) {
                throw new Error('Required parameter taskId was null or undefined when calling getTaskById.');
            }
            let headers = this.defaultHeaders;
            let httpHeaderAccepts: string[] = [
                'application/json'
            ];
            const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
            if (httpHeaderAcceptSelected != undefined) {
                headers = headers.set('Accept', httpHeaderAcceptSelected);
            }
        
            // to determine the Content-Type header
            const consumes: string[] = [
            ];
            return this.httpClient.request<Array<Task>>('get',`${this.basePath}/task/get/be/${encodeURIComponent(String(projectId))}/${encodeURIComponent(String(taskId))}`,
                {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
                }
            );
        }
        

}

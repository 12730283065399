import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ProjectService } from 'zontro-openapi-frontend';
declare const Autodesk: any;
declare const THREE: any
@Component({
  selector: 'app-forge-bim-preview',
  templateUrl: './forge-bim-preview.component.html',
  styleUrls: ['./forge-bim-preview.component.css']
})
export class ForgeBimPreviewComponent implements OnInit {
  @Input() urn: any
  @Output() modelLoaded = new EventEmitter<any>()
  token: any
  options: any;
  viewer: any
  constructor(private projectService: ProjectService) { }

  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges) {
    if(changes['urn']!==undefined && this.urn){
      let newUrn = changes['urn'].currentValue;
      this.urn='urn:'+newUrn
      if(this.viewer!=null) {
        this.viewer.finish();
        this.viewer = null;
        Autodesk.Viewing.shutdown();
      }
      this.loadNewScan()

    }
  }
  loadNewScan() {
    this.projectService.getForgeToken('Aerospace_REV3','unit3floor1').subscribe(
      result => { 
        this.token = result.accessToken;
        this.options = {
          //env: 'AutodeskProduction2',
          //api: 'streamingV2',  
          env: 'AutodeskProduction',
          api: 'derivativeV2',  
          endpoint: 'https://cdn.derivative.autodesk.com',
          getAccessToken: function(onTokenReady: any) {
            var token = result.accessToken
            var timeInSeconds = result.expiresIn;
            onTokenReady(token, timeInSeconds);
          }
        }
        Autodesk.Viewing.Initializer(this.options, this.loadViewer.bind(this));
        
      }
    )
  }
  onDocumentLoadSuccess(viewerDocument: any) {
    var defaultModel = viewerDocument.getRoot().getDefaultGeometry();
    this.viewer.start()
	  this.viewer.loadDocumentNode(viewerDocument, defaultModel);
  }
  onDocumentLoadFailure() {
    console.error('Failed fetching Forge manifest');
  }
  async loadViewer(){
    var config3d = {
      memory: {
          limit:  1000 // in MB
      }
    };
    var htmlDiv = document.getElementById('forgeViewer');
    this.viewer = new Autodesk.Viewing.GuiViewer3D(htmlDiv,config3d);
    var startedCode = await this.viewer.start();
    if (startedCode > 0) {
        console.error('Failed to create a Viewer: WebGL not supported.');
        return;
    }
    //console.log('Initialization complete, loading a model next...');
    var documentId = this.urn
    Autodesk.Viewing.Document.load(documentId, this.onDocumentLoadSuccess.bind(this), this.onDocumentLoadFailure);  

    this.viewer.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT,()=> {
      this.modelLoaded.emit(true)
    })
  }
  ngOnDestroy() {
    if(this.viewer){
      this.viewer.finish();
      this.viewer = null;
      Autodesk.Viewing.shutdown();
    }

  }
  
}

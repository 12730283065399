
<div class="card " style="width: 18rem;" [ngStyle]="{'width.px': width}">
  <div class="card-header" >
    <div class="row">
      <div class="col-sm-1 m-0 p-0">
        <div class="btn btn-sm" *ngIf="width==400" (click)="minimizeMap()"><i class="fa fa-arrow-left"></i></div>
        <div class="btn btn-sm " *ngIf="width==100" (click)="minimizeMap()"><i class="fa fa-arrow-right"></i></div>
      </div>
      <div class="col" *ngIf = "width!==100" style="text-align:right !important;">
        <h6 class="card-title" *ngIf = "area!==undefined ">{{area.unit}}:  {{area.floor}}</h6>
      </div>
    </div>
  </div>
  <div class="card-body"> 
      <div class="map-container"  >
        <div class="map-frame">
          <div id="map"></div>
        </div>
      </div>
  </div>
</div>
<div class="container-fluid">
    <div class="row pt-6" style="padding-top: .5% !important;">
        <div class="col-sm v min-vh-100 "  >
            <div class="container-fluid mt-1 p-0">
                <div class="row mr-0">
                    <div class="col-sm-1">
                        <app-area-bim-preview
                        (selectedArea)="passUrn($event)"></app-area-bim-preview>
                        <!-- <app-area> -->
                            <!-- (selectedScan)="passScan($event)"
                            [taskArea]="scan_name" -->
                            <!-- (selectedScan)="passScan($event)"
                            (urn)="passUrn($event)"
                            [closestPano]="closestPano" -->
                        <!-- </app-area>  -->
                    </div>
                    <div class="col p-0 m-0">
                        <div >
                            <app-forge-bim-preview
                                [urn] = "urn"></app-forge-bim-preview>
                            <!-- <app-bim class="mr-0 pr-0"> -->
                                <!-- [scan] = "scan_name"
                                [setLock] = "setLock"
                                (forgePos) = "passForgePos($event)"
                                (yawPitchScan) = "onViewChange($event)" 
                                (calDate) = "passScanFromPano($event)"
                                (mapLocs) = "passMapLocs($event)" -->
                                
                            <!-- </app-bim> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bim-preview',
  templateUrl: './bim-preview.component.html',
  styleUrls: ['./bim-preview.component.css']
})
export class BimPreviewComponent implements OnInit {
  urn: any;

  constructor(private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit(): void {
  }
  passUrn(urn: any) {
    this.urn = urn
    this.changeDetectorRef.detectChanges();
  }
}

<meta name="viewport" content="width=device-width, height=device-height, initial-scale=1.0">
<div id = "panoBim">
    <!-- <div id="controls">
            <div class="row">
                <div class="ctrl" id="nxt-img">
                    <button class="btn btn-light" >
                        <i class="far fa-image" ></i><i class="fas fa-angle-right"></i>    
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="ctrl" id="prv-img">
                    <button class="btn btn-light" >
                        <i class="fas fa-angle-left"></i><i class="far fa-image"></i>
                    </button>
                </div>  
            </div>

    </div> -->
</div>
<!-- <div id="lock" *ngIf="bim">
    <button class="btn btn-light" style="opacity: 0.8;float: left;">
        <i class="fa fa-lock fa-lg" aria-hidden="true" ></i>
    </button>
</div> -->
<!-- <div class = "row">
    <button class="btn btn-light m-1" style="float: right; " *ngIf ="bim" (click)="openBim()" ><a style="margin-left: -6.5px;" *ngIf ="bim" >BIM</a>
    </button>
    <button class="btn btn-dark m-1" style="float: right; " *ngIf ="!bim" (click)="openBim()"><a style="margin-left: -6.5px;" *ngIf ="!bim" >BIM</a>
        <i class="fa fa-col" aria-hidden="true" ></i>
    </button>
</div> -->
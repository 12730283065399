import { Component, Input, OnInit } from '@angular/core';
import { UploadService, UserService, ProjectService } from 'zontro-openapi-frontend';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import {NgbDateStruct, NgbDate,NgbCalendar} from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import * as JSZip from 'jszip';
let zipFile: JSZip = new JSZip();
@Component({
  selector: 'app-setup',
  templateUrl: './setup.component.html',
  styleUrls: ['./setup.component.css']
})
export class SetupComponent implements OnInit {
  title: string = "Create Project"

  @Input() orgName: any = ''
  step: number = 1
  progress: any;
  uploadedFile: any;
  fileId: string = 'file1'
  fileToUpload: File | null = null;
  selectedFile: any;
  subscription!: Subscription;
  tag: string = 'mapping'
  updatedBy: any = 'admin'
  currentUser: any;
  message: string = '';
  messageType: string = '';
  isSaving: boolean = false
  closeResult = '';
  username: any;
  projectName: string =''
  projectId: string = ''
  userInitials!: string;
  projectConfirmed: boolean = false;
  fileProcessed: boolean = false;
  fileList: any;
  fileName: string = '';
  unsupportedFile: boolean = true;
  revitInputs: boolean = false;
  rootFileName: string = ' ' 
  revitSubFolder: string = ''
  addFiles: boolean = false;
  originalProjectId: string = '';
  subCat: boolean = false;
  clientId: string = '';
  projectList: any = []
  projectExists: boolean = false;
  fileUploaded: boolean = true
  rootFileList: any = []
  skipOptional: boolean = false
  myMppFile: any;
  scheduleUploaded: boolean = false;
  uploadMpp: any;
  ganttParsed: boolean = false;
  model!: NgbDateStruct 
  isEnabled : any;
  minDate!: NgbDateStruct 
  maxDate!: NgbDateStruct 
  subdirectories: any = [];
  selectedSubdirectory: any = ''
  constructor(public modal: NgbActiveModal,
              private uploadService: UploadService,
              private projectService: ProjectService,
              private authService: AuthService,
              private userService: UserService,
              private router: Router) { }

  async ngOnInit() {
    this.currentUser = await this.authService.getCurrentUser()
    const { attributes } = this.currentUser;
    this.username = {attributes}.attributes.name
    var initials = []
    initials = this.currentUser.attributes.name.trim().split(" ");
    this.userInitials=""
    // const nextButton = document.getElementById('nextButton') as HTMLInputElement;
    //                                                       nextButton.disabled = true
    if(initials.length>1) {
      this.userInitials = this.userInitials.concat(initials[0].substring(0,1),initials[initials.length-1].substring(0,1))
    } else {
      this.userInitials = this.userInitials.concat(initials[0].substring(0,1))
    }

    if(this.currentUser){
      this.userService.getUserDetails(this.currentUser.username).subscribe(
        (data: any) => {
          if(!jQuery.isEmptyObject(data)) {
             this.orgName = data[0].orgName
             this.clientId = data[0].clientId
             this.projectService.getProjects(data[0].clientId).subscribe(
              result=> {
                this.projectList = result
              })
          }
          if(data.length==0) {
            this.router.navigate(['signin'])
          }
        }
      )
    }
    var date = new Date().toISOString()
    this.model = {
      "year": parseInt(date.substring(0,4)),
      "month": parseInt(date.substring(5,7)),
      "day": parseInt(date.substring(8,10))
    }
  }
  async processFile(setupFiles: any){
    let file: File = setupFiles.files[0];
    this.message = ''
    const reader = new FileReader();
    var length = 10;
    if(file.name) {
      var truncatedName = file.name.substring(0,Math.min(length,file.name.length));
      this.fileName = truncatedName+'...'
      var [filename, fileExtension] = file.name.split(/\.(?=[^\.]+$)/);
      fileExtension = fileExtension.toLowerCase()
      if(this.step==1){
        this.tag='mpp'
        this.unsupportedFile = false
        this.myMppFile = file
        this.isSaving = true
      }  else if(this.step==2) {
        this.rootFileList = []
        this.tag='revit'
        this.unsupportedFile = false
        this.revitInputs = true
        this.addFiles = true
        JSZip.loadAsync(file).then((zip) => { 
          Object.keys(zip.files).forEach((filename) => { 
            if(filename.includes('.rvt')==true) {
              this.rootFileList.push(filename)
              this.unsupportedFile = false
            }
            if(this.rootFileList.length<1){
              this.unsupportedFile = true
              this.message = "Upload .zip file with revit files inside"
            } else {
              this.message = ""
            }
          });
        });
      } else if(this.step==3) {
        let excelUploaded = false
        let textUploaded = false
        this.revitInputs = true
        this.tag='mapping'
        await JSZip.loadAsync(file).then((zip) => { 
          Object.keys(zip.files).forEach((filename) => { 
            if(filename.toLowerCase().includes('.xlsx')==true || filename.toLowerCase().includes('.xls')==true || filename.toLowerCase().includes('.csv')==true) {
              excelUploaded=true
            } 
            if(filename.toLowerCase().includes('.txt')==true ) {
              textUploaded = true
            }
            if(excelUploaded==false || textUploaded==false) {
              this.unsupportedFile = true
              this.message = "Upload .zip file with mapping excel and corresponding text files "
            } else {
              this.unsupportedFile=false
              this.message = ""
              this.revitInputs = false
            }
          });
        });
      } else if(this.step==4) {
        let scanUploaded = false
        let imageUploaded = false
        this.revitInputs = true
        this.tag='geoslam'
        await JSZip.loadAsync(file).then((zip) => { 
          Object.keys(zip.files).forEach((filename) => { 
            if(filename.toLowerCase().includes('.jpg')==true || filename.includes('.jpeg')==true ) {
              imageUploaded=true
            } 
            if(filename.toLowerCase().includes('.geoslam')==true ) {
              scanUploaded=true
            } 
            if(scanUploaded==false || imageUploaded==false) {
              this.unsupportedFile = true
              this.message = "Zipped file should contain 1 geoslam file, reference files and panorama images from Lidar Scan"
            } else {
              //if(this.selectedSubdirectory!=="") {
                this.unsupportedFile=false
              //}
              this.message = ""
              this.revitInputs = false
            }
          });
        });
        this.unsupportedFile = false
      } 
      var fileTypesAllowed = ["mpp","xml","zip"]
      if(fileTypesAllowed.includes(fileExtension)==false)  {
        this.unsupportedFile = true
        this.displayMessage(`File formats allowed: .mpp, .xml, .zip`, 'danger')
      }
      if(this.unsupportedFile == false) {
        //this.isSaving = true
        this.fileId = filename.replace(/ /g,"_");
        reader.addEventListener('load', (event: any) => {
          this.selectedFile = {
            src: event.target.result,
            file: file
          }
          this.fileProcessed = true
        })
        reader.readAsDataURL(file);
        //this.isSaving = false
      } else {}
    }

  }

  onSave(){
    if(this.selectedFile ){
      this.isSaving = true
      this.fileProcessed = false
      // if(this.rootFileName ==' ') {
      //   this.message = "Add sub-directory for BIM "
      // }
        // if(this.rootFileName !== ' ' && this.revitSubFolder.length>0 ) {
        //   this.projectId = this.projectId+'/'+this.revitSubFolder.replace(/ /g,"_")
        //    this.revitInputs = false
        // }
      if(this.step==1) {
        this.uploadMpp = {
          user: this.currentUser.username,
          projectId: this.projectName.replace(/ /g,"_").toLowerCase(),
          mppFileSaved: true
        }
      }
      var scanMonth = ("0"+this.model.month).slice(-2)
      var scanDate = "m"+this.model.year.toString()+scanMonth+this.model.day.toString();
      this.subscription = this.uploadService.addFilesForm(this.projectId, this.fileId.replace(/ /g,"_"), this.selectedFile.file, this.tag, this.currentUser.username, this.rootFileName, scanDate)
                                            .subscribe((result: any) => {
                                                        if(result.fileUrl){
                                                          this.isSaving = false
                                                          this.addFiles = false
                                                          this.projectId = this.originalProjectId
                                                          this.revitSubFolder = ''
                                                          this.rootFileName = ''
                                                          //this.fileUploaded = false
                                                          // const nextButton = document.getElementById('nextButton') as HTMLInputElement;
                                                          // nextButton.disabled = false;
                                                          if(this.tag == "mpp") {
                                                            setTimeout(() => {this.getFiles()}, 2000)
                                                          } else if(this.tag == "mapping"){
                                                            setTimeout(() => {this.getFiles()}, 2000)
                                                          } else {
                                                            this.getFiles()
                                                          }
                                                          
                                                        } else {
                                                          this.isSaving = false
                                                          this.addFiles = false
                                                          this.projectId = this.originalProjectId
                                                          this.revitSubFolder = ''
                                                          this.rootFileName = ''
                                                          this.displayMessage(`Error in uploading file`, 'danger')
                                                        }
                                                       },
                                                      error => {
                                                        this.isSaving = false
                                                        this.addFiles = false
                                                        this.projectId = this.originalProjectId
                                                        this.revitSubFolder = ''
                                                        this.rootFileName = ''
                                                        this.displayMessage(`File upload failed. Please try again`, 'danger')
                                                        console.log(error)
                                                       }
                                                    )
    }
  }
  displayMessage(message: string, messageType: string){
    this.message = message
    this.messageType = messageType
  }

  addProject() {
    if(this.projectName.length<=0) {
      this.message = 'Please add your Project name'
    } else {
      this.message = ''
      this.projectConfirmed = true
      this.title = 'Project Setup'
      for(var i=0;i<this.projectList.length; i++) {
        if(this.projectList[i].projectId==this.projectName.replace(/ /g,"_").toLowerCase()) {
          this.projectExists = true
          this.message = this.projectList[i].projectName+' ('+this.orgName+') exists!'
        }
      }
      if(this.projectExists==false) {
        this.loadProject()
      }

    }

  }

  loadProject() {
    this.projectExists = false;
    this.message = ''
    this.projectId = this.clientId+'/'+this.projectName.replace(/ /g,"_").toLowerCase()
    //this.projectId = this.projectName.replace(/ /g,"_").toLowerCase()
    this.originalProjectId = this.projectId
    this.projectService.getProjectDetails(this.projectName.replace(/ /g,"_").toLowerCase(),this.clientId)
                                          .subscribe((result: any) => {
                                            if(!jQuery.isEmptyObject(result)) { 
                                            } else {
                                              var newProject = {
                                                projectId: this.projectName.replace(/ /g,"_").toLowerCase(),
                                                projectName: this.projectName,
                                                projectOwner: this.currentUser.username,
                                                clientId: this.clientId,
                                                orgName: this.orgName
                                              }
                                              this.projectService.createProject(newProject)
                                              .subscribe((result1: any) => {
                                                if(!jQuery.isEmptyObject(result1)) { 
                                                  this.projectService.getProjectDetails(this.projectName.replace(/ /g,"_").toLowerCase(),this.clientId)
                                                  .subscribe((result: any) => { })
                                                } else {
                                                  this.message = 'Project creation failed, please try again'
                                                }
                                               })
                                            }
                                           })

    this.getFiles()
  }
  deleteFile(file: any) {
    var fileInfo = {
      projectId: this.projectId,
      fileName: file.fileNameOriginal,
      removedBy: this.currentUser.username
    }
    this.subCat = false
    this.uploadService.deleteFiles(fileInfo).subscribe(result => {
      this.getFiles()
    })
  }
  getFiles() {
    this.subdirectories = []
    this.fileUploaded = true
    this.scheduleUploaded= false;
    this.ganttParsed = false
    this.uploadService.getFiles(this.projectName.replace(/ /g,"_"), this.currentUser.username).subscribe(result => {
      this.fileList  = result
      this.subCat = true
      
     // this.checkForSubcat(result)
      for(var i=0;i<this.fileList.length;i++){
        if(this.fileList[i].fileType =='Schedule' && this.step==1) {
          this.fileUploaded = false
          this.scheduleUploaded= true;
          this.ganttParsed = true;
        }
        if(this.fileList[i].fileType =='BIM' && this.step==2) {
          this.fileUploaded = false
          //this.subdirectory.push(this.fileList[i].subcategory)
        }
        if(this.fileList[i].fileType =='BIM-Schedule Mapping' && this.step==3) {
          this.fileUploaded = false
        }
        if(this.fileList[i].fileType =='Lidar Scan' && this.step==4) {
          this.fileUploaded = false
        }
        if(this.fileList[i].fileType =='BIM') {
          this.subdirectories.push(this.fileList[i].subcategory)
        }
      }
     this.selectedSubdirectory=this.subdirectories[0] 
    })
  }
  signout() {
    this.authService.signOut()
    this.authService.setLoggedIn(false)
  }
  checkString() {
    if(this.rootFileName!==' ' ) {
      var [filename, fileExtension] = this.rootFileName.split(/\.(?=[^\.]+$)/);
    }
    if(fileExtension=='rvt' && filename.length>0) {
      if(this.revitSubFolder.length>0 ) {
        this.projectId = this.projectId+'/'+this.revitSubFolder.replace(/ /g,"_")
        this.revitInputs = false
      }
    }
    
  }
  checkSelect() {
    if(this.selectedSubdirectory=="") {
      this.message="Please select a Subdirectory"
    } else if(this.unsupportedFile==false){
      this.message=""
      this.revitInputs = false
      this.projectId = this.projectId+'/'+this.selectedSubdirectory
    }
    console.log(this.fileProcessed,this.revitInputs)
  }
  checkForSubcat(result: any){
    result.forEach((x: any) => {
      if(x.subcategory!==' '){
        this.subCat = true
      }
    })
  }
  next() {
    this.step = this.step+1
    //this.fileUploaded = true
    if(this.step ==4) {
      this.skipOptional = false
    }
    this.getFiles()
  }
  previous() {
    this.getFiles()
    this.step = this.step-1
  }
  confirmProject() {
    var mppFiles = 0
    var bimFiles = 0
    var mappingFiles = 0
    var myMppFile

    for(var i=0;i<this.fileList.length;i++){
      if(this.fileList[i].fileType =='Schedule') {
        mppFiles = mppFiles+1
        myMppFile = this.fileList[i].fileNameOriginal
      }
      if(this.fileList[i].fileType =='BIM') {
        bimFiles = bimFiles+1
      }
      if(this.fileList[i].fileType =='BIM-Schedule Mapping') {
        mappingFiles = mappingFiles+1
      }
    }
    if(mppFiles!=1) {
      this.message = 'File list should contain only 1 master schedule file'
    } else if(bimFiles==0) {
      this.message = 'File list should contain atleast 1 zipped revit file'
    } else if(mappingFiles==0) {
      this.message = 'File list should contain atleast 1 file for BIM-Schedule mapping'
    } else {
      var updatedProject = {
        projectId: this.projectName.replace(/ /g,"_").toLowerCase(),
        status: "Processing Files",
        mppFile: myMppFile
      }
      this.projectService.updateProject(updatedProject,this.currentUser.username).subscribe(result => { 
        this.router.navigate(['preview', this.orgName, this.projectName.replace(/ /g,"_").toLowerCase()])
      })
    
    }

  }
  skip() {
    this.skipOptional = true
    this.fileUploaded = false
  }
  scheduleAdded(ev: any) {
    if(ev==2) {
      this.scheduleUploaded = true
    }
    if(ev==3) {
      this.ganttParsed = true
      this.isSaving = false
    }
  }
  changeDate(event: any) {
    if(this.unsupportedFile==false) {
      this.fileProcessed=true
      this.message=""
    }
    
  }
  ngOnDestroy() {
    if(this.subscription) this.subscription.unsubscribe()
  }
}

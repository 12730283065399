<div class="container-fluid">
    <div class="chart-container" #chartContainer style="width: 600px; height: 570px; align-content: center;"></div>
    <!-- <div class="row">
        <div class="col">
            <div id="container" ></div>
        </div>
        <div class="col">
            
        </div>
    </div> -->
</div>

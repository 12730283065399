export * from './area.service';
import { AreaService } from './area.service';
export * from './bimcomp.service';
import { BimcompService } from './bimcomp.service';
export * from './link.service';
import { LinkService } from './link.service';
export * from './note.service';
import { NoteService } from './note.service';
export * from './pano.service';
import { PanoService } from './pano.service';
export * from './project.service';
import { ProjectService } from './project.service';
export * from './scan.service';
import { ScanService } from './scan.service';
export * from './task.service';
import { TaskService } from './task.service';
export * from './upload.service';
import { UploadService } from './upload.service';
export * from './user.service';
import { UserService } from './user.service';
//export * from './forge.service';
//import { ForgeService } from './forge.service';
export const APIS = [AreaService, BimcompService, LinkService, NoteService, PanoService, ProjectService, ScanService, TaskService, UploadService, UserService];

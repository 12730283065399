import { Component, ElementRef, OnInit } from '@angular/core';
import * as echarts from "echarts";

import * as $ from 'jquery';
import { ChartService } from 'src/app/services/chart.service';
import { TaskService } from 'zontro-openapi-frontend';
@Component({
  selector: 'app-sunburst',
  templateUrl: './sunburst.component.html',
  styleUrls: ['./sunburst.component.css']
})
export class SunburstComponent implements OnInit {
  options: any
  projectId: string = 'Aerospace_REV3'
  chartData: any =[];
  date = '2022-01-14T22:00:00.000Z'
  child1: any =[];
  color1 = { color: '#F54F4A' };
  color2 = { color: '#FF8C75' };
  color3 = { color: '#FFB499' };
  color4 = { color: '#FF0000' };
  color5 = { color: '#000000' };
  charts: any;
  level1: any;
  duration1!: number;
  text1: any
  constructor(private elm:ElementRef, private taskService: TaskService, private chartService: ChartService) { }


  ngOnInit() {
    let sunburst =  echarts.init($(this.elm.nativeElement).find('#sunburst')[0]);
    this.taskService.getDelay(this.projectId,this.date,815).subscribe(
      (data:any) => {
        var option = {
          tooltip : {
            trigger: 'item',
            formatter: "{a} <br/>{b} : {c} ({d}%)"
           },
          series: {
            radius: ['15%', '100%'],
            type: 'sunburst',
            sort: undefined,
            emphasis: {
              focus: 'ancestor'
            },
            data: data,
            label: {
              show: false,
              rotate: 'tangential',
              align: 'center',
              formatter: '{b}: {@score} d'
            },
            levels: [],
            itemStyle: {
              color: '#ddd',
              borderWidth: 2,
              borderRadius: 5
            }
          }
        };
        option && sunburst.setOption(option);
      })

    
    //   this.taskService.getChild(this.projectId,this.date,815).subscribe(
    //   data => {
    //     this.level1 = data
    //     console.log(data)
    //     //this.createSunburstData(result)
    //     let sunburst =  echarts.init($(this.elm.nativeElement).find('#sunburst')[0]);
    //     for(var i=0;i<data.length;i++) {  
    //       //this.createNode(data[i])
    //       var color1: { color: string; }
    //       if(data[i].progress>=.8)
    //         color1=this.color1
    //       else if(data[i].progress>=.4 && data[i].progress<.8)
    //         color1 = this.color2
    //       else color1 = this.color3
    //       this.duration1 = data[i].duration
    //       this.text1 = data[i].text
    //       this.taskService.getChild(this.projectId,this.date,data[i].id).subscribe(
    //         result => {
    //           this.child1.splice(0,this.child1.length);
    //           //console.log(this.child1)
    //           for(var i=0;i<result.length;i++) {        
    //             var color
    //             if(result[i].progress>=.8)
    //               color=this.color1
    //             else if(result[i].progress>=.4 && result[i].progress<.8)
    //               color = this.color2
    //             else color = this.color3
    //             var child1 = {value:result[i].duration, name: result[i].text, itemStyle: color }
    //             this.child1.push(child1)
    //           }
    //           console.log(this.level1)
    //           if(this.child1.length!=0) {
    //             var obj = {value:this.duration1, name: this.text1, itemStyle: color1, children: this.child1}
    //             this.chartData.push(obj)
    //           } else {
    //             var objEnd = {value:this.duration1, name: this.text1, itemStyle: color1 }   
    //             this.chartData.push(objEnd)        
    //           }  
    //         }
    //       )
    //       this.charts=this.chartData
    //     }
    //     console.log(this.charts)
        
    //     var option = {
    //       tooltip : {
    //         trigger: 'item',
    //         formatter: "{a} <br/>{b} : {c} ({d}%)"
    //        },
    //       series: {
    //         radius: ['15%', '100%'],
    //         type: 'sunburst',
    //         sort: undefined,
    //         emphasis: {
    //           focus: 'ancestor'
    //         },
    //         data: this.charts,
    //         label: {
    //           show: false,
    //           rotate: 'tangential',
    //           align: 'center',
    //           formatter: '{b}: {@score} d'
    //         },
    //         levels: [],
    //         itemStyle: {
    //           color: '#ddd',
    //           borderWidth: 2,
    //           borderRadius: 5
    //         }
    //       }
    //     };
    //     //console.log(option.series.data)
    //     option && sunburst.setOption(option);
        
    //   })
    
    // const item1 = {
    //   color: '#F54F4A'
    // };
    // const item2 = {
    //   color: '#FF8C75'
    // };
    // const item3 = {
    //   color: '#FFB499'
    // };
    // const data = [
    //   {
    //     name:'construction',
    //     children: [
    //       {
    //         name: 'pillar',
    //         value: 5,
    //         children: [
    //           {
    //             value: 1,
    //             itemStyle: item1
    //           },
    //           {
    //             name: 'column',
    //             value: 2,
    //           },
    //           {
    //             name: 'walls',
    //             value: 3,
    //           }
    //         ],
    //         itemStyle: item1
    //       },
    //       {
    //         value: 10,
    //         children: [
    //           {
    //             value: 6,
    //             itemStyle: item3
    //           },
    //           {
    //             value: 2,
    //             itemStyle: item3
    //           },
    //           {
    //             value: 2,
    //             itemStyle: item3
    //           }
    //         ],
    //         itemStyle: item1
    //       }
    //     ],
    //     itemStyle: item1
    //   },
    //   {
    //     value: 9,
    //     children: [
    //       {
    //         value: 4,
    //         children: [
    //           {
    //             value: 2,
    //             itemStyle: item2
    //           },
    //           {
    //             value: 2,
    //             itemStyle: item3
    //           }
    //         ],
    //         itemStyle: item1
    //       },
    //       {
    //         children: [
    //           {
    //             value: 3,
    //           }
    //         ],
    //         itemStyle: item3
    //       }
    //     ],
    //     itemStyle: item2
    //   },
    //   {
    //     value: 7,
    //     children: [
    //       {
    //         children: [
    //           {
    //             value: 1,
    //             itemStyle: item3
    //           },
    //           {
    //             value: 3,
    //             itemStyle: item2
    //           },
    //           {
    //             value: 2,
    //             itemStyle: item1
    //           }
    //         ],
    //         itemStyle: item3
    //       }
    //     ],
    //     itemStyle: item1
    //   },
    //   {
    //     children: [
    //       {
    //         value: 6,
    //         children: [
    //           {
    //             value: 1,
    //             itemStyle: item2
    //           },
    //           {
    //             value: 2,                
    //             itemStyle: item1
    //           },
    //           {
    //             value: 1,
    //             itemStyle: item3
    //           }
    //         ],
    //         itemStyle: item3
    //       },
    //       {
    //         value: 3,
    //         children: [
    //           {
    //             value: 1
    //           },
    //           {
    //             value: 2,
    //             itemStyle: item3
    //           },
    //           {
    //             value: 1
    //           }
    //         ],
    //         itemStyle: item3
    //       }
    //     ],
    //     itemStyle: item1
    //   }
    // ];



  }

  // async createSunburstData(data: any) {
  //   //console.log(data);
  //   let sunburst =  echarts.init($(this.elm.nativeElement).find('#sunburst')[0]);
  //   for(var i=0;i<data.length;i++) {  
  //     //this.createNode(data[i])
  //     var color1: { color: string; }
  //     if(data[i].progress>=.8)
  //       color1=this.color1
  //     else if(data[i].progress>=.4 && data[i].progress<.8)
  //       color1 = this.color2
  //     else color1 = this.color3
    
  //     this.taskService.getChild(this.projectId,this.date,data[i].id).subscribe(
  //       result => {
  //         this.child1.splice(0,this.child1.length);
  //         //console.log(this.child1)
  //         for(var i=0;i<result.length;i++) {        
  //           var color
  //           if(result[i].progress>=.8)
  //             color=this.color1
  //           else if(result[i].progress>=.4 && result[i].progress<.8)
  //             color = this.color2
  //           else color = this.color3
  //           var child1 = {value:result[i].duration, name: result[i].text, itemStyle: color }
  //           this.child1.push(child1)
  //         }
  //         if(this.child1.length!=0) {
  //           var obj = {value:data[i].duration, name: data[i].text, itemStyle: color1, children: this.child1}
  //           this.chartData.push(obj)
  //         } else {
  //           var objEnd = {value:data[i].duration, name: data[i].text, itemStyle: color1 }   
  //           this.chartData.push(objEnd)        
  //         }  
  //       }
  //     )
  //     this.charts=this.chartData
  //   }
  //   //console.log(this.charts)
    
  //   var option = {
  //     tooltip : {
  //       trigger: 'item',
  //       formatter: "{a} <br/>{b} : {c} ({d}%)"
  //      },
  //     series: {
  //       radius: ['15%', '100%'],
  //       type: 'sunburst',
  //       sort: undefined,
  //       emphasis: {
  //         focus: 'ancestor'
  //       },
  //       data: this.charts,
  //       label: {
  //         show: false,
  //         rotate: 'tangential',
  //         align: 'center',
  //         formatter: '{b}: {@score} d'
  //       },
  //       levels: [],
  //       itemStyle: {
  //         color: '#ddd',
  //         borderWidth: 2,
  //         borderRadius: 5
  //       }
  //     }
  //   };
  //   //console.log(option.series.data)
  //   option && sunburst.setOption(option);
  // }

  // async createNode(data:any){
  //   var color1: { color: string; }
  //   if(data.progress>=.8)
  //     color1=this.color1
  //   else if(data.progress>=.4 && data.progress<.8)
  //     color1 = this.color2
  //   else color1 = this.color3
  
  //   await this.taskService.getChild(this.projectId,this.date,data.id).subscribe(
  //     result => {
  //       this.child1.splice(0,this.child1.length);
  //       //console.log(this.child1)
  //       for(var i=0;i<result.length;i++) {        
  //         var color
  //         if(result[i].progress>=.8)
  //           color=this.color1
  //         else if(result[i].progress>=.4 && result[i].progress<.8)
  //           color = this.color2
  //         else color = this.color3
  //         var child1 = {value:result[i].duration, name: result[i].text, itemStyle: color }
  //         this.child1.push(child1)
  //       }
  //       if(this.child1.length!=0) {
  //         var obj = {value:data.duration, name: data.text, itemStyle: color1, children: this.child1}
  //         this.chartData.push(obj)
  //       } else {
  //         var objEnd = {value:data.duration, name: data.text, itemStyle: color1 }   
  //         this.chartData.push(objEnd)        
  //       }  
  //     }
  //   )
  // }
}

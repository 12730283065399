import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChartService } from 'src/app/services/chart.service';
import { TaskService } from 'zontro-openapi-frontend';

@Component({
  selector: 'app-parent-org-chart',
  templateUrl: './parent-org-chart.component.html',
  styleUrls: ['./parent-org-chart.component.css']
})
export class ParentOrgChartComponent implements OnInit {

  @Input() selectedParent:any
  @Input() projectId: any 
  date = new Date().toISOString()
  child: any =[]
  parent: any
  moreChild: boolean = false;
  childOfChild: any = [];
  plan: any = [];
  selectedId!: number;
  childMaxLength!: number;
  dataMaxLength!: number;
  @Output() idForGantt = new EventEmitter<any>()
  constructor(private taskService: TaskService, 
              private chartService: ChartService,
              private route: ActivatedRoute) { }

  ngOnInit(){
    const routeParams = this.route.snapshot.paramMap
    const projectId = routeParams.get('projectId');
    this.projectId = projectId
  }
  ngOnChanges(changes: SimpleChanges) {
    if(changes['projectId']!==undefined  && this.projectId){
      let task = changes['projectId'].currentValue;
      this.projectId = task
    }
    if(changes['selectedParent']!==undefined && this.selectedParent){
      let task = changes['selectedParent'].currentValue;
      this.parent = task
      this.taskService.getChild(this.projectId,this.date,task.id).subscribe(
      result => {
        this.createChartData(result)
      })
    }
  }
  openChild(id: any) {
    this.selectedId = id
    this.moreChild = true
    this.childOfChild.splice(0,this.childOfChild.length);
    this.taskService.getChild(this.projectId,this.date,id).subscribe(
    result => {
      this.createChildChartData(result)
    })
    this.idForGantt.emit(id)
  }
  createChildChartData(data: any){
    this.childMaxLength = data.length
    for(var i=0; i<data.length;i++) {
      var plan = this.chartService.plannedProgress(data[i].planned_end, data[i].planned_start)
      var data1 = {
        sl:i+1,
        id:data[i].id,
        text: data[i].text,
        actual: Math.round(data[i].progress*100),
        planned: plan 
      }
      this.childOfChild.push(data1)
    }
  }
  createChartData(data: any){
    this.dataMaxLength = data.length
    for(var i=0;i<data.length; i++){
      var plan = this.chartService.plannedProgress(data[i].planned_end, data[i].planned_start)
      var data1={
        sl:i+1,
        id:data[i].id,
        text:data[i].text,
        actual:Math.round(data[i].progress*100),
        planned:plan,
        delay:Math.round(plan-data[i].progress*100)
      }
      this.child.push(data1)
    }
  }
  passId(id:any) {
    this.idForGantt.emit(id)
  }
}

<nav class="navbar navbar-expand-lg pb-1  navbar-light bg-light pt-0 mb-4 shadow fixed-top" style="height: 3% !important;">
    <div class="container-fluid">
      <a class="navbar-brand" href="#">
        <img src="../../assets/images/logo.png" alt="" width="30" height="30" class="d-inline-block align-text-top">
        <a style="color: #4E91DA; font-weight: 650;">    Zontro</a>    
        <a class="navbar-brand" href="#" *ngIf = "orgName!==''" style="color: rgb(71, 71, 71); font-size: smaller; padding-left: 20%;">
            Organization: {{orgName}} 
        </a>   
      </a>
      <div>
        <div ngbDropdown class="d-inline-block">
          <button type="button" class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle>{{userInitials | uppercase }}</button>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
            <button ngbDropdownItem (click)="signout()">Signout</button>
          </div>
        </div>
      </div>
  </div>
</nav>
<div class="container-fluid mt-3 pt-4">
    <div class="card h-100 pl-4 " >
        <div class="card-body">
            <div class="container-fluid">
                <div class="row">
                    <div class="row" style="margin-left: 30px !important; margin-right: 30px !important;">
                        <div class="col pt-4">
                            <p style="font-size: 22px;">Projects</p>
                        </div>
                        <div class="col-sm-2 p-4">
                            <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                                <button class="nav-item nav-link-edit w-20 btn btn-primary mr-3" [routerLink]="['../newproject']">Create Project</button >
                            </div>
                            
                            <!-- <button class="w-20 btn btn-primary mr-3 ">Create Project</button> -->
                        </div>
                    </div>
                    <div class="row-md-4 ml-3 mr-3" style="margin-bottom: 5px; margin-top: .2%;" *ngFor="let item of projectList">
                        <div class="card m-3 shadow p-1 mb-1 bg-body rounded">
                            <div class="card-header" style="font-size: 15px;">
                                <!-- <span class="badge primary-color-bg">{{item.sl}}</span> -->
                                <p style="font-size: 20px; "><span style="font-weight: 600; color: rgb(93, 97, 99);">{{item.projectName}}</span></p>
                                <p style="font-size: 14px; color: rgb(104, 108, 110); ">Created on: {{item.creationDate.substring(0,10)}}</p>
                            </div>
                            <div class="card-body p-4 m-4">
                                <div class="col">
                                    <p style="font-size: 18px; ">Organization: <span style="font-weight: 600; color: cornflowerblue;">{{item.orgName}}</span></p>
                                    <p style="font-size: 18px; ">Owner: <span style="font-weight: 600; color: rgb(93, 97, 99);">{{item.projectOwner}}</span></p>
                                    <p style="font-size: 18px; ">Status: <span style="font-weight: 600; color: rgb(93, 97, 99);">{{item.status}}</span></p>
                                </div>
                                <div class="col">
                                    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                                        <button class="btn btn-sm btn-outline-primary mr-3 " *ngIf = "item.status==='In Progress' || item.status==='Setup files uploaded'"
                                        style=" float: right; font-size: 16px;"  
                                        (click) = "onSelectedProject(item)" >
                                        View Project <i class="fas fa-chevron-right"></i>
                                    </button>
                                    <button class="btn btn-sm btn-outline-primary mr-3 " 
                                    style=" float: right; font-size: 16px;"   
                                        (click) = "onSelectedProgress(item)" >
                                        View Processing Progress <i class="fas fa-chevron-right"></i>
                                    </button>
                                    <button class="btn btn-sm btn-outline-primary mr-3 " 
                                        style=" float: right; font-size: 16px;"  
                                        (click) = "deleteProject(item)" >
                                        Delete Project <i class="fas fa-chevron-right"></i>
                                    </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <app-setup style="display:none" 
                [orgName] = "orgName"></app-setup>
            </div>
        </div>
    </div>
</div>


/**
 * Zontro UI APIs
 * Zontro APIs for UI and backend
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ApiResponse } from '../model/apiResponse';
import { BimAll } from '../model/bimAll';
import { BimDetails } from '../model/bimDetails';
import { BimMap } from '../model/bimMap';
import { BimProgress } from '../model/bimProgress';
import { DbIdMap } from '../model/dbIdMap';
import { BimDeviationRequest } from '../model/bimDeviationRequest';
import { BimDeviation } from '../model/bimDeviation'

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class BimcompService {

    protected basePath = 'http://zontro-webui.mvizn.com:3200/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Create a new BIM eelement
     * 
     * @param body added BIM component
     * @param projectId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addBimElements(body: BimMap, projectId: string, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public addBimElements(body: BimMap, projectId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public addBimElements(body: BimMap, projectId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public addBimElements(body: BimMap, projectId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addBimElements.');
        }

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling addBimElements.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ApiResponse>('post',`${this.basePath}/bimcomp/add/${encodeURIComponent(String(projectId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

        /**
     * Add dbId to BIM elements
     * 
     * @param body updated dbIds
     * @param projectId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
         public addDbId(body: DbIdMap, projectId: string, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
         public addDbId(body: DbIdMap, projectId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
         public addDbId(body: DbIdMap, projectId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
         public addDbId(body: DbIdMap, projectId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
     
             if (body === null || body === undefined) {
                 throw new Error('Required parameter body was null or undefined when calling addBimElements.');
             }
     
             if (projectId === null || projectId === undefined) {
                 throw new Error('Required parameter projectId was null or undefined when calling addBimElements.');
             }
     
             let headers = this.defaultHeaders;
     
             // to determine the Accept header
             let httpHeaderAccepts: string[] = [
                 'application/json'
             ];
             const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
             if (httpHeaderAcceptSelected != undefined) {
                 headers = headers.set('Accept', httpHeaderAcceptSelected);
             }
     
             // to determine the Content-Type header
             const consumes: string[] = [
                 'application/json'
             ];
             const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
             if (httpContentTypeSelected != undefined) {
                 headers = headers.set('Content-Type', httpContentTypeSelected);
             }
     
             return this.httpClient.request<ApiResponse>('post',`${this.basePath}/bimcomp/addforge/${encodeURIComponent(String(projectId))}`,
                 {
                     body: body,
                     withCredentials: this.configuration.withCredentials,
                     headers: headers,
                     observe: observe,
                     reportProgress: reportProgress
                 }
             );
         }
    /**
     * Returns all bim elements with progress
     * 
     * @param projectId project id to fetch the bim data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBimProgress(projectId: string, date: string,  observe?: 'body', reportProgress?: boolean): Observable<Array<BimProgress>>;
    public getBimProgress(projectId: string, date: string,  observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<BimProgress>>>;
    public getBimProgress(projectId: string, date: string,  observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BimProgress>>>;
    public getBimProgress(projectId: string, date: string,  observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getBimProgress.');
        }
        if (date === null || date === undefined) {
            throw new Error('Required parameter date was null or undefined when calling getBimProgress.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<BimProgress>>('get',`${this.basePath}/bimcomp/getprogress/${encodeURIComponent(String(projectId))}/${encodeURIComponent(String(date))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    /**
     * Returns all bim elements with details
     * 
     * @param projectId project id to bim details
     * @param taskId task id to fetch BIM details
     * @param date date to fetch BIM details
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
     public getBimDetails(projectId: string, taskId: string, date: string, observe?: 'body', reportProgress?: boolean): Observable<Array<BimDetails>>;
     public getBimDetails(projectId: string, taskId: string, date: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<BimDetails>>>;
     public getBimDetails(projectId: string, taskId: string, date: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BimDetails>>>;
     public getBimDetails(projectId: string, taskId: string, date: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         if (projectId === null || projectId === undefined) {
             throw new Error('Required parameter projectId was null or undefined when calling getBimDetails.');
         }
 
         if (taskId === null || taskId === undefined) {
             throw new Error('Required parameter taskId was null or undefined when calling getBimDetails.');
         }
         if (date === null || date === undefined) {
            throw new Error('Required parameter date was null or undefined when calling getBimDetails.');
         }
 
         let headers = this.defaultHeaders;
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'application/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
         ];
 
         return this.httpClient.request<Array<BimDetails>>('get',`${this.basePath}/bimcomp/getdetails/${encodeURIComponent(String(projectId))}/${encodeURIComponent(String(taskId))}/${encodeURIComponent(String(date))}`,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
 
    //     /**
    //  * Returns all bim elements with details
    //  * 
    //  * @param projectId project id to fetch the data
    //  * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
    //  * @param reportProgress flag to report request and response progress.
    //  */
    // public getBimDetails(projectId: string, taskId: number, observe?: 'body', reportProgress?: boolean): Observable<Array<BimDetails>>;
    // public getBimDetails(projectId: string, taskId: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<BimDetails>>>;
    // public getBimDetails(projectId: string, taskId: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<BimDetails>>>;
    // public getBimDetails(projectId: string, taskId: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
     
    //     if (projectId === null || projectId === undefined) {
    //         throw new Error('Required parameter projectId was null or undefined when calling getBimDetails.');
    //     }
    //     if (taskId === null || taskId === undefined) {
    //         throw new Error('Required parameter taskId was null or undefined when calling getBimDetails.');
    //     }
    //     let headers = this.defaultHeaders;
     
    //     // to determine the Accept header
    //     let httpHeaderAccepts: string[] = [
    //         'application/json'
    //     ];
    //     const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    //     if (httpHeaderAcceptSelected != undefined) {
    //         headers = headers.set('Accept', httpHeaderAcceptSelected);
    //     }
     
    //     // to determine the Content-Type header
    //     const consumes: string[] = [
    //     ];
     
    //     return this.httpClient.request<Array<BimDetails>>('get',`${this.basePath}/bimcomp/getdetails/${encodeURIComponent(String(projectId))}/${encodeURIComponent(String(taskId))}`,
    //         {
    //             withCredentials: this.configuration.withCredentials,
    //             headers: headers,
    //             observe: observe,
    //             reportProgress: reportProgress
    //         }
    //     );
    // }

    /**
     * Update BIM Progress
     * 
     * @param body updated BIM component progress
     * @param projectId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateBimProgress(body: BimAll, projectId: string, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public updateBimProgress(body: BimAll, projectId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public updateBimProgress(body: BimAll, projectId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public updateBimProgress(body: BimAll, projectId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updateBimProgress.');
        }

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling updateBimProgress.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ApiResponse>('post',`${this.basePath}/bimcomp/addprogress/${encodeURIComponent(String(projectId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
        /**
     * Update BIM Progress
     * 
     * @param body updated BIM component progress
     * @param projectId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
         public getBimDeviation(body: BimDeviationRequest, observe?: 'body', reportProgress?: boolean): Observable<BimDeviation>;
         public getBimDeviation(body: BimDeviationRequest, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BimDeviation>>;
         public getBimDeviation(body: BimDeviationRequest, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BimDeviation>>;
         public getBimDeviation(body: BimDeviationRequest, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
     
             if (body === null || body === undefined) {
                 throw new Error('Required parameter body was null or undefined when calling updateBimProgress.');
             }
     
             let headers = this.defaultHeaders;
     
             // to determine the Accept header
             let httpHeaderAccepts: string[] = [
                 'application/json'
             ];
             const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
             if (httpHeaderAcceptSelected != undefined) {
                 headers = headers.set('Accept', httpHeaderAcceptSelected);
             }
     
             // to determine the Content-Type header
             const consumes: string[] = [
                 'application/json'
             ];
             const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
             if (httpContentTypeSelected != undefined) {
                 headers = headers.set('Content-Type', httpContentTypeSelected);
             }
     
             return this.httpClient.request<ApiResponse>('post',`${this.basePath}/bimcomp/get-deviation`,
                 {
                     body: body,
                     withCredentials: this.configuration.withCredentials,
                     headers: headers,
                     observe: observe,
                     reportProgress: reportProgress
                 }
             );
         }
    
}

/**
 * Zontro UI APIs
 * Zontro APIs for UI and backend
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ApiResponse } from '../model/apiResponse';
import { FileUpload } from '../model/fileUpload';
import { FileList } from '../model/fileList';
import { FileInfo } from '../model/fileInfo';
import { FileUrl } from '../model/fileUrl';
import { ForgeFileList } from '../model/forgeFileList';
import { FileStatus } from '../model/fileStatus';
import { FileListStatus } from '../model/fileListStatus';
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


//import { FileUrl } from 'zontro-openapi-frontend/model/fileUrl';


@Injectable()
export class UploadService {

    protected basePath = 'http://zontro-webui.mvizn.com:3200/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

    /**
     * Add file to project&#x27;s file list
     * 
     * @param fileId 
     * @param file 
     * @param tag 
     * @param addedBy 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
     public addFilesForm(projectId: string, fileId: string, file: Blob, tag: string, addedBy: string, rootFileName: string, scanDate: string, observe?: 'body', reportProgress?: boolean): Observable<FileUrl>;
     public addFilesForm(projectId: string, fileId: string, file: Blob, tag: string, addedBy: string, rootFileName: string, scanDate: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FileUrl>>;
     public addFilesForm(projectId: string, fileId: string, file: Blob, tag: string, addedBy: string, rootFileName: string, scanDate: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FileUrl>>;
     public addFilesForm(projectId: string, fileId: string, file: Blob, tag: string, addedBy: string, rootFileName: string, scanDate: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling addFiles.');
        }
         if (fileId === null || fileId === undefined) {
             throw new Error('Required parameter fileId was null or undefined when calling addFiles.');
         }
 
         if (file === null || file === undefined) {
             throw new Error('Required parameter file was null or undefined when calling addFiles.');
         }
 
         if (tag === null || tag === undefined) {
             throw new Error('Required parameter tag was null or undefined when calling addFiles.');
         }
 
         if (addedBy === null || addedBy === undefined) {
             throw new Error('Required parameter addedBy was null or undefined when calling addFiles.');
         }
         if (rootFileName === null || rootFileName === undefined) {
            throw new Error('Required parameter rootFileName was null or undefined when calling addFiles.');
        }
        if (scanDate === null || scanDate === undefined) {
            throw new Error('Required parameter scanDate was null or undefined when calling addFiles.');
        }
 
         let headers = this.defaultHeaders;
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'application/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
             'multipart/form-data'
         ];
 
         const canConsumeForm = this.canConsumeForm(consumes);
 
         let formParams: { append(param: string, value: any): void; };
         let useForm = false;
         let convertFormParamsToString = false;
         // use FormData to transmit files using content-type "multipart/form-data"
         // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
         useForm = canConsumeForm;
         if (useForm) {
             formParams = new FormData();
         } else {
             formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
         }
 
         if (projectId !== undefined) {
            formParams = formParams.append('projectId', <any>projectId) as any || formParams;
         }
         if (fileId !== undefined) {
             formParams = formParams.append('fileId', <any>fileId) as any || formParams;
         }
         if (file !== undefined) {
             formParams = formParams.append('file', <any>file) as any || formParams;
         }
         if (tag !== undefined) {
             formParams = formParams.append('tag', <any>tag) as any || formParams;
         }
         if (addedBy !== undefined) {
             formParams = formParams.append('addedBy', <any>addedBy) as any || formParams;
         }
         if (rootFileName !== undefined) {
            formParams = formParams.append('rootFileName', <any>rootFileName) as any || formParams;
         }
         if (scanDate !== undefined) {
            formParams = formParams.append('scanDate', <any>scanDate) as any || formParams;
         }
 
         return this.httpClient.request<ApiResponse>('post',`${this.basePath}/file/add`,
             {
                 body: convertFormParamsToString ? formParams.toString() : formParams,
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
    /**
     * Add file to project&#x27;s file list
     * 
     * @param body updated BIM component progress
     * @param projectId 
     * @param userId user Id of the logged in user
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteFiles(body: FileInfo, observe?: 'body', reportProgress?: boolean): Observable<ApiResponse>;
    public deleteFiles(body: FileInfo, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ApiResponse>>;
    public deleteFiles(body: FileInfo, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ApiResponse>>;
    public deleteFiles(body: FileInfo, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling deleteFiles.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ApiResponse>('post',`${this.basePath}/file/delete`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Returns file list for project
     * 
     * @param projectId project id
     * @param userId user Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFiles(projectId: string, userId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<FileList>>;
    public getFiles(projectId: string, userId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<FileList>>>;
    public getFiles(projectId: string, userId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<FileList>>>;
    public getFiles(projectId: string, userId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (projectId === null || projectId === undefined) {
            throw new Error('Required parameter projectId was null or undefined when calling getFiles.');
        }

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling getFiles.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<FileUpload>>('get',`${this.basePath}/file/get/${encodeURIComponent(String(projectId))}/${encodeURIComponent(String(userId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    /**
     * Returns file list for project
     * 
     * @param projectId project id
     * @param userId user Id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
     public getFileStatusUI(projectId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<FileListStatus>>;
     public getFileStatusUI(projectId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<FileListStatus>>>;
     public getFileStatusUI(projectId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<FileListStatus>>>;
     public getFileStatusUI(projectId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
 
         if (projectId === null || projectId === undefined) {
             throw new Error('Required parameter projectId was null or undefined when calling getFiles.');
         }
 
         let headers = this.defaultHeaders;
 
         // to determine the Accept header
         let httpHeaderAccepts: string[] = [
             'application/json'
         ];
         const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
         if (httpHeaderAcceptSelected != undefined) {
             headers = headers.set('Accept', httpHeaderAcceptSelected);
         }
 
         // to determine the Content-Type header
         const consumes: string[] = [
         ];
 
         return this.httpClient.request<Array<FileUpload>>('get',`${this.basePath}/file/get/${encodeURIComponent(String(projectId))}`,
             {
                 withCredentials: this.configuration.withCredentials,
                 headers: headers,
                 observe: observe,
                 reportProgress: reportProgress
             }
         );
     }
        /**
     * Add file to project&#x27;s file list
     * 
     * @param body updated BIM component progress
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
         public getFileStatus(body: ForgeFileList, observe?: 'body', reportProgress?: boolean): Observable<FileStatus>;
         public getFileStatus(body: ForgeFileList, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FileStatus>>;
         public getFileStatus(body: ForgeFileList, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FileStatus>>;
         public getFileStatus(body: ForgeFileList, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
     
             if (body === null || body === undefined) {
                 throw new Error('Required parameter body was null or undefined when calling getFileStatus.');
             }
     
             let headers = this.defaultHeaders;
     
             // to determine the Accept header
             let httpHeaderAccepts: string[] = [
                 'application/json'
             ];
             const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
             if (httpHeaderAcceptSelected != undefined) {
                 headers = headers.set('Accept', httpHeaderAcceptSelected);
             }
     
             // to determine the Content-Type header
             const consumes: string[] = [
                 'application/json'
             ];
             const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
             if (httpContentTypeSelected != undefined) {
                 headers = headers.set('Content-Type', httpContentTypeSelected);
             }
     
             return this.httpClient.request<ApiResponse>('post',`${this.basePath}/track/filestatus/get`,
                 {
                     body: body,
                     withCredentials: this.configuration.withCredentials,
                     headers: headers,
                     observe: observe,
                     reportProgress: reportProgress
                 }
             );
         }
     

}


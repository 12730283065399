import { ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'ZONTRO-FRONTEND';
  routes = ['/', '/signin', '/forget-password']
  //selectedTask: any;

  constructor(private router: Router,
            private changeDetectorRef: ChangeDetectorRef){}

  // active = 2;
  // passIdToSV(id:any) {
  // this.selectedTask = id
  // this.changeDetectorRef.detectChanges();
  // }
}
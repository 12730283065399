import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms'
import { environment } from '../environments/environment';
import Amplify, { Auth } from "aws-amplify";
import { ApiModule, BASE_PATH } from 'zontro-openapi-frontend';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { TooltipModule } from 'ng2-tooltip-directive';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { NgxFileDropModule } from 'ngx-file-drop';
import { NgbAlertModule, NgbModalModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

//Amplify.configure(awsconfig);
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SigninComponent } from './auth/signin/signin.component';
import { SignupComponent } from './auth/signup/signup.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { DashboardComponent } from './dashboard/dashboard.component';
import { GanttScheduleComponent } from './dashboard/gantt-schedule/gantt-schedule.component';
import { OverallCardsComponent } from './dashboard/overall-cards/overall-cards.component';
import { ParentTreeComponent } from './dashboard/parent-tree/parent-tree.component';
import { ParentOrgChartComponent } from './dashboard/parent-tree/parent-org-chart/parent-org-chart.component';
import { TooltipComponent } from './dashboard/parent-tree/parent-org-chart/tooltip/tooltip.component';
import { DelayChartComponent } from './dashboard/delay-chart/delay-chart.component';
import { DelayTooltipComponent } from './dashboard/delay-chart/delay-tooltip/delay-tooltip.component';
import { SiteViewerComponent } from './site-viewer/site-viewer.component';
import { ControlsComponent } from './site-viewer/controls/controls.component';
import { LeftPaneComponent } from './site-viewer/left-pane/left-pane.component';
import { BimViewComponent } from './site-viewer/bim-view/bim-view.component';
import { FloorMapComponent } from './site-viewer/floor-map/floor-map.component';
import { ForgeComponent } from './site-viewer/forge/forge.component';
import { SunburstComponent } from './dashboard/sunburst/sunburst.component';
import { DelaySunburstComponent } from './dashboard/delay-sunburst/delay-sunburst.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SiteViewComponent } from './site-view/site-view.component';
import { PanoComponent } from './site-view/pano/pano.component';
import { BimComponent } from './site-view/bim/bim.component';
import { AreaComponent } from './site-view/area/area.component';
import { MapComponent } from './site-view/map/map.component';
import { OverviewComponent } from './overview/overview.component';
import { SetupComponent } from './overview/setup/setup.component';
import { MainUiComponent } from './main-ui/main-ui.component';
import { PreviewComponent } from './preview/preview.component';
import { SchedulePreviewComponent } from './preview/schedule-preview/schedule-preview.component';
import { BimPreviewComponent } from './bim-preview/bim-preview.component';
import { AreaBimPreviewComponent } from './bim-preview/area-bim-preview/area-bim-preview.component';
import { ForgeBimPreviewComponent } from './bim-preview/forge-bim-preview/forge-bim-preview.component';

Amplify.configure({
    Auth: {
        region: environment.awsRegion,
        userPoolId: environment.userPoolId,
        userPoolWebClientId: environment.userPoolWebClientId,
        mandatorySignIn: true,
        authenticationFlowType: environment.authenticationFlowType,

        // oauth: {
        //     domain: 'your_cognito_domain',
        //     scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
        //     redirectSignIn: 'http://localhost:3000/',
        //     redirectSignOut: 'http://localhost:3000/',
        //     responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
        // }
    }
});

// You can get the current config object
const currentConfig = Auth.configure();
@NgModule({
  declarations: [
    AppComponent,
    SigninComponent,
    SignupComponent,
    ForgotPasswordComponent,
    DashboardComponent,
    GanttScheduleComponent,
    OverallCardsComponent,
    ParentTreeComponent,
    ParentOrgChartComponent,
    TooltipComponent,
    DelayChartComponent,
    DelayTooltipComponent,
    SiteViewerComponent,
    ControlsComponent,
    LeftPaneComponent,
    BimViewComponent,
    FloorMapComponent,
    ForgeComponent,
    SunburstComponent,
    DelaySunburstComponent,
    SiteViewComponent,
    PanoComponent,
    BimComponent,
    AreaComponent,
    MapComponent,
    OverviewComponent,
    SetupComponent,
    MainUiComponent,
    PreviewComponent,
    SchedulePreviewComponent,
    BimPreviewComponent,
    AreaBimPreviewComponent,
    ForgeBimPreviewComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgxChartsModule,
    ApiModule,
    TooltipModule,
    LeafletModule, 
    OwlDateTimeModule, 
    OwlNativeDateTimeModule, 
    NgbModule,
    NgbAlertModule, 
    NgbModalModule,
    NgxFileDropModule
  ],
  providers: [{
    provide: BASE_PATH, useValue: environment.basePath
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true
  },
  NgbActiveModal],
  bootstrap: [AppComponent]
})
export class AppModule { }

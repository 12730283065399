<div class="card">
    <div class="card-body">
        <div class="row">{{tooltipData.text}}</div>
        <div class="row">

            <div class="col" style="padding-top: 5%;">
                <p style="font-size: 10px; color: rgb(44, 44, 44);">Hierarchy: 
                    <a style="color: #64b5f6; font-size: 10px;">{{parentTree[3]}} -> {{parentTree[2]}} -> {{parentTree[1]}} -> {{parentTree[0]}}</a></p> 
                <p style="font-size: 10px; color: rgb(44, 44, 44);">Actual Progress: <a style="color: #64b5f6; font-size: 10px;">{{tooltipData.actualP}}%</a></p> 
                <p style="font-size: 10px; color: rgb(44, 44, 44);">Planned Progress: <a style="color: #64b5f6; font-size: 10px;">{{tooltipData.plannedP}}%</a></p> 
            </div>
        </div>
    </div>
</div>
<meta name="viewport" content="width=device-width, height=device-height, initial-scale=1.0">
<div id = "panoScan">
    <div id="controls">
        <div class="row">
            <div class="col" >
                <div class="ctrl" id="next-img"
                [tooltip]="$any(HtmlContent1)"
                theme='light'
                tooltipClass='tooltp'
                contentType="template"
                placement="right">
                    <button class="btn btn-light">
                        <i class="far fa-image"></i><i class="fas fa-angle-right"></i>    
                    </button>
                </div>
            </div>
            <div class="col">
                <div class="ctrl" id="prev-img"
                [tooltip]="$any(HtmlContent2)"
                theme='light'
                tooltipClass='tooltp'
                contentType="template"
                placement="right"
                >
                    <button class="btn btn-light" >
                        <i class="fas fa-angle-left"></i><i class="far fa-image"></i>
                    </button>
                </div>  
            </div>
        </div>
    </div>
</div>

<ng-template #HtmlContent1>
    Next Image
</ng-template>
<ng-template #HtmlContent2>
    Previous Image
</ng-template>
<!-- <div id="lock" *ngIf="bim">
    <button class="btn btn-light" style="opacity: 0.8;float: left;">
        <i class="fa fa-lock fa-lg" aria-hidden="true" ></i>
    </button>
</div> -->

<!-- <img id="myimage" [src]='thumbnail' /> -->

import { ChangeDetectorRef, Component, Input, OnInit, SimpleChanges } from '@angular/core';
declare var pannellum: any;

@Component({
  selector: 'app-bim-view',
  templateUrl: './bim-view.component.html',
  styleUrls: ['./bim-view.component.css']
})
export class BimViewComponent implements OnInit {
  w: any
  basepath: string = "assets/20211027_BIM/13_49_51/"
  bim: boolean = true;
  @Input() scan: any;
  @Input() panoCount: any;
  @Input() viewS: any
  @Input() hfovS: any
  //@Input() setLock: any
  @Input() newScene: any
  @Input() bimScene: any
  @Input() newtaskScene: any
  @Input() currentScene: any
  yaw: number = 0;
  sceneName: string = "";
  constructor( private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.createBimViewer()
    //console.log(this.currentScene)
    if(!this.newScene) {
      //console.log(this.currentScene)
      this.w.loadScene(this.currentScene,10,this.yaw,120) 
      
    }
    else {
      this.w.loadScene(this.newScene,10,this.yaw,120)
      //console.log(this.newScene,this.currentScene)
      if(this.newScene!==this.currentScene) {
       // console.log(this.newScene,this.currentScene)
        this.w.loadScene(this.currentScene,10,this.yaw,120)
      }
    }  
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes['panoCount']!==undefined && this.panoCount){
      let id = changes['panoCount'].currentValue;
      this.panoCount=id
    }
    if(changes['scan']!==undefined && this.scan){
      let id = changes['scan'].currentValue;
      this.scan=id
      if(this.scan && this.scan=='unit6floor2') {
        this.basepath = "assets/20211027_BIM/14_40_25/unit6L2_"
        this.sceneName = "Unit6-L2_"
        this.yaw = -90
      } else {
        this.basepath = "assets/20211027_BIM/13_49_51/unit6L1_"
        this.yaw = 90
        this.sceneName = "Unit6-L1_"
      }
      this.createBimViewer();
      this.w.loadScene(1,10,this.yaw,120)
    }
    // if(changes['setLock']!==undefined && this.setLock){
    //   let set = changes['setLock'].currentValue;
    //   this.setLock = set
    // } 
    if(changes['viewS']!==undefined && this.viewS){
      let view = changes['viewS'].currentValue;
      //  if(this.setLock){
        //console.log(view);
        this.w.setPitch(view.pitch);
        this.w.setYaw(view.yaw+this.yaw);
      //  }
    }
    if(changes['hfovS']!==undefined && this.hfovS){
      let newHfov = changes['hfovS'].currentValue;
      //  if(this.setLock){
        //console.log(this.viewer2.getHfov());
        this.w.setHfov(newHfov);
      //  }
    }
    if(changes['newScene']!==undefined && this.newScene){
      
      let newValue = changes['newScene'].currentValue;
      // if(this.setLock){
          if(newValue==this.w.getScene()) {}
          else this.w.loadScene(newValue,10,this.yaw,120)
      // }
    }
    if(changes['newtaskScene']!==undefined && this.newtaskScene){
      let newValue = changes['newtaskScene'].currentValue;
      if(newValue==this.w.getScene()) {}
      else this.w.loadScene(newValue,10,0,120)
    }
    if(changes['currentScene']!==undefined && this.currentScene){
      let newValue = changes['currentScene'].currentValue;
      // if(this.setLock){
        if(newValue==this.w.getScene()) {}
        else this.w.loadScene(newValue,10,this.yaw,120)
      // }
    }
    if(changes['bimScene']!==undefined && this.bimScene){
      let newValue = changes['bimScene'].currentValue;
      // if(this.setLock){
        if(newValue==this.w.getScene()) {}
        else this.w.loadScene(newValue,10,this.yaw,120)
      // }
    }

  }
  openBim(){
    if(this.bim) {
      this.bim = !this.bim;
    }  else {
      this.bim = !this.bim;
    }
  }
  // nextImage(event: any){
  //   if(this.w.getScene()==1){
  //     this.w.loadScene(2,10,this.yaw,120)
  //   } else if(this.w.getScene()==(this.panoCount)){
  //   } else {
  //     var scene = this.w.getScene()+1
  //     this.w.loadScene(scene,10,this.yaw,120)
  //   }
    
  // }
  // prevImage(event: any){
  //   if(this.w.getScene()==1){
  //   } else if(this.w.getScene()==this.panoCount){
  //     this.w.loadScene(this.panoCount-1,10,this.yaw,120)
  //   } else {
  //   var scene = this.w.getScene()-1
  //   this.w.loadScene(scene,10,this.yaw,120)
  //   }
  // }

  createBimViewer(){
    this.w = pannellum.viewer('panoBim', { 
      "default": {
        "firstScene": "1",
        // "author": "Zontro1",
        "sceneFadeDuration": 1000,
        "autoLoad": true,
        "draggable": false
      }, 
      "scenes": {
        "1": {
          "title": this.sceneName+"1",
          "hfov": 120,
          "pitch": -10,
          "yaw": this.yaw,
          // "yaw": -90,unit6f2
          "type": "equirectangular",
          "panorama": this.basepath+'1.png',
        },
      }
    });
    // this.w.addHotSpot({
    //   "pitch": -10,
    //   "yaw": 0,
    //   "type": "scene",
    //   "text": "next Image",
    //   "sceneId": 2
    // },1);
    var ext = '.png' 
    for (var i=1; i<this.panoCount; i++) {
      var sceneId = i+1;
        this.w.addScene(sceneId,{
          "sceneFadeDuration": 1000,
          "title": this.sceneName+sceneId,
          "hfov": 120,
          "pitch": -10,
          "autoload": true,
          "yaw": this.yaw,
          "type": "equirectangular",
          "panorama": this.basepath+sceneId+ext,
        });
    }
    // this.w.on('mouseup', this.onMouseUp.bind(this));
    // (<HTMLInputElement>document.getElementById("nxt-img")).addEventListener('click', this.nextImage.bind(this));
    // (<HTMLInputElement>document.getElementById("prv-img")).addEventListener('click', this.prevImage.bind(this));
  }
  // onMouseUp(event: any){
  //   this.changeDetectorRef.detectChanges();
  // };
}

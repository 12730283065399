import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { PanoService } from 'zontro-openapi-frontend';
declare var pannellum: any;

@Component({
  selector: 'app-left-pane',
  templateUrl: './left-pane.component.html',
  styleUrls: ['./left-pane.component.css']
})
export class LeftPaneComponent implements OnInit {
  @Input() scan: any
  date = '2021-12-25T06:10:20Z'
  projectId: any ='Aerospace_REV3'
  v: any;
  @Input() setLock: any;
  @Input() large:any
  @Input() newScene: any
  @Input() closestPano: any
  mapmin: boolean = true;
  bim: boolean = true;
  thumbnail: any;
  count: number = 0;
  @Output() panoCount = new EventEmitter<any>()
  @Output() currentScene = new EventEmitter<any>()
  @Output() yawPitchScan = new EventEmitter<any>()
  @Output() hfovScan = new EventEmitter<any>()
  @Output() forgePos = new EventEmitter<any>()
  panoLength: number = 0;
  markers: any =[];
  subscription: any;
  panoList: any
  data: any
  closestScene: number = 1;
  taskHighlight: boolean = true
  closestDone: boolean = false;
  
  constructor(private panoService: PanoService, 
              private changeDetectorRef: ChangeDetectorRef
              ) { }

  ngOnInit() {
  }
  ngOnChanges(changes: SimpleChanges) {
    
    if(changes['closestPano']!==undefined && this.closestPano){
      let newValue = changes['closestPano'].currentValue;
      this.closestPano = newValue
      setTimeout(() => {this.scan.offsetYaw = this.closestPano.offsetYaw}, 500)
      // try {
      //   this.scan.offsetYaw = this.closestPano.offsetYaw
      // } catch(err) {
      //   console.log(err)
      //  // window.location.reload()
      // }
      
      this.panoService.getPanobyArea(this.projectId,newValue.scanName).subscribe(
      result => {
        this.data = result
        //this.createViewer1(result)
        this.panoLength = result.length
        this.panoCount.emit(result.length)
        this.forgePos.emit(result[0])
        this.panoList = result
        var path1 = this.closestPano.scanPanoPath.substring(23)
        this.v = pannellum.viewer('panoScan', { 
          "default": {
            "firstScene": "1",
            "sceneFadeDuration": 1000,
            "autoLoad": true,
            "compass": false,
            "showControls": false,
            "keyboardZoom": false,
           // "mouseZoom": false,
          }, "scenes": {
            "1": {
              "title": "Scene 1",
              "hfov": 120,
              "pitch": 10,
              "yaw": this.closestPano.offsetYaw,
              "type": "equirectangular",
              "panorama": 'assets/'+path1+'/'+this.data[0].scan_pano_name,
            }
          }  
        });
        for (var i=1; i<result.length; i++) { 
          if(this.closestPano.panoId==this.data[i].scan_pano_name) {
            this.closestScene = i+1
          }
          var path1 = this.closestPano.scanPanoPath.substring(23)
          var sceneId = i+1;
          this.v.addScene(sceneId,{
            "sceneFadeDuration": 1000,
            "title": "Scene "+sceneId,
            "hfov": 120,
            "pitch": -10,
            "autoload": true,
            "yaw": this.closestPano.offsetYaw,
            "type": "equirectangular",
            "panorama": 'assets/'+path1+'/'+this.data[i].scan_pano_name,
          });
        }
        (<HTMLInputElement>document.getElementById("next-img")).addEventListener('click', this.nextImage.bind(this));
        (<HTMLInputElement>document.getElementById("prev-img")).addEventListener('click', this.prevImage.bind(this));
        this.v.on('scenechange', this.sceneChangeListener.bind(this));
        this.v.on('mouseup', this.onMouseUp.bind(this));
        this.v.on('zoomchange', this.onZoomChange.bind(this));
        setTimeout(() => {this.v.loadScene(this.closestScene,10,this.closestPano.offsetYaw,120)}, 2000)
        this.closestDone=true
      })
    } else if(changes['scan']!==undefined && this.scan){
      if(!changes["scan"].isFirstChange()){
        if(this.closestDone==true){
          let id = changes['scan'].currentValue;
          this.scan=id
          this.subscription = this.panoService.getPanobyArea(this.projectId,id.scanName).subscribe(
            result => {
              this.createViewer(result)
              this.panoLength = result.length
              this.panoCount.emit(result.length)
              this.forgePos.emit(result[0])
              this.panoList = result
          })
        }
      }
    }
    if(changes['large']!==undefined){
      if(!changes["large"].isFirstChange()){
      let newValue = changes['large'].currentValue;
      //if(newValue==false) {
      this.v.resize();
      
     // }
      // if(newValue==true) {
      //   (<HTMLInputElement>document.getElementById("panoScan")).style.width = '100%';
      //   if(this.count>=1){
      //   this.v.resize();
      //   }
      // }  else  {
        
      //   (<HTMLInputElement>document.getElementById("panoScan")).style.width = '50%';
      //   this.v.resize();
      // }
      }
    }
    if(changes['setLock']!==undefined && this.setLock){
      let newValue = changes['setLock'].currentValue;
      this.setLock = newValue
      // if(this.setLock==false) {
      //   this.v = pannellum.viewer('panoScan', { 
      //     "default": {
      //       "firstScene": "1",
      //       "sceneFadeDuration": 1000,
      //       "autoLoad": true,
      //       "compass": false,
      //       "showControls": true,
      //       "keyboardZoom": true,
      //       "mouseZoom": true,
      //     }
      //   })
      // }

    }
    if(changes['newScene']!==undefined && this.newScene){
      let newValue = changes['newScene'].currentValue;
      if(newValue==this.v.getScene()) {}
      else {
        if(this.closestDone==true){ 
          this.v.loadScene(newValue,10,this.scan.offsetYaw,120)
          this.newScene=newValue
        }
      }
    } 
  }
  createViewer(data:any){
    var path1 = this.scan.scanPanoPath.substring(23)
    this.v = pannellum.viewer('panoScan', { 
      "default": {
        "firstScene": "1",
        "sceneFadeDuration": 1000,
        "autoLoad": true,
        "compass": false,
        "showControls": false,
        "keyboardZoom": false,
        //"mouseZoom": false,
        "showZoomCtrl": false,

      }, "scenes": {
        "1": {
          "title": "Scene 1",
          "hfov": 120,
          "pitch": 10,
         // "yaw": 0,
          "yaw": this.scan.offsetYaw,
          "type": "equirectangular",
          "panorama": 'assets/'+path1+'/'+data[0].scan_pano_name,
        }
      }  
    });
    
    for (var i=1; i<data.length; i++) { 
      var path1 = this.scan.scanPanoPath.substring(23)
      var sceneId = i+1;
      this.v.addScene(sceneId,{
        "sceneFadeDuration": 1000,
        "title": "Scene "+sceneId,
        "hfov": 120,
        "pitch": -10,
        "autoload": true,
        //"yaw": 0,
        "yaw": this.scan.offsetYaw,
        "type": "equirectangular",
        "panorama": 'assets/'+path1+'/'+data[i].scan_pano_name,
      });
    }
    (<HTMLInputElement>document.getElementById("next-img")).addEventListener('click', this.nextImage.bind(this));
    (<HTMLInputElement>document.getElementById("prev-img")).addEventListener('click', this.prevImage.bind(this));
    this.v.on('scenechange', this.sceneChangeListener.bind(this));
    this.v.on('mouseup', this.onMouseUp.bind(this));
    this.v.on('zoomchange', this.onZoomChange.bind(this));
  }
  
  nextImage(event: any){
    if(this.v.getScene()==1){
      this.v.loadScene(2,10,this.scan.offsetYaw,120)
    } else if(this.v.getScene()==(this.panoLength)){
    } else {
      var scene = this.v.getScene()+1
      this.v.loadScene(scene,10,this.scan.offsetYaw,120)
    }
    
  }
  prevImage(event: any){
    if(this.v.getScene()==1){
    } else if(this.v.getScene()==this.panoLength){
      this.v.loadScene(this.panoLength-1,10,this.scan.offsetYaw,120)
    } else {
    var scene = this.v.getScene()-1
    this.v.loadScene(scene,10,this.scan.offsetYaw,120)
    }
  }
  sceneChangeListener(event: any) {
    if(this.newScene==event){}
    else {
      if(this.closestDone==true){
        this.currentScene.emit(event);
      } else {
        setTimeout(() => {this.currentScene.emit(event)}, 3000);
      }
    }
    //for(var i=0;i<this.panoList.length;i++){

      this.forgePos.emit(this.panoList[event-1])
    //}
   
    
  };
  onMouseUp(event: any){
    var direction = {yaw: this.panoList[this.v.getScene()-1].rotation-(this.v.getYaw()-this.scan.offsetYaw),pitch: this.v.getPitch()}
    this.yawPitchScan.emit(direction)
    this.changeDetectorRef.detectChanges();
  };
  onZoomChange(event: any){
    this.changeDetectorRef.detectChanges();
    this.hfovScan.emit(event)
  };
  // ngOnDestroy() {
  //   this.subscription.unsubscribe()
  // }
}

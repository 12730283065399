import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { CRS, map, latLng, latLngBounds, imageOverlay,icon, Marker } from 'leaflet'
import { Subscription } from 'rxjs';
import { AreaService, PanoService } from 'zontro-openapi-frontend';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit {
  @Input() scan: any;
  @Input() panoLocs: any
  @Input() panoScene: any

  @Output() selectedMarkerFloor = new EventEmitter<any>()
  private map: any
  rotMark: any;
  subscription!: Subscription;
  area: any
  isfloorChange: boolean = false;
  minimized: boolean = true;
  private initMap(){
    this.map = map('map', {
      crs:CRS.Simple,
      center: latLng(500, 300),
      minZoom: 0,
      maxZoom: 14,
      zoomControl: false,
      scrollWheelZoom: true
    })
  }
  width: any = 100
  prevIdx: number = -1;
  onInitDone: number = 0
  imageUrl: any;
  boundArr: any;
  bounds: any;
  layer: any;
  markerArr: any =[]
  markers: Marker[] = []
  iconNormal = icon({
    iconSize: [ 25, 25 ],
    iconAnchor: [ 13, 30 ],
    iconUrl: 'assets/images/squareBlue.png'
  })
  iconlarge = icon({
    iconSize: [ 32, 30 ],
    iconAnchor: [ 20, 30 ],
    iconUrl: 'assets/images/squareRed.png'
  })
  projectId: any = "Aerospace_REV3"


  constructor(private areaService: AreaService,
              private panoService: PanoService) { }

  ngOnInit() {
     this.initMap();
  }
  ngOnChanges(changes: SimpleChanges) {


    if(changes['scan']!==undefined && this.scan){
      if(!changes["scan"].isFirstChange()){
          let newScan = changes['scan'].currentValue;
          this.scan=newScan
          this.subscription = this.areaService.getAreaById(this.projectId,this.scan.areaId).subscribe(
            result => {
              this.isfloorChange = true
              this.area=result
              this.boundArr = this.area.floorPlanBounds.split(', ')
              this.imageUrl = 'assets/images/'+this.area.floorPlanPath;;
              this.bounds = latLngBounds([[0,0],[this.boundArr[2],this.boundArr[3]]])
              this.onInitDone = 2
              this.loadFloorPlan(this.area)
            })
      }
    } else if(changes['panoScene']!==undefined && this.panoScene){
      let scene = changes['panoScene'].currentValue;
      this.panoScene=scene;
      this.openPano(scene) 
    }
    if(changes['panoLocs']!==undefined && this.panoLocs){
          let newLocs = changes['panoLocs'].currentValue;
          this.panoLocs=newLocs
          for(var i=0; i<this.markers.length; i++) {
            this.map.removeLayer(this.markers[i])
          }
          this.markers = []
          this.markerArr=[]
          for (var i=0; i<this.panoLocs.length; i++) { 
            var marker = {id: i+1, lat: this.panoLocs[i].transX, lng: this.panoLocs[i].transY, z:this.panoLocs[i].transY}
            this.markerArr.push(marker);
          }
          if (this.onInitDone=1) {
            setTimeout(() => {this.setMarkers()}, 2000)
          }else {
            //this.setMarkers();
          }
    }
  }

   loadFloorPlan(area: any){
    this.panoService.getPanobyArea(this.area.projectId,this.scan.scanName).subscribe(
      result => {
        for (var i=0; i<result.length; i++) { 
          var marker = {id: i+1, lat: result[i].transX, lng: result[i].transY, z:result[i].transY}
          this.markerArr.push(marker);
        }
        //this.setMarkers();
        this.onInitDone=1
      })

      //if(this.onInitDone>=1){
       // this.onInitDone=2
        this.imageUrl='assets/images/'+area.floorPlanPath;
        var bnd = area.floorPlanBounds.split(', ')
        this.boundArr = bnd
        this.bounds = latLngBounds([[0,0],[this.boundArr[2],this.boundArr[3]]])
        this.onFloorChange() 
      //} else {}
  }
  setMarkers() {
    //console.log(this.markers)
    this.markers = this.markerArr.map((camera: any) => new Marker(
            { lat: camera.lng, lng: camera.lat },{icon: this.iconNormal})
            .on('click', function (e){ openPano(camera)})
            .addTo(this.map)
    );
    this.rotMark = this.markers[0].setIcon(this.iconlarge);
    this.prevIdx = 0
    this.map.setView([this.markerArr[0].lng, this.markerArr[0].lat], 2,{
      "animate": true,
      "pan": {
        "duration": 2
      }
    });
    const openPano = (id:any) =>{        
      if (id.id < 0) return;
      else {
        if(this.prevIdx>-1) {
          this.markers[this.prevIdx].setIcon(this.iconNormal);
        } else {
          this.rotMark.setIcon(this.iconNormal)}
          this.rotMark = this.markers[id.id-1].setIcon(this.iconlarge);
          this.prevIdx=id.id-1;
            this.selectedMarkerFloor.emit(id.id)
      }
      this.map.setView([id.lng, id.lat], 2,{
        "animate": true,
        "pan": {
          "duration": 2
        }
      });
    };
  }
  onFloorChange(){
    for(var i=0; i<this.markers.length; i++) {
      this.map.removeLayer(this.markers[i])
    }
    this.markerArr=[]
    this.map.remove();
    this.initMap();
    //this.layer.remove();
    this.createMap()
  }

  createMap(){
    this.layer = imageOverlay(this.imageUrl, this.bounds).addTo(this.map);
    var minmaxbounds = latLngBounds([[this.boundArr[0],this.boundArr[1]], [this.boundArr[2],this.boundArr[3]]]);
    this.map.setMaxBounds(minmaxbounds); 
    this.map.fitBounds(this.bounds);
    this.map.setZoom(1)
    $('.leaflet-control-attribution').hide()
    
  }
  openPano(id: number) {
    if (id < 2) return;
    else {
      if(this.prevIdx>-1) {
        this.markers[this.prevIdx].setIcon(this.iconNormal);
      } else {this.rotMark.setIcon(this.iconNormal)}
      this.markers[id-1].setIcon(this.iconlarge);
      this.prevIdx=id-1
      this.map.setView([this.markerArr[id-1].lng, this.markerArr[id-1].lat], 2,{
        "animate": true,
        "pan": {
          "duration": 2
        }
      });
    }
  }
  minimizeMap() {
    this.minimized = !this.minimized
    if(this.minimized==true) {
      this.width = 100
    } else {
      this.width = 400
    }

  }
  ngOnDestroy() {
    this.subscription.unsubscribe()
  }
}

import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { id } from '@swimlane/ngx-charts';
import { ChartService } from 'src/app/services/chart.service';
import { TaskService } from 'zontro-openapi-frontend';

@Component({
  selector: 'app-parent-tree',
  templateUrl: './parent-tree.component.html',
  styleUrls: ['./parent-tree.component.css']
})
export class ParentTreeComponent implements OnInit {
  //@Input() delayArr: any
  @Input() projectTaskId:any
  projectId: any 
  plan: any =[];
  selectedParent!: number;
  selectedTask!: any
  orgChart: boolean = false;
  @Output() idForGantt = new EventEmitter<any>()
  //idForGantt: any;
  constructor(private taskService: TaskService, 
              private chartService: ChartService,
              private route: ActivatedRoute, 
              private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit() {
    const routeParams = this.route.snapshot.paramMap
    const projectId = routeParams.get('projectId');
    this.projectId = projectId
  }
  ngOnChanges(changes: SimpleChanges) {
    if(changes['projectTaskId']!==undefined && this.projectTaskId){
      let id = changes['projectTaskId'].currentValue;
      var date = new Date().toISOString()
      this.taskService.getChild(this.projectId,date,id).subscribe(
      result => {
        //this.plan = this.chartService(result[0].planned_end, result[0].planned_start)
        this.createChartData(result)
       // this.dataArr = result
      })
    }
  }
  createChartData(data: any){
    for(var i=0;i<data.length; i++){
      var plan = this.chartService.plannedProgress(data[i].planned_end, data[i].planned_start)
      var data1={
        sl:i+1,
        id:data[i].id,
        text:data[i].text,
        actual:Math.round(data[i].progress*100),
        planned:plan,
        delay:Math.round(plan-data[i].progress*100)
      }
      this.plan.push(data1)
    }
  }
  onSelected(task:any){
    this.selectedParent = task
    this.changeDetectorRef.detectChanges();
    this.orgChart = true
    this.selectedTask = task
    this.idForGantt.emit(task.id)
  }
  onBack(){
    this.orgChart = false
  }
  passIdForGantt(id: any){
    this.idForGantt.emit(id)
    this.changeDetectorRef.detectChanges();
  }

}

import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { AreaService, ScanService } from 'zontro-openapi-frontend';

@Component({
  selector: 'app-area',
  templateUrl: './area.component.html',
  styleUrls: ['./area.component.css']
})
export class AreaComponent implements OnInit {
  areaList: any
  projectId: string = 'Aerospace_REV3'
  date = '2022-03-18T06:10:20Z'
  scans: any
  @Output() selectedScan = new EventEmitter<any>()
  @Input() taskArea: any
  select: any;

  constructor(private areaService: AreaService,
              private scanService: ScanService) { }

  ngOnInit(){

    this.areaService.getAreaDetails(this.projectId).subscribe(
      result1 => {
        this.areaList=result1
        this.selectArea(this.areaList[0])
      }
    )
   // setTimeout(() => {this.selectArea(this.areaList[0])}, 2000)
  }

  selectArea(area: any) {
    this.select = area.areaId;
    this.scanService.getScan(this.projectId,this.date,area.areaId).subscribe(
      result => {
        this.scans = result
        for(var i=0;i<result.length;i++){
          this.scans[i].scanDate = this.scans[i].scanDate.slice(0,10) 
        }
        this.selectedScan.emit(this.scans[0])
      }
    )
  }
  ngOnChanges(changes: SimpleChanges) { 
    if(changes['taskArea']!==undefined && this.taskArea){
      let newValue = changes['taskArea'].currentValue;
      this.select = newValue.areaId;
      
    }
  }
}

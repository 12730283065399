<nav class="navbar navbar-expand-lg pb-4  navbar-light bg-light pt-4 mb-4 shadow" style="height: 3% !important;">
    <div class="container-fluid">
      <a class="navbar-brand" href="#">
        <img src="../../assets/images/logo.png" alt="" width="30" height="30" class="d-inline-block align-text-top">
        <a style="color: #4E91DA; font-weight: 650;">    Zontro</a>    
        <a class="navbar-brand" href="#" style="color: rgb(71, 71, 71); font-size: smaller; padding-left: 20%;">
            Project: JTC Aerospace 
        </a>   
      </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
        <ul class="navbar-nav justify-content-center" style="padding-left: 50%!important;" >
            <li class="nav-item" >
                <a class="nav-link active"  aria-current="page" href="site-viewer/392">Site View</a>
            </li>
            <li class="nav-item">
              <a class="nav-link"  aria-current="page"  href="dashboard"  target='_blank'  >DashBoard</a>
            </li>
          </ul>
      </div>
    </div>
  </nav>


<div class="container-fluid">
    <div class="row">
        <!-- <div class="col-sm-auto bg-light sticky-top">
            <div class="d-flex flex-sm-column flex-row flex-nowrap bg-light align-items-center sticky-top">
                <ul class="nav nav-pills nav-flush flex-sm-column flex-row flex-nowrap mb-auto mx-auto text-center align-items-center">
                    <li class="nav-item mt-5">
                        <a (click) = "showArea()" class="nav-link py-3 px-2" title="" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-original-title="Home"
                        (click)="openArea()">
                            <i class="fas fa-layer-group"></i>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="nav-link py-3 px-2" title="" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-original-title="Dashboard">
                            <i class="fas fa-exclamation-triangle"></i>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="nav-link py-3 px-2" title="" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-original-title="Orders">
                            <i class="far fa-file-alt"></i>
                        </a>
                    </li>
                </ul>
            </div>
        </div> -->
        <div class="col-sm v min-vh-100 "  >
            <div class="container-fluid mt-1 p-0">
                <div class="row mr-0">
                    <div class="col-sm-1">
                        <!-- <app-controls></app-controls> -->
                        <app-controls *ngIf ="!areamin"
                        (selectedScan)="passScan($event)"
                        (urn)="passUrn($event)"
                        [closestPano]="closestPano">
                    </app-controls> 
                    </div>
                    <!-- <div class="col-1 area" *ngIf="showAreaList">
                        <app-controls
                        (selectedScan)="passScan($event)"></app-controls> 
                    </div> -->
                    <!-- <div class="col ">
                        <div class="row"> -->
                            <div class="col p-0 m-0">
                                <div class="leftPane ">
                                    <app-left-pane class="mr-0 pr-0"
                                        [large]="large"
                                        [scan] = "scan_name"
                                        [newScene] = "newScene"
                                        [closestPano]="closestPano"
                                        (panoCount)=passPanoCount($event)
                                        (currentScene) = "passSceneId($event)"
                                        (yawPitchScan) = onViewChange($event) 
                                        (hfovScan) = onZoomChange($event) 
                                        (forgePos) = "passForgePos($event)"
                                        [setLock] = "setLock">
                                    </app-left-pane>
                                </div>
                            </div>
                            <div class="col p-0 m-0" *ngIf="!large">
                                <div class="rightPane ">
                                    <app-forge 
                                    [area] = scan_name
                                    [areaUrn] = "urn"
                                    [closestUrn] = "closestUrn"
                                    [panoPos] = "forgePos"
                                    [selectedTask] = "selectedTask"
                                    [panoYaw] = "viewS"
                                    [setLock] = "setLock"
                                    [hfovS] = "hfovS"
                                    (devPopup) = "showDevPopup($event)"
                                    (modelLoaded) = "removeLoader($event)"
                                    [showDeviation] = "showDeviation"
                                    ></app-forge>
                                    <div id="lock" *ngIf="showDeviation"> 
                                        <div class="card text-center">
                                            <div class="card-header" >
                                                <div class="row">
                                                    <div class="col" style="text-align:left !important;">Unit 3: RC Columns/Wall to Level 2 [ID: 1734]</div>
                                                    <div class="col" style="text-align:right !important;">
                                                        <button type="button" class="btn-close" aria-label="Close" (click)="closeDeviation()"></button>
                                                    </div>
                                                </div>                                              
                                            </div>
                                            <div class="card-body">
                                              <h5 class="card-title">{{deviationDetails.bimId}}</h5>
                                              <p class="card-text">{{deviationDetails.text}}</p>
                                              <img src="assets/images/{{deviationDetails.img}}"  class="card-img-top" alt="...">
                                            </div>
                                            <div class="card-footer text-muted">
                                              Detected on 2022-01-19
                                            </div>
                                          </div>                                   
                                    <!-- <div class="col"                 
                                    [tooltip]="$any(HtmlContent1)"
                                    theme='light'
                                    tooltipClass='tooltp'
                                    contentType="template"
                                    placement="left">
                                        <button class="btn btn-light" style="float: left; " *ngIf ="!setLock" (click)="viewLocked()">
                                            <i class="fa fa-lock fa-lg" aria-hidden="true" ></i>
                                        </button>
                                        <button class="btn btn-light " style="float: left; " *ngIf ="setLock" (click)="viewLocked()">
                                            <span tooltip="Tooltip" placement="top"></span>
                                            <i class="fa fa-lock fa-lg" aria-hidden="true" style="color: #4E91DA;"></i>
                                        </button>
                                    </div> -->
                                    </div>
                                    <div id="loader" *ngIf="showLoader"> 
                                        <div id="loader-card" class="center">
                                        <div class="card text-center" >
                                            <div class="card-header" >
                                                <div class="row">
                                                    <div class="col" style="text-align:left !important;">Please wait while the BIM model is loaded..</div>
                                                </div>                                              
                                            </div>
                                            <div class="card-body">
                                                <div class="d-flex align-items-center">
                                                    <strong>Loading...</strong>
                                                    <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div> 
                                </div>
                            </div> 

      
                        <!-- </div>        
                    </div> -->
                </div>
                <div id="controls">
                    <div class = "row">
                        <div class="col">
                        </div>
                        <div class="col"                 
                        [tooltip]="$any(HtmlContent1)"
                        theme='light'
                        tooltipClass='tooltp'
                        contentType="template"
                        placement="left">
                            <button class="btn btn-light" style="float: left; " *ngIf ="large" (click)="doResize()">
                                <i class="fa fa-columns" aria-hidden="true" ></i>
                            </button>
                            <button class="btn btn-light " style="float: left; " *ngIf ="!large" (click)="doResize()">
                                <span tooltip="Tooltip" placement="top"></span>
                                <i class="fa fa-columns" aria-hidden="true" style="color: #4E91DA;"></i>
                            </button>
                        </div>
                    </div>
                </div>   
            </div>
            <div id = "floor">
                <app-floor-map *ngIf ="!mapmin && isArea "
                [closestPano]="closestPano"
                [currentScene]="currentScene"
                [scan] = "scan_name"
                (selectedMarkerFloor) = "posScanBim($event)"
                [selectedArea]="selectedArea"
                ></app-floor-map>
            </div>
            <ng-template #HtmlContent1>
                Split View - BIM
            </ng-template>
            <ng-template #HtmlContent2>
                Select Floor
            </ng-template>
            
        </div>
     </div>
</div> 







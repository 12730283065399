import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  errorMessage: string = '';
  email: string = ''
  password: string = ''
  c1!: string; 
  c2!: string; 
  c3!: string; 
  c4!: string; 
  c5!: string; 
  c6!: string; 
  codeSent: boolean =false;
  data: any;
  toVerify: any;
  code: string = '';
  msg: boolean = false;
  constructor(private authService: AuthService) { }

  ngOnInit(): void {
  }
  async onSendCode() {
    try {
      this.data = await this.authService.sendCode(this.email)
      this.toVerify=this.data.CodeDeliveryDetails.Destination
      this.codeSent=true
    } catch(error:any){
      console.log(error)
      this.errorMessage = error
    }
  }
  async onReset() {
    this.code = this.c5.concat(this.c6.toString())
    this.code = this.c4.concat(this.code.toString())
    this.code = this.c3.concat(this.code.toString())
    this.code = this.c2.concat(this.code.toString())
    this.code = this.c1.concat(this.code.toString())
    try {
      this.data = await this.authService.resetPwd(this.email, this.code, this.password)
      this.msg = true
    } catch(error:any){
      console.log(error)
      this.errorMessage = error
    }
  }
  onVerifySuccess(){
    this.authService.verifySuccess()
  }
}

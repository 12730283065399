<div class=" p-0 m-0">
    <ul class="list-group list-group-flush text shadow" >
      <li  class="list-group-item area-list" *ngFor="let item of areaList" (click)=selectArea(item) >
        <p [ngClass]="{'areaSelected': item.areaId === select  }" >
          {{item.name}}
        </p>
      </li>
    </ul>
</div>
<!-- <div class="container-fluid p-1 date" style="width: max-content;" >
  <select class="form-select" aria-label="select" style="width: 100%;" (change)="selectScan($event)">
    <option *ngFor="let title of scans" 
        [value]="title.scanName" >
        {{title.scanDate}}
    </option>
  </select>
</div> -->

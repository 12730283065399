<div class="row">
    <div class="col sm-2">
        <div id="forgeViewer"></div>
    </div>
    <div class="col sm-5">

    </div>
    <div id="controls">
        <!-- <div class="col m-1"                 
        [tooltip]="$any(HtmlContent1)"
        theme='light'
        tooltipClass='tooltp'
        contentType="template"
        placement="left">
            <button class="btn btn-light" style="float: left;" (click)="showConflicts()" *ngIf='showDev'>
                <i class="fas fa-exclamation-triangle" aria-hidden="true" ></i>
            </button> 
            <button class="btn btn-primary" style="float: left; " (click)="showConflicts()" *ngIf='!showDev'>
                <span tooltip="Tooltip" placement="top"></span>
                <i class="fas fa-exclamation-triangle" aria-hidden="true"></i>
            </button>

        </div> -->
        <div class="col m-1"                 
        [tooltip]="$any(HtmlContent2)"
        theme='light'
        tooltipClass='tooltp'
        contentType="template"
        placement="left">
            <button class="btn btn-light " style="float: left; " (click)="showModel()" *ngIf='!showMod'>
                <span tooltip="Tooltip" placement="top"></span>
                <i class="far fa-building" aria-hidden="true"></i>
            </button>
            <button class="btn btn-light " style="float: left;" (click)="showModel()" *ngIf='showMod'>
                <i class="far fa-building" aria-hidden="true" style="color: #4E91DA;"></i>
            </button>
        </div>
    </div>
    <ng-template #HtmlContent1>
        All Deviations
    </ng-template>
    <ng-template #HtmlContent2>
        BIM Model
    </ng-template>
</div>
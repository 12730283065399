import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  projectTaskId: any;
  idForGantt: any;
  delayArr :any
  overallDelay: any;
  idFromSB: any;
  currentUser: any;
  @Output() selectedTask= new EventEmitter<any>()

  constructor(private changeDetectorRef: ChangeDetectorRef,
              private authService: AuthService) { }

  async ngOnInit() {
    try {
      this.currentUser = await this.authService.getCurrentUser()
      const { attributes } = this.currentUser;
    } catch(error:any){
      if(error == "The user is not authenticated") {
        
      } else {this.onProceed()}
    } 
  }
  passProjectTaskId(id: any) {
    this.projectTaskId = id
    this.changeDetectorRef.detectChanges();
  }
  passIdForGantt(id: any){
    this.idForGantt = id
    this.changeDetectorRef.detectChanges();
  }
  passDelay(delay:any){
    this.delayArr = delay
    this.changeDetectorRef.detectChanges();
    this.overallDelay = delay[0].delay
  }
  passIdSelect(id: any) {
    this.idFromSB = id;
    this.changeDetectorRef.detectChanges();
  }

  passIdToSV(id:any) {
    this.selectedTask.emit(id)
  }

  onProceed() {
    this.authService.setLoggedIn(true)
    const { attributes } = this.currentUser;
  }
}

import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { Link, Task, LinkService, TaskService } from 'zontro-openapi-frontend';
import { gantt } from 'dhtmlx-gantt';
import { ChartService } from 'src/app/services/chart.service';
import { AuthService } from 'src/app/services/auth.service';
import { ActivatedRoute } from '@angular/router';
const url = "https://export.dhtmlx.com/gantt/api.js";
@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-gantt-schedule',
  templateUrl: './gantt-schedule.component.html',
  providers: [TaskService, LinkService],
  styleUrls: ['./gantt-schedule.component.css']
})
export class GanttScheduleComponent implements OnInit {
  @ViewChild("gantt_here", {static:true})
  ganttContainer!: ElementRef;
  projectId: any ;
  date = new Date().toISOString()
  @Input() idForGantt: any
  // colHeader: string = '<div class="gantt_grid_head_cell gantt_grid_head_add" onclick="gantt.createTask()"></div>';
  colHeader: string = '<div class="gantt_grid_head_cell gantt_grid_head_add" onclick="gantt.createTask()"></div>'
  tasks: any
  links: any
  prevId: any
  cp: boolean = true
  onlyCP: boolean = false
  criticalPath: any
  delayArr: any = []
  user: any
  errorMessage: any
  projectList: any = []
 // public selectedDate: Date;
  public dateTime!: Date
  file!: File
  fileName = ''
  //private hasHtml5FileApiSupport;
  toggle: boolean = true
  asData: any

  //Setup Import to DB 
  @Input() myMppFile: any
  @Input() newMppSaved: any
  loadAPI!: Promise<any>
  importProjectId: any
  importProjectData: any
  @Output() scheduleAdded = new EventEmitter<any>()
  @Output() selectedTask= new EventEmitter<any>()
  @Output() delayAr = new EventEmitter<any>()
  importUser: any;
 

  constructor( private taskService: TaskService, 
               private linkService: LinkService,
               private chartService: ChartService,
               private authService: AuthService,
               private route: ActivatedRoute,
              ) { }

  ngOnInit(){
    //this.buttonClicked();
    const routeParams = this.route.snapshot.paramMap
    const projectId = routeParams.get('projectId');
    this.projectId = projectId

    if(projectId) {this.getGanttData()}
    gantt.config.date_format = "%Y-%m-%d %H:%i:%s";
    gantt.plugins({
	    auto_scheduling: true,
	    critical_path: true,
	    drag_timeline: false,
	    fullscreen: true,
	    keyboard_navigation: true,
	    marker: true,
	    tooltip: true,
	    undo: false,
    });
    this.loadAPI = new Promise((resolve) => {
      this.loadScript();
    });
    gantt.config.highlight_critical_path = true;
    gantt.config.bar_height = 16;
    gantt.config.row_height = 24;
    gantt.config.task_height = 16;
    gantt.config.link_line_width = 1;
    gantt.config.lightbox_additional_height = 180;
    gantt.config.lightbox_additional_width = 100;

    gantt.config.drag_move = false; //disables the possibility to move tasks by dnd
    gantt.config.drag_links = false; //disables the possibility to create links by dnd
    gantt.config.drag_progress = false; //disables the possibility to change the task //progress by dragging the progress knob
    gantt.config.drag_resize = false;
    //   gantt.config.auto_scheduling = true;
    //    gantt.config.auto_scheduling_strict = false;
    //  //gantt.config.auto_scheduling_initial = true;
    //    gantt.config.auto_scheduling_compatibility = false;

gantt.config.work_time = true;

    //Grid columns
    gantt.config.columns =  [
      {name: "id", label: "ID",  min_width: 60, width: 60, resize:true,  },
      {name: "wbs", label: "WBS", tree:true, min_width: 100, width: 100, template: gantt.getWBSCode, resize:true,  },
      {name:"text", label:"Task",   width:"*", min_width: 200,  resize: true, template:taskFunc},
      //{name:"duration", label: "Duration", width: "*", resize: true},
      {name: "progress", label: "Progress", width: 80, align: "center",
        template: function (item: any) {
          if (Math.round(item.progress * 100) >= 100)
            return ('<i class="fas fa-check" style = "color:#3DD598"></i>')
          if (item.progress == 0)
            return "";
          return Math.round(item.progress * 100) + "%";
        }
      },
      {name:"buttons",  width: 50, resize:false, template: panoFunc },
      {name:"buttons",  width: 50, resize:false, template: devFunc }
    ];
    function taskFunc(task: any){
      if(task.if_deleted == 1)
          return "<div class='userInput'>"+task.text+" </div>";
      return task.text;
    };
    function panoFunc(task: any){
      if(task.inputType == 1 )
        return '<i class="far fa-eye" style=" color: #3778BE" data-action="pano"></i>'
          // return '<i class="far fa-eye" style=" color: #3778BE" data-action="pano"></i>'
      return '';
    };
    function devFunc(task: any){
      if(task.conflict == 1 ) {
        return '<i class="fas fa-exclamation-triangle" style="color: #ff8f00" data-action="dev"></i>'
      } else if(task.conflict==2) {
        return '<i class="fas fa-exclamation-triangle" style="color: #8AB8FF" alt="Refer to child tasks"></i>'
      }
      return '';
    };
    gantt.templates.tooltip_text = function(start,end,task){
      return "<b>Task:</b> "+task.text+"<br/><b>Progress:</b> " + Math.round(task.progress*100)+"%";
    };
    //Lightbox
    //gantt.config.wide_form = true; 
    gantt.templates.lightbox_header = function(start_date,end_date,task){
      return gantt.templates.task_time(task.start_date, task.end_date, task)  +  "<br>&nbsp&nbsp" +
      (gantt.templates.task_text(task.start_date, task.end_date, task) || "").substring(0, 70);
    };
    gantt.config.buttons_right = ["gantt_cancel_btn"];
    gantt.config.buttons_left = ["gantt_save_btn"];
    gantt.config.lightbox.sections = [
      // {name: "description", label: "Name", height: 60, map_to: "text", type: "textarea", readonly:true},
      // {name: "time", height: 62, map_to: "auto", type: "duration", year_range: [2020, 2028]},
      // {name: "type", type: "typeselect",height: 62, map_to: "types"}, 
      {name: "progress", height: 40, map_to: "progress", type: "select", options: [
        {key: 0, label: "Not started", value: 0},
        {key: "0.1", label: "10%", value: .1},
        {key: "0.2", label: "20%", value: .2},
        {key: "0.3", label: "30%", value: .3},
        {key: "0.4", label: "40%", value: .4},
        {key: "0.5", label: "50%", value: .5},
        {key: "0.6", label: "60%", value: .6},
        {key: "0.7", label: "70%", value: .7},
        {key: "0.8", label: "80%", value: .8},
        {key: "0.9", label: "90%", value: .9},
        {key: "1", label: "Complete", value: 1}
        ]},
      {name: "inputtype",height: 40, map_to: "deleted", type: "radio", default_value: true, options: [
        {key: true, label: " User Input &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp"},
        {key: false, label: " System Input"}
      ]}
    ];
    gantt.config.lightbox.project_sections = [
      {name: "progress", height: 40, map_to: "progress", type: "select", options: [
        {key: "0", label: "Not started", value: 0},
        {key: "0.1", label: "10%", value: .1},
        {key: "0.2", label: "20%", value: .2},
        {key: "0.3", label: "30%", value: .3},
        {key: "0.4", label: "40%", value: .4},
        {key: "0.5", label: "50%", value: .5},
        {key: "0.6", label: "60%", value: .6},
        {key: "0.7", label: "70%", value: .7},
        {key: "0.8", label: "80%", value: .8},
        {key: "0.9", label: "90%", value: .9},
        {key: "1", label: "Complete", value: 1}
      ]},
      {name: "inputtype",height: 40, map_to: "deleted", type: "radio", default_value: true, options: [
        {key: true, label: " User Input &nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp"},
        {key: false, label: " System Input"}
      ]}
    ];
    gantt.locale.labels["section_progress"] = "Progress";
    gantt.locale.labels["section_inputtype"] = "Progress Input By";
    // gantt.attachEvent("onLightboxSave", function(id){
    //   // gantt.confirm({
    //   //   text: "Update Task?",
    //   //   ok:"Confirm", 
    //   //   cancel:"Cancel",
    //   //   callback: function(result: any){
    //   //     if(result){
    //          var task = gantt.getTask(id);
    //          task.progress = 
    //   //       //task.checked=!task.checked;
    //         gantt.updateTask(id, task);
    //         console.log(task)
    //   //     }
    //   //     else {
    //   //       gantt.refreshTask(id)
    //   //     }
    //   //   }
    //   // });

    // }, {once: true})
    // Image button actions
    gantt.attachEvent("onTaskClick", (id,e) =>  {
      var button = e.target.closest("[data-action]")
      if(button) {
        var action = button.getAttribute("data-action");
        switch (action) {
          // case "3":
          //   gantt.showLightbox(id);
          //   break;
          case "add":
            gantt.alert("Adding new task is disabled");
            //gantt.createTask(null, id);
            break;  
          case "pano":
            gantt.modalbox({
              title:"Click confirm to change BIM progress to " + id + ")",
              text: " ",
              ok: 'View',
              buttons:[
                { label:"Confirm",   css:"link_save_btn",   value:id },
                {label:"Cancel", css:"link-cancel-btn", value:"cancel"}
              ],
              callback: function(id: any) {
                //this.selectedTask.emit(id)
                changeTask(id);
                gantt.alert(id+" progress highlighted on BIM");},
              width: "500px",
            });
            break;
          case "dev":
            gantt.modalbox({
              title:"Click confirm to see Deviation & BIM progress for " + id + ")",
              text: " ",
              ok: 'View',
              buttons:[
                { label:"Confirm",   css:"link_save_btn",   value:id },
                {label:"Cancel", css:"link-cancel-btn", value:"cancel"}
              ],
              callback: function(id: any) {
                //this.selectedTask.emit(id)
                changeTaskDev(id);
                gantt.alert(id+" progress highlighted on BIM. Showing Deviation.");},
              width: "500px",
            });
            // gantt.modalbox({
            //   title:"Area to view : (Task ID -" + id + ")",
            //   text: "<div class='modalbox-content'>Take me to :</div><br><div><a class='nav-link' href= 'site-viewer/"+id+"' target='_blank'> 360 Virtual Tour </a><hr></div>",
            //   buttons:[
            //   {label:"Cancel", css:"link-cancel-btn", value:"cancel"}],
            //   width: "500px",
            // });
            break;
        }
      };
      const changeTask = (id:any) =>{  
         var data = {
          id: id,
          dev: false
         }
         if(id!=='cancel') {
          this.selectedTask.emit(data)
         }
      }
      const changeTaskDev = (id:any) =>{  
        var data = {
         id: id,
         dev: true
        }
        if(id!=='cancel') {
          this.selectedTask.emit(data)
        }
     }  
      return true;
    }, {once: false}
    );

    //Zoom Configuration
    var zoomConfig = {
      levels: [
        {
          name:"week",
          scale_height: 40,
          min_column_width:100,
          scales:[
            {unit: "week", step: 1, format: function (date:Date) {
              var dateToStr = gantt.date.date_to_str("%d %M");
              var endDate = gantt.date.add(date, +6, "day");
              var weekNum = gantt.date.date_to_str("%W")(date);
              return "Week " + weekNum + ", " + dateToStr(date) + " - " + dateToStr(endDate);
            }},
            {unit: "day", step: 1, format: "%j %D"}
          ]
        },
        {
          name:"month",
          scale_height: 40,
          min_column_width:180,
          scales:[
            {unit: "month", format: "%F, %Y"},
            {unit: "week", format: "Week %W"}
          ]
        },
        {
          name:"quarter",
          scale_height: 40,
          min_column_width:240,
          scales:[
            {
              unit: "quarter", step: 1, format: function (date:Date) {
                var dateToStr = gantt.date.date_to_str("%M");
                var endDate = gantt.date.add(gantt.date.add(date, 3, "month"), -1, "day");
                return dateToStr(date) + " - " + dateToStr(endDate);
              }
            },
            {unit: "month", step: 1, format: "%M"},
          ]
        },
        {
          name:"year",
          scale_height: 40,
          min_column_width: 100,
          scales:[
            {unit: "month", step: 1, format: "%M"},
            {unit: "year", step: 1, format: "%Y"}
          ]
        }
      ],
    };
    gantt.ext.zoom.init(zoomConfig);
    gantt.ext.zoom.setLevel("quarter");

    //Baseline Date clean
    gantt.attachEvent("onTaskLoading", function (task) {
      task.planned_start = gantt.date.parseDate(task.planned_start, "xml_date");
      task.planned_end = gantt.date.parseDate(task.planned_end, "xml_date");
      return true;
    }, {once: false});  

    //Dynamic progress parent
    (function dynamicProgress() {

      function calculateSummaryProgress(task: any) {
        if (task.type != gantt.config.types.project)
          return task.progress;
        var totalToDo = 0;
        var totalDone = 0;
        gantt.eachTask(function (child) {
          if (child.type != gantt.config.types.project) {
            totalToDo += child.duration;
            totalDone += (child.progress || 0) * child.duration;
          }
        }, task.id);
        if (!totalToDo) return 0;
        else  return (totalDone / totalToDo);
      }
      function refreshSummaryProgress(id: any, submit: any) {
        if (!gantt.isTaskExists(id))
          return;
        var task = gantt.getTask(id);
        var newProgress = calculateSummaryProgress(task);
        if (newProgress !== task.progress) {
          task.progress = newProgress;   
          if (!submit) {
            gantt.refreshTask(id);
          } else {
            gantt.updateTask(id,task);
          }
        }
        if (!submit && gantt.getParent(id) !== gantt.config.root_id) {
          refreshSummaryProgress(gantt.getParent(id), submit);
        }
      }
      gantt.attachEvent("onParse", function () {
        gantt.eachTask(function (task) {
          task.progress = calculateSummaryProgress(task);
        });
      },  {once: false});
      gantt.attachEvent("onAfterTaskUpdate", function (id) {
        refreshSummaryProgress(gantt.getParent(id), true);
      },  {once: false});
      // gantt.attachEvent("onTaskDrag", function (id) {
      //   refreshSummaryProgress(gantt.getParent(id), false);
      // },  {once: false});
      gantt.attachEvent("onAfterTaskAdd", function (id) {
        refreshSummaryProgress(gantt.getParent(id), true);
      },  {once: false});
    })();

    //Styling
    gantt.templates.grid_header_class = function(columnName: any, column: any) {
      return "updColor"
    }
    gantt.templates.grid_row_class = function(columnName: any, column: any) {
      return "gridCell"
    }
    gantt.templates.scale_row_class = function(scale){
      return "tmlnHeaderColor"
     }
     this.currentUser()
     

      const dp = gantt.createDataProcessor({
      task: {
        update: (data: any,id: any) => {
    //UPDATE FROM GANTT
          console.log(parseFloat(data.progress), id)
          if(data.deleted='true') {data.deleted=true} else {data.deleted=false}
          //if(data.inputType='true') {data.inputType=true} else {data.inputType=false}
          //if(data.progress='0') {data.progress=0}
          var task={
            id:data.id,
            projectId:this.projectId,
            text:data.text,
            startDate:data.start_date.replace(' ','T').concat('','Z'),
            duration:data.duration,
            progress:parseFloat(data.progress),
            plannedStart: data.planned_start.replace(' ','T').concat('','Z'),
            plannedEnd: data.planned_end.replace(' ','T').concat('','Z'),
            parent: data.parent,
            taskTypes: data.type,
            userId: this.user,
            deleted:data.deleted || false,
            conflict:data.conflict || false,
            open: data.open,
            inputType: false,
            //if_deleted: task[i].if_deleted
          }
          console.log(task)
          this.taskService.addTask(task,this.projectId,this.user).subscribe( 
            result => {  
           },error => {
             console.log(error)
           })
        }
      },
      link: {}
    });
  } 
  public loadScript() {
    let node = document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    node.async = true;
    //node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);
  }
  async currentUser(){
    try {
      var user = await this.authService.getUser()
      const { attributes } = user
      this.user = {attributes}.attributes.sub
      if (user!==undefined) {
        return  
      } else {
        return this.authService.verifySuccess()
      }
    } catch(error:any){
      console.log(error)
      this.errorMessage = error
    }
  } 

  //Parse data from backend on gantt
   getGanttData(){
    Promise.all([this.taskService.getTasks(this.projectId,this.date).toPromise(), this.linkService.getLink(this.projectId,this.date).toPromise()])
    .then(([data, links]) => {
      gantt.clearAll();
      gantt.parse({data, links});
      this.delay(data)
      this.delayAr.emit(this.delayArr)
    });

  }
    delay(task:any) {
      var today = new Date().getTime();
      for(var i=0;i<task.length;i++){

        if(task[i].progress>=0 || task[i].progress<1){
          if(gantt.hasChild(task[i].id)==undefined) {
            var maxLevel = true
          } else maxLevel=false
          var plan = this.chartService.plannedProgress(task[i].planned_end, task[i].planned_start)
          var delay = Math.round((today-((task[i].duration*task[i].progress)+task[i].planned_start.getTime()))/(24*60*60*1000))
          var data={
           id: task[i].id,
           text: task[i].text,
           actualP: task[i].progress*100,
           plannedP: plan,
           parent: task[i].parent,
           delay: delay,
           maxLevel:maxLevel,
           open: task[i].open,
           inputType: task[i].inputType,
           if_deleted: task[i].if_deleted
          }
          this.delayArr.push(data)
        } else {
          if(gantt.hasChild(task[i].id)==undefined) {
            var maxLevel = true
          } else maxLevel=false
          var delay1 = Math.round((task[i].start_date.getTime()-task[i].planned_end.getTime())/(24*60*60*1000))
          var plan = this.chartService.plannedProgress(task[i].planned_end, task[i].planned_start)
          var data={
            id: task[i].id,
            text: task[i].text,
            actualP: task[i].progress*100,
            plannedP: plan,
            parent: task[i].parent,
            delay: delay1,
            maxLevel:maxLevel,
            open: task[i].open,
            inputType: task[i].inputType,
            if_deleted: task[i].if_deleted
          }
          this.delayArr.push(data)
        }
      }
    }
        
      

  
  ngAfterViewInit(){
    gantt.init(this.ganttContainer.nativeElement);
    gantt.addTaskLayer(function draw_planned(task: any) {
      if (task.planned_start && task.planned_end) {
        var end_date = gantt.calculateEndDate({
          start_date: task.planned_start,
          duration: task.duration
        });
          var sizes = gantt.getTaskPosition(task, task.planned_start, end_date);
          var el = document.createElement('div');
          el.className = 'baseline';
          el.style.left = sizes.left + 'px';
          el.style.width = sizes.width + 'px';
          el.style.top = sizes.top + gantt.config.task_height + -5 + 'px';
          return el;
      }
      return false;
    });
    
  }
  ngOnChanges(changes: SimpleChanges) {
    if(changes['idForGantt']!==undefined && this.idForGantt){
      let id = changes['idForGantt'].currentValue;
      gantt.selectTask(id)  
      var task = gantt.getTask(id);
      var sizes = gantt.getTaskPosition(task, task.start_date, task.planned_end);
      gantt.scrollTo(sizes.left, sizes.top);    
      gantt.open(id)
    }
    //Setup Import to DB inputs
    if(changes['myMppFile']!==undefined && this.myMppFile){
      let file = changes['myMppFile'].currentValue;
      this.myMppFile=file;
      gantt.importFromMSProject({
            data: this.myMppFile,
            taskProperties: ["Notes", "Name"],
            callback: this.getparsed.bind(this) 
          });
    } 
    if(changes['newMppSaved']!==undefined && this.newMppSaved){
      let file = changes['newMppSaved'].currentValue;
      this.newMppSaved=file;
      this.importProjectId = this.newMppSaved.projectId
      this.importUser = this.newMppSaved.user
      this.addMppToDb()
    } 
  }
  //Control buttons on gantt
  //Collapse All
  collapse_tasks(){
    gantt.eachTask(function(task){
      task.$open = false;
    });
    gantt.render();
  }
  //Expand All
  expand_tasks(){
    gantt.eachTask(function(task){
      task.$open = true;
    });
    gantt.render();
  }
  //Toggle Critical Path
  toggle_highlight(){
    gantt.config.highlight_critical_path = !gantt.config.highlight_critical_path;
    this.cp=!this.cp
    gantt.render();
   }
  viewCP() {
    this.onlyCP=!this.onlyCP
    if(this.onlyCP) {
      this.criticalPath = gantt.attachEvent("onBeforeTaskDisplay", function(id, task){
        if (gantt.isCriticalTask(task)){
          return true;
        }
        return false;
      }, {once: false});
      gantt.render();
    } else {
      gantt.detachEvent(this.criticalPath);
      gantt.render();

    }
  } 
  changeTask(id: any) {
    this.selectedTask.emit(id)
  }
  //NEW PROJECT IMPORT FUNCTIONS
  // public buttonClicked() {
  //   this.loadAPI = new Promise((resolve) => {
  //     console.log('resolving promise...');
  //     this.loadScript();
  //   });
  // }   
  // public loadScript() {
  //   //console.log('preparing to load...')
  //   let node = document.createElement('script');
  //   node.src = url;
  //   node.type = 'text/javascript';
  //   node.async = true;
  //   //node.charset = 'utf-8';
  //   document.getElementsByTagName('head')[0].appendChild(node);
  //   console.log('Import module load complete');
  // }
  // onFileSelected(event: any) {

  //   this.file = event.target.files[0];
  //   console.log(this.file);
  //   gantt.importFromMSProject({
  //     data: this.file,
  //     taskProperties: ["Notes", "Name"],
  //     callback: this.getparsed.bind(this) 
  //   });
  // }
  // getparsed(project: any) {
  //   var i: any;
  //   //console.log(project.data.data[0].end_date);
  //   //const ev = this.taskService.addNewTask(project.data.data,this.projectId,'system')
  //   //console.log(ev)
  //   gantt.attachEvent("onParse",  () => {
  //     gantt.eachTask( (task) => {
  //       if (gantt.hasChild(task.id)) {
  //         task.type = gantt.config.types.project;
  //         //const ev = this.taskService.addNewTask(newTask,this.projectId,this.user)
  //         gantt.updateTask(task.id,task);
  //         //this.newProject.push(newTask)
  //         //console.log(task.id, task.text, task.end_date, task.start_date, task.duration, task.progress,task.$rendered_parent, task.$rendered_type)
  //         //gantt.updateTask(task.id,task);
  //       } else if (task.duration === 0) {
  //         task.type = gantt.config.types.milestone;
  //         // var newTask = {
  //         //   taskId: task.id,
  //         //   projectId: this.projectId,
  //         //   text: task.text,
  //         //   startDate: task.start_date,
  //         //   duration: task.duration,
  //         //   progress: task.progress,
  //         //   plannedStart: task.start_date,
  //         //   plannedEnd: task.end_date,
  //         //   parent: task.$rendered_parent,
  //         //   taskTypes: task,
  //         //   userId: this.user,
  //         //   deleted: true,
  //         //   conflict: false 
  //         // }
  //         // console.log(newTask)
  //         //this.taskService.addNewTask(newTask,this.projectId,this.user)
  //         gantt.updateTask(task.id,task);
  //       } else {
  //         // var newTask = {
  //         //   taskId: task.id,
  //         //   projectId: this.projectId,
  //         //   text: task.text,
  //         //   startDate: task.start_date,
  //         //   duration: task.duration,
  //         //   progress: task.progress,
  //         //   plannedStart: task.start_date,
  //         //   plannedEnd: task.end_date,
  //         //   parent: task.$rendered_parent,
  //         //   taskTypes: task.$rendered_type,
  //         //   userId: this.user,
  //         //   deleted: true,
  //         //   conflict: false 
  //         // }
  //         // console.log(newTask)
  //         //this.taskService.addNewTask(newTask,this.projectId,this.user)
  //         gantt.updateTask(task.id,task);
  //       }
  //     });
  //   }, {once: false});
  //   //for(i=0; i<project.data.data.length;i++){
  //    // console.log(project.data.data[i],project.data.data[i].$rendered_parent,project.data.data[i].$rendered_type)
  //      //this.getDataService.insertLinks(project.data.links[i])
  //  //}
  //   // for(i=0; i<project.data.links.length;i++){
   
  //   //     this.getDataService.insertLinks(project.data.links[i])
  //   // }
  //   if (project) {
  //     gantt.clearAll();
  //     console.log("gantt cleared");
  //     if (project.config.duration_unit) {
  //       gantt.config.duration_unit = project.config.duration_unit;
  //     }                    
  //     gantt.parse(project.data);
  //     //console.log("project.data");
  //     //console.log(project.data);
  //     // project.data.data.forEach(element => {
  //     //   this.getDataService.insert(project.data.data[element])
  //     //   this.getDataService.insertLinks(project.data.links[element])
  //     // });
      
  //     this.asData = project.data;
  //   }
  // }
  //NEW PROJECT IMPORT FUNCTIONS END

//VERSIONS START
  // onChange() {
  //   //console.log(this.dateTime)

  //    var dt = this.dateTime.toISOString()
  //   //console.log(dt)
  //   Promise.all([this.taskService.getTasks(this.projectId,dt).toPromise(), this.linkService.getLink(this.projectId,dt).toPromise()])
  //   .then(([data, links]) => {
  //     gantt.parse({data, links});
  //     this.delay(data)
  //     this.delayAr.emit(this.delayArr)
  //   });
  // }
//VERSIONS END


//Setup import to db
  getparsed(project: any) {
    var i: any;
    gantt.attachEvent("onParse",  () => {
      gantt.eachTask( (task) => {
        if (gantt.hasChild(task.id)) {
          task.type = gantt.config.types.project;
        } else if (task.duration === 0) {
          task.type = gantt.config.types.milestone;
        } else {
        }
      });
    }, {once: false});
    if (project) {
      gantt.clearAll();
      if (project.config.duration_unit) {
        gantt.config.duration_unit = project.config.duration_unit;
      }                    
      gantt.parse(project.data);
      this.importProjectData = project.data;
    }
    this.scheduleAdded.emit(3)
  }
  addMppToDb() {
    var newProject: Task[] = []
    var newLinks: Link[] = []
    gantt.eachTask( (task) => {
       if (gantt.hasChild(task.id)) {
         task.type = gantt.config.types.project;
         var newTask = {
          id: parseFloat(task.id),
          projectId: this.importProjectId,
          text: task.text,
          startDate: task.start_date,
          duration: Math.round(task.duration),
          progress: task.progress,
          plannedStart: task.start_date,
          plannedEnd: task.end_date,
          parent: parseFloat(task.$rendered_parent),
          taskTypes: task.type,
          userId: this.importUser,
          deleted: true,
          conflict: false,
          inputType: true 
        }
        newProject.push(newTask)
       } else {
         var newTask = {
            id: parseFloat(task.id),
            projectId: this.importProjectId,
            text: task.text,
            startDate: task.start_date,
            duration: Math.round(task.duration),
            progress: task.progress,
            plannedStart: task.start_date,
            plannedEnd: task.end_date,
            parent: parseFloat(task.$rendered_parent),
            taskTypes: task.$rendered_type,
            userId: this.importUser,
            deleted: true,
            conflict: false,
            inputType: true 
          }
          newProject.push(newTask)
       }
    })
    this.taskService.addNewTask(newProject,this.importProjectId,this.importUser).subscribe(
      (data: any) => {
        this.scheduleAdded.emit(1)
      })
    for(var i=0;i<this.importProjectData.links.length;i++) {
      var newLink = {
        id: parseFloat(this.importProjectData.links[i].id),
        projectId: this.importProjectId,
        sourceId: parseFloat(this.importProjectData.links[i].source),
        targetId: parseFloat(this.importProjectData.links[i].target),
        lag: parseFloat(this.importProjectData.links[i].lag),
        linkType: parseFloat(this.importProjectData.links[i].type),
        userId: this.importUser,
        ifDeleted: false
      }
      newLinks.push(newLink)
    }
    this.linkService.addNewLink(newLinks,this.importProjectId).subscribe(
         (data: any) => {
          this.scheduleAdded.emit(2)
         })
  }
  ngOnDestroy() {
    gantt.clearAll();
    //gantt.destructor()
  }
}

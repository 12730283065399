import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.css']
})
export class TooltipComponent implements OnInit {
  colorSchemePlanned = {
    domain: ['#64b5f6', '#ffffff']
  };
  actual: any;
  view: any = [90, 90];
  //planned: any;
  @Input() tooltipData: any
  constructor() { }

  ngOnInit(){
    this.actual = [
      {
        "name": "actual",
        "value": this.tooltipData.actual
      },
      {
        "name": "total",
        "value": 100
      }
    ];
  }
  format(data: any) {
    return '';
  }

}

/**
 * Zontro UI APIs
 * Zontro APIs for UI and backend
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface Task { 
    id: number;
    projectId?: string;
    text: string;
    startDate: Date;
    duration: number;
    progress: number;
    plannedStart: Date;
    plannedEnd: Date;
    parent?: number;
    taskTypes: string;
    //taskTypes?: Task.TaskTypesEnum;
    userId?: string;
    deleted?: boolean;
    conflict?: boolean;
    inputType?: boolean
}
export namespace Task {
    export type TaskTypesEnum = 'task' | 'project' | 'milestone';
    export const TaskTypesEnum = {
        Task: 'task' as TaskTypesEnum,
        Project: 'project' as TaskTypesEnum,
        Milestone: 'milestone' as TaskTypesEnum
    };
}
<body class="wind text-left">
  <img class="mb-4" src="assets/images/logo.png" alt="" width="28" height="28"> <a style="vertical-align: top; color: #64b5f6; font-size: larger;" >    Zontro</a>
  <div *ngIf="!currentUser"> 
    <p class="mt-5 mb-3 text-muted" style="font-size: 12px;"> </p>
    <main class="form-signin">
      <div class="input-group mb-3">
        <form (ngSubmit)="onLogin(loginForm)" #loginForm="ngForm" novalidate>
          <div *ngIf="errorMessage" type="danger"><p style=" color: red;">{{ errorMessage }}</p></div>
          <h1 class="h3 mb-4 pt-10 fw-normal" style="color: #64b5f6;">Sign in </h1>
          <div class="form-floating mb-2">
            <input type="email" class="form-control " size="45" name= "email" id="floatingInput" [(ngModel)]="email" placeholder="name@example.com" required>
            <label for="floatingInput">Email address</label>
          </div>
          <div class="form-floating mt-2 mb-2">
            <input type="password" class="form-control form-control-sm" name="password" id="floatingPassword" [(ngModel)]="password" placeholder="Password" required>
            <label for="floatingPassword">Password</label>
          </div>
          <button class="w-20 btn btn-primary" type="submit">Sign in</button>
        </form>
      </div>
      <p class="mt-1 mb-1 text-muted" style="font-size: 12px;"> <a [routerLink]="['/forgotpwd']"> Forgot Password?</a></p>
    </main>
    <p class="mt-5 mb-3 text-muted" style="font-size: 12px;"> Don't have an account?  <a [routerLink]="['/signup']"> Create an Account</a></p>
  </div> 
  <div  *ngIf="currentUser" class="signin">
    <main class="form-signin">
      <h1 class="h3 mb-5 fw-normal">Sign in to Zontro</h1>
        <div class="row">
          <h5 class="h5 mb-3 fw-normal">Logged in as {{currentUser.attributes.email}}<p *ngIf="userInDB && !clientVerification" style=" color: rgb(0, 153, 255);">Access Level: {{ accessLevel | uppercase }}</p></h5>
        </div>
        <div class="form-floating mb-2"  *ngIf="clientVerification" style="width: 300px;">
          <select class="form-select" aria-label="select" style="width: 100%;" (change)="checkClient($event)">
            <option *ngFor="let client of clientList" 
               >
                {{client.orgName}}
            </option>
          </select>
        </div>
        <div class="form-floating mb-2" *ngIf="clientVerification" style="width: 300px;">
          <input type="number" class="form-control " name= "client" id="floatingClient" [(ngModel)]="clientCode" placeholder="12345678"  required>
          <label for="floatingClient">Company Code</label>
        </div>
        <div class="row">
        <div class="form-floating mt-2 mb-2">
          <div *ngIf="errorMessage" type="danger"><p style=" color: red;">{{ errorMessage }}</p></div>
          <div class="btn-group" role="group" aria-label="First group">
            <!-- <button class="w-20 btn btn-primary mr-3"  type="submit" (click)="onProceed()">Proceed to UI</button> -->
            <button *ngIf="clientVerification"  class="w-20 btn btn-primary mr-3"  type="submit" (click)="onCompanyAccess()">Verify access</button>
            <button *ngIf="!clientVerification"   class="w-20 btn btn-primary mr-3"  type="submit" (click)="onOverview()">Overview</button>
            <button class="w-20 btn btn-primary mr-3" type="submit" (click)="onSignOut()">Sign out</button>
          </div>
        </div>
      </div>
    </main>
  </div>
  <p class="mt-5 mb-3 text-muted" style="font-size: 12px;">&copy; <a href="#"> Terms and Conditions</a> | <a href="#"> Privacy Policy</a></p>     
</body>
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProjectService, UserService } from 'zontro-openapi-frontend';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.css']
})
export class OverviewComponent implements OnInit {
  currentUser: any;
  userInitials: any
  projectList: any
  orgName: any;
  constructor(private authService: AuthService, 
              private projectService: ProjectService, 
              private userService: UserService,
              private router: Router) { }

  async ngOnInit() {
    try {
      this.currentUser = await this.authService.getCurrentUser()
      var initials = []
      initials = this.currentUser.attributes.name.trim().split(" ");
      this.userInitials=""
      if(initials.length>1) {
        this.userInitials = this.userInitials.concat(initials[0].substring(0,1),initials[initials.length-1].substring(0,1))
      } else {
        this.userInitials = this.userInitials.concat(initials[0].substring(0,1))
      }
      this.getProjects()
    } catch(error:any){
      console.log(error) 
    }
  }
  signout() {
    this.authService.signOut()
    this.authService.setLoggedIn(false)
  }
  onSelectedProject(data: any) {
    //if(data.status=="Processing Files") {
    //  this.router.navigate(['preview',data.clientId,data.projectId])
   // } else {
      this.router.navigate(['ui',data.clientId,data.projectId])
    //}
   
  }
  onSelectedProgress(data: any) {
      this.router.navigate(['preview',data.clientId,data.projectId])
  }
  getProjects() {
    this.userService.getUserDetails(this.currentUser.username).subscribe(
      (data: any) => {  
        if(!jQuery.isEmptyObject(data)) {
          this.orgName = data[0].orgName
          this.projectService.getProjects(data[0].clientId).subscribe(
            result=> {
              this.projectList = result
            }
          )
        }
        if(data.length==0) {
          this.router.navigate(['signin'])
        }
      }
    )
  }
  deleteProject(data: any){
    var delProject = {
      projectId: data.projectId,
      removedBy: this.currentUser.username,
    }
    this.projectService.deleteProject(delProject).subscribe((result: any) => { 
      this.getProjects()
    })
  }
}
